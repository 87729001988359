import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';
import SurveyScore from './SurveyScore';

class SurveyReports extends React.Component {
    state = {
        completedSurveys: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getCompletedSurveys();
        }
    }

    render = () => {
        return (
            <div>
                <h3>All Completed Surveys</h3>
                <h3><button onClick={this.getCompletedSurveys}>Refresh</button></h3>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Survey Name</th>
                                <th>Survey Membership</th>
                                <th>Submitted Date</th>
                                <th>Score</th>
                                <th>Details Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSurveys()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getCompletedSurveys = () => {
        request('surveyGroupUsers/getUniversalCompletedSurveys').then(res => {
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                const surveyObject = {};
                for (let i = 0; i < res.data.rows.length; i++) {
                    const row = res.data.rows[i];
                    if (!row.survey_grouping) {
                        continue;
                    }
                    const groupingName = row.survey_grouping ? row.survey_grouping : row.name;
                    if (!surveyObject[groupingName]) {
                        surveyObject[groupingName] = {};
                    }
                    const membership = surveyObject[groupingName];
                    const name = row.first_name + " " + row.last_name;
                    if (!membership[name]) {
                        membership[name] = {};
                    }
                    const user = membership[name];
                    if (!user[row.display_name]) {
                        user[row.display_name] = [];
                    }
                    const survey = user[row.display_name];
                    survey.push(row);
                }
                this.setState(( { completedSurveys: surveyObject }));
            }
        });
    }

    renderSurveys = () => {
        const toRender = [];
        if (this.state.completedSurveys) {
            for (const [grouping, users] of Object.entries(this.state.completedSurveys)) {
                toRender.push(<tr key={grouping} style={{ backgroundColor: '#555555' }}><td colSpan="5"><h2>{grouping}</h2></td></tr>);
                for (const [user, surveys] of Object.entries(users)) {
                    toRender.push(<tr key={`${grouping}-${user}`} style={{ backgroundColor: '#be8de0', textAlign: 'left' }}><td colSpan="5">{user}</td></tr>);
                    for (const [survey, rows] of Object.entries(surveys)) {
                        //toRender.push(<tr key={`${grouping}-${user}-${survey}`} style={{ backgroundColor: 'white' }}><td colSpan="5">{survey}</td></tr>);
                        for (const completedSurvey of rows) {
                            toRender.push(<tr key={completedSurvey.guid} style={{ backgroundColor: 'white' }}><td>{completedSurvey.display_name}</td><td>{completedSurvey.name}</td><td>{new Date(completedSurvey.created_at).toDateString()}</td><td><SurveyScore key={`ss-${completedSurvey.guid}`} asguId={completedSurvey.guid} surveyName={completedSurvey.name} /></td><td><Link to={`../admin/surveyReport/${completedSurvey.guid}`}>Details</Link></td></tr>)
                        }
                    }
                }
            }
        }
        return toRender;
    }

}

export default SurveyReports;