import React from 'react';

class KokoroTable extends React.Component {
    constructor(props) {
        super(props);
    }

    hasFilters = () => {
        const filter = this.state.filter;
        const entries = Object.entries(filter);
        for (let i = 0; i < entries.length; i++) {
            if (entries[i][1].trim() !== '') {
                return true;
            }
        }
        return false;
    }
    
    filter = (allData) => {
        if (allData) {
            const hasFilters = this.hasFilters();
            const returnData = hasFilters ? [] : allData;
            if (hasFilters) {
                const filter = this.state.filter;
                for (let i = 0; i < allData.length; i++) {
                    let explicitFail = false;
                    let match = false;
                    const data = allData[i];
                    const values = Object.entries(data);
                    for (let j = 0; j < values.length; j++) {
                        const key = values[j][0];
                        const value = values[j][1];
                        if (filter[key] && filter[key] !== '') {
                            if (value.toLowerCase().indexOf(filter[key]) > -1)
                            {
                                match = true;
                            } else {
                                explicitFail = true;
                            }
                        }
                    }
                    if (match === true && explicitFail === false) {
                        returnData.push(data);
                    }
                }
            }
            return returnData;
        }
    }

    state = {
        currentPage: 1,
        maxItems: this.props.maxItems ? this.props.maxItems : 3,
        pageNavWidth: '100%',
        filter: {},
        sort: ''
    }

    componentDidUpdate = () => {
        const pages = document.getElementsByClassName(`table-${this.props.tableName}-page`);
        for(const page of pages) {
            if (page.innerText != this.state.currentPage) {
                page.removeAttribute('disabled');
            } else {
                page.setAttribute('disabled', true);
            }
        }
    }

    render = () => {
        const data = this.filter(this.props.data);
        return (
            <div style={this.props.style}>
                <div style={{ textAlign: 'right', marginBottom: '15px', fontSize: '12px' }}>
                    <label>Max List View:</label>
                    <input type="number" style={{ marginLeft: '12px', width: "30px", fontSize: '12px', borderWidth: '1px', textAlign: 'right' }} value={this.state.maxItems} onChange={(sender) => { sender.preventDefault(); const value = parseInt(sender.target.value); if (value > 0 && value < 26) { this.setState((prevState) => ( { maxItems: parseInt(sender.target.value), currentPage: 1 } ) ); } } } />
                </div>
                <table className="kokoroTable" id={`kokoro-table-${this.props.tableName}`} style={this.props.style}>
                    {this.renderTHead()}
                    {this.renderTBody(data)}
                </table>
                <div style={{ width: this.state.pageNavWidth, marginTop: '20px', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'space-evenly', textAlign: 'center' }}>{this.renderPageLinks(data)}</div>
            </div>
            
        )
    }

    renderPageLinks = (data, maxItems) => {
        const toRender = [];
        if (data && this.state.maxItems) {
            const pages = Math.floor(data.length / this.state.maxItems);
            const remainder = data.length % this.state.maxItems;
            const totalPages = pages + (remainder > 0 ? 1 : 0);
            if (pages === 0) {
                return;
            }
            if (totalPages < 2) {
                return;
            }
            if (totalPages > 11) {
                const currentPage = this.state.currentPage;
                if (currentPage < 7) {
                    for (let i = 1; i <= 11; i++) {
                        toRender.push(<button disabled={this.state.currentPage === i} key={`table-${this.props.tableName}-page-${i}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{i}</button>);
                    }
                    toRender.push(<div>...</div>)
                    toRender.push(<button disabled={this.state.currentPage === totalPages} key={`table-${this.props.tableName}-page-${totalPages}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{totalPages}</button>);
                } else if (totalPages - 6 < currentPage) {
                    toRender.push(<button disabled={this.state.currentPage === 1} key={`table-${this.props.tableName}-page-${1}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{1}</button>);
                    toRender.push(<div>...</div>)
                    for (let i = totalPages - 8; i <= totalPages; i++) {
                        toRender.push(<button disabled={this.state.currentPage === i} key={`table-${this.props.tableName}-page-${i}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{i}</button>);
                    }
                } else {
                    toRender.push(<button disabled={this.state.currentPage === 1} key={`table-${this.props.tableName}-page-${1}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{1}</button>);
                    toRender.push(<div>...</div>)
                    for (let i = currentPage - 5; i <= currentPage + 5; i++) {
                        toRender.push(<button disabled={this.state.currentPage === i} key={`table-${this.props.tableName}-page-${i}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{i}</button>);
                    }
                    toRender.push(<div>...</div>)
                    toRender.push(<button disabled={this.state.currentPage === totalPages} key={`table-${this.props.tableName}-page-${totalPages}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{totalPages}</button>);
                }
            } else {
                for (let i = 1; i <= totalPages; i++) {
                    toRender.push(<button disabled={this.state.currentPage === i} key={`table-${this.props.tableName}-page-${i}`} className={`table-${this.props.tableName}-page`} onClick={this.setPage}>{i}</button>);
                }
            }
        }
        return toRender;
    }

    getFilterField = (field) => {
        const filterType = field.filterType ? field.filterType : 'text';
        const tableName = this.props.tableName;
        switch (field.filterType) {
            case 'dropdown':
                return;
            default:
                const value = this.state.filter[field.value] ? this.state.filter[field.value] : '';
                return (
                    <input key={`${tableName}-filter-${field.value}`} type="text" placeholder="Filter By" value={value} onChange={
                        (sender) => {
                            sender.preventDefault();
                            const updates = {};
                            updates[field.value] = sender.target.value.toLowerCase();
                            this.setState((prevState) => ( { filter: { ...prevState.filter, ...updates } } ) );
                        } }
                    />
                ); 
        }
    }

    renderTHead = () => {
        const toRender = [];
        const filterRender = [];
        const fields = this.props.fields;
        const tableName = this.props.tableName;
        if (fields) {
            const space = 100 / fields.length;
            for (const field of fields) {
                const fieldContent = [<div key={`${tableName}-header-title-${field.header}`}>{field.header}</div>];
                if (field.filterable === true) {
                    filterRender.push(<th><div key={`${tableName}-header-filter-${field.value}`}>{this.getFilterField(field)}</div></th>);
                } else {
                    filterRender.push(<th></th>);
                }
                toRender.push(<th key={`table-${this.props.tableName}-header-${field.header}`} style={{  }}>{fieldContent}</th>)
            }
        }
    
        return (
            <thead>
                <tr>
                    {filterRender}
                </tr>
                <tr>
                    {toRender}
                </tr>
            </thead>
        );
    }

    renderTBody = (data) => {
        const fields = this.props.fields;
        const currentPage = this.state.currentPage;
        const maxItems = this.state.maxItems;
        const toRender = [];
        if (data && fields) {
            const startIndex = (currentPage * maxItems) - maxItems;
            const cutOff = data.length < startIndex + maxItems ? data.length : startIndex + maxItems;
            const subSections = this.props.subSections;
            const subSectionMap = {};
            for (let i = startIndex; i < cutOff; i++) {
                const d = data[i];
                if (subSections) {
                    let subSectionName;
                    for (let j = 0; j < subSections.length; j++) {
                        const subSection = subSections[j];
                        if ( j === 0) {
                            subSectionName = d[subSection.value];
                            if (!subSectionMap[subSectionName]) {
                                subSectionMap[subSectionName] = {};
                                toRender.push(<tr style={{ textAlign: 'center', backgroundColor: subSection.color } }><td colSpan={fields.length}>{subSectionName}</td></tr>);
                            }
                            continue;
                        }
                        const subValue = d[subSection.value];
                        let subExtract = '';
                        for (let k = 0; k < subValue.props.children.length; k++) {
                            subExtract += subValue.props.children[k].length ? subValue.props.children[k] : '';
                        }
                        const subValueMap = `${subSectionName}-${subExtract}`;
                        if (subSectionMap[subSectionName][subValueMap] !== true) {
                            subSectionMap[subSectionName][subValueMap] = true;
                            toRender.push(<tr style={{ textAlign: 'left', backgroundColor: subSection.color } }><td colSpan={fields.length}>{subValue}</td></tr>);
                        }
                    }
                }
                const columns = this.renderBodyArray(d, i);
                toRender.push(<tr key={`table-${this.props.tableName}-tbody-r${i}`}>{columns}</tr>)
            }
        }
    
        return (
            <tbody>
                {toRender}
            </tbody>
        );
    }

    renderBodyArray = (data, i) => {
        const fields = this.props.fields;
        const columns = [];
        const space = 100 / fields.length;
        for (let j = 0; j < fields.length; j++) {
            const column = fields[j].value;
            const dataTextAlign = this.getTextAlign(fields[j].dataTextAlign);
            columns.push(<td key={`table-${this.props.tableName}-tbody-r${i}-c${j}`} style={{ width: `${space}%`, ...dataTextAlign }}>{data[column]}</td>)
        }
        return columns;
    }

    getTextAlign = (alignField) => {
        if (alignField) {
            return { textAlign: alignField };
        }
        return {}
    }

    setPage = (sender) => {
        this.setState(() => ( { currentPage: parseInt(sender.target.innerText) } ) );
    }
} 

export default KokoroTable;