import React, { useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { request } from '../../../../utils/requests';
import KokoroModal from '../../../Utils/KokoroModal';

const CohortAddStage = (props) => {
    const [type, setType] = useState(1);
    const [description, setDescription] = useState('');
    const [start, setStart] = useState('');
    const [due, setDue] = useState('');
    const [nudges, setNudges] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState();
    const [selectedSurveys, setSelectedSurveys] = useState();
    const [error, setError] = useState();
    return (
        <div>
            { props.showModal && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Add Stage</h3>
                        {
                            error && (
                                <h4 style={{ color: 'red' }}>Error: {error}</h4>
                            )
                        }
                        <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Type</legend>
                                <select value={type} onChange={(sender) => { sender.preventDefault(); setType(parseInt(sender.target.value))}}>
                                    {buildTypeOptions(props.types)}
                                </select>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Description</legend>
                                <textarea value={description} onChange={(sender) => { sender.preventDefault(); setDescription(sender.target.value) } } />
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Initial Date</legend>
                                <div><DatePicker selected={start} onChange={(date) => {  setStart(date) } } /></div>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Due Date</legend>
                                <div><DatePicker selected={due} onChange={(date) => {  setDue(date) } } /></div>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Nudges: <button onClick={(sender) => { const prevNudges = [...nudges]; prevNudges.push(''); setNudges(prevNudges); } } >+</button></legend>
                                {buildNudges(nudges, setNudges)}
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Select Users:</legend>
                                <div>
                                    {buildUsers(props.cohortUsers, selectedUsers, setSelectedUsers)}
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px' }}>
                                        <button onClick={
                                            (sender) => {
                                                const selectAllUsers = {};
                                                for (let i = 0; i < props.cohortUsers.length; i++) {
                                                    const user = props.cohortUsers[i];
                                                    if (user.is_active === true) {
                                                        selectAllUsers[user.id] = user;
                                                    }
                                                    setSelectedUsers(selectAllUsers);
                                                }
                                            } } >Check All</button>
                                        <button onClick={
                                            (sender) => {
                                                setSelectedUsers({});        
                                            }
                                        }>Uncheck All</button>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Select Surveys:</legend>
                                <div style={{ textAlign: 'left' }}>
                                    {buildSurveys(props.cohortSurveys, selectedSurveys, setSelectedSurveys)}
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px' }}>
                                        <button onClick={
                                            (sender) => {
                                                const selectAllSurveys = {};
                                                for (let i = 0; i < props.cohortSurveys.length; i++) {
                                                    const survey = props.cohortSurveys[i];
                                                    if (survey.is_active === true) {
                                                        selectAllSurveys[survey.id] = survey;
                                                    }
                                                    setSelectedSurveys(selectAllSurveys);
                                                }
                                            } } >Check All</button>
                                        <button onClick={
                                            (sender) => {
                                                setSelectedSurveys({});        
                                            }
                                        }>Uncheck All</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', padding: '1vw', margin: 'auto', marginTop: '2vh' }}>
                            <button onClick={
                                (sender) => {
                                    sender.target.disabled = false;
                                    // Need to develop rules around Each Type for now loose constraints
                                    const data = { type_id: type, cohort_id: props.cohort.id, description, nudges, start, due };
                                    if (due === '') {
                                        setError('User must select a Due Date');
                                        return;
                                    }
                                    const userEntries = selectedUsers && Object.entries(selectedUsers).length > 0 ? Object.entries(selectedUsers) : undefined;
                                    if (userEntries) {
                                        const user_ids = [];
                                        for (let i = 0; i < userEntries.length; i++) {
                                            user_ids.push(userEntries[i][0]);
                                        }
                                        data.user_ids = user_ids;
                                    }
                                    const surveyEntries = selectedSurveys && Object.entries(selectedSurveys).length > 0 ? Object.entries(selectedSurveys) : undefined;
                                    if (surveyEntries) {
                                        const survey_ids = [];
                                        for (let i = 0; i < surveyEntries.length; i++) {
                                            survey_ids.push(surveyEntries[i][0]);
                                        }
                                        data.survey_ids = survey_ids;
                                    }
                                    request('addCohortStage', data).then(res => {
                                        if (res.data.successful === true) {
                                            setType(1);
                                            setDescription('')
                                            setStart('')
                                            setDue('')
                                            setNudges([]);
                                            setSelectedUsers(undefined);
                                            setSelectedSurveys(undefined);
                                            setError();
                                            sender.target.disabled = false;
                                            props.closeModal(true);
                                        } else {
                                            setError("Failed to Add Stage, Unknown Reason");
                                            sender.target.disabled = false;
                                        }
                                    });
                                }
                            }>Save</button>
                            <button onClick={
                                () => {
                                    setType(1);
                                    setDescription('')
                                    setStart('')
                                    setDue('')
                                    setNudges([]);
                                    setSelectedUsers();
                                    setSelectedSurveys();
                                    setError();
                                    props.closeModal(false);
                                }
                            }>Cancel</button>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const buildTypeOptions = (types) => {
    const toRender = [];
    if (types) {
        for (let i = 0; i < types.length; i++) {
            const type = types[i];
            toRender.push(<option key={`AddState-Type-${type.id}`} value={type.id}>{type.name}</option>);
        }
    }
    return toRender;
}

const buildNudges = (nudges, setNudges) => {
    const toRender = [];
    if (nudges) {
        for (let i = 0; i < nudges.length; i++) {
            const nudge = nudges[i];
            toRender.push( <div key={`CohortAdd-Nudge-${i}`}><label>Nudge #{i}:</label><DatePicker selected={nudge} onChange={(date) => { const prevNudges = [...nudges]; prevNudges[i] = date; setNudges(prevNudges) } } /><button onClick={(sender) => { const prevNudges = [...nudges]; prevNudges.splice(i, 1); setNudges(prevNudges); } } >-</button></div>)
        }
    }
    return toRender;
}

const buildUsers = (users, selectedUsers, setSelectedUsers) => {
    let checkAll = !selectedUsers ? true : false;
    const prevSelectedUsers = checkAll !== true ? { ...selectedUsers } : {};
    if (checkAll === true) {
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.is_active === true) {
                prevSelectedUsers[user.id] = user;
            }
        }
    }

    const toRender = [];
    if (users) {
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.is_active !== true) {
                continue;
            }
            const name = `${user.first_name} ${user.last_name}`;
            const key= `StageAdd-User-Select-${user.id}`;
            toRender.push(
                <div key={key}>
                    <input type="checkbox" checked={prevSelectedUsers[user.id] !== undefined} name={key} value={user.id} onChange={(sender) => { if (sender.target.checked === true) { prevSelectedUsers[user.id] = user; } else { delete prevSelectedUsers[user.id] } setSelectedUsers(prevSelectedUsers) } } />
                    <label htmlFor={key}>{name}</label>
                </div>
            );
        }
        
    }

    if (checkAll === true) {
        setSelectedUsers(prevSelectedUsers);
    }

    return toRender;
}

const buildSurveys = (cohortSurveys, selectedSurveys, setSelectedSurveys) => {
    let checkAll = !selectedSurveys ? true : false;
    const prevSelectedSurveys = checkAll !== true ? { ...selectedSurveys } : {};
    if (checkAll === true) {
        for (let i = 0; i < cohortSurveys.length; i++) {
            const cohortSurvey = cohortSurveys[i];
            if (cohortSurvey.is_active === true) {
                prevSelectedSurveys[cohortSurvey.id] = cohortSurvey;
            }
        }
    }

    const toRender = [];
    if (cohortSurveys) {
        for (let i = 0; i < cohortSurveys.length; i++) {
            const cohortSurvey = cohortSurveys[i];
            if (cohortSurvey.is_active !== true) {
                continue;
            }
            const key= `StageAdd-Survey-Select-${cohortSurvey.id}`;
            toRender.push(
                <div key={key}>
                    <input type="checkbox" checked={prevSelectedSurveys[cohortSurvey.id] !== undefined} name={key} onChange={(sender) => { if (sender.target.checked === true) { prevSelectedSurveys[cohortSurvey.id] = cohortSurvey; } else { delete prevSelectedSurveys[cohortSurvey.id] } setSelectedSurveys(prevSelectedSurveys) } } />
                    <label htmlFor={key}>{cohortSurvey.survey}</label>
                </div>
            );
        }
        
    }

    if (checkAll === true) {
        setSelectedSurveys(prevSelectedSurveys);
    }

    return toRender;
}

export default CohortAddStage;