import React from 'react';

const AuthorizationRequired = (props) => {
    return (
        <div className="sd-root-modern">
            <div className='survey-message'>An authorized user account is required to use this site, please login to an authorized account.<br></br><br></br>If you think you are arriving on this message by mistake please email: <a href='mailto:support@joinkokoro.com'>Support Here</a></div>
        </div>
    )
};

export default AuthorizationRequired;