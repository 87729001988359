import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';

const OrganizationAddModal = (props) => {
    const [name, setName] = useState('');
    const [partner_id, setPartnerId] = useState(1)
    const [error, setError] = useState();
    return (
        <div>
            {
                props.showModal && (
                    <KokoroModal closeModal={props.closeModal}>
                        <div>
                            <h3>Add Organization</h3>
                            {
                                error && (
                                    <h4 style={{ color: 'red' }}>Error: {error}</h4>
                                )
                            }
                            <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Name</legend>
                                    <div>
                                        <label>Name:</label>
                                        <input type="text" value={name} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setName(sender.target.value);
                                            } }
                                        />
                                    </div>
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Partner</legend>
                                    <div>
                                        <label>Partner:</label>
                                        <select type="text" value={partner_id} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setPartnerId(parseInt(sender.target.value));
                                            } }
                                        >
                                            { generatePartnerSelect(props.partners) }
                                        </select>
                                    </div>
                                </fieldset>
                            </div>
                            <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', padding: '1vw', margin: 'auto', marginTop: '2vh' }}>
                                <button onClick={
                                    (sender) => {
                                        sender.target.disabled = true;
                                        // Validate fields
                                        if (!name || name.trim() === '') {
                                            setError("User Must Set a Name");
                                            sender.target.disabled = false;
                                            return;
                                        }

                                        if (partner_id < 1) {
                                            setError("Partner Id must be > 0");
                                            sender.target.disabled = false;
                                            return;
                                        }
                                        
                                        // Request Add
                                        request('addOrganization', { name, partner_id }).then(res => {
                                            if (res.data.successful === true) {
                                                setName('');
                                                setPartnerId(1);
                                                setError();
                                                sender.target.disabled = false;
                                                props.closeModal(true);
                                            } else {
                                                setError("Failed to Add Organization, Possible Duplicate");
                                                sender.target.disabled = false;
                                            }
                                        });
                                    } }
                                >
                                    Save
                                </button>
                                <button onClick={(sender) => { setName(''); setPartnerId(1); setError(); props.closeModal(); } }>Cancel</button>
                            </div>
                        </div>
                    </KokoroModal>
                )
            }
        </div>
    );
}

const generatePartnerSelect = (partners) => {
    const toRender = [];
        if (partners) {
            for (let i = 0; i < partners.length; i++) {
                const partner = partners[i];
                toRender.push(<option key={`AddOrg-Partner-${partner.id}`} value={partner.id}>{partner.name}</option>)
            }
        }
    return toRender;
}

export default OrganizationAddModal;