import React from 'react';
import KokoroTable from '../../../Utils/KokoroTable';
import KokoroModal from '../../../Utils/KokoroModal';
import CohortEditUsers from './CohortEditUsers';
import KokoroDialog from '../../../Utils/KokoroDialog';
import { request } from '../../../../utils/requests';

class CohortUsers extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        showModal: false,
        showDialog: false,
        dialogValue: undefined
    }

    handleCloseModal = (refresh) => {
        if (refresh === true) {
            this.setState((prevState) => ( { showModal: false } ) );
            this.props.refresh();
        } else {
            this.setState((prevState) => ( { showModal: false } ) );
        }
    }

    filterActiveUsers = (users, is_active) => {
        const displayUsers = [];
        if (users) {
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                user.deactivate = (<button onClick={() => { this.setState((prevState) => ( { dialogValue: user, showDialog: true } ) ) } }>Deactivate</button>);
                user.reactivate = (<button onClick={() => { this.setState((prevState) => ( { dialogValue: user, showDialog: true } ) ) } }>Reactivate</button>);
                if (user.is_active === is_active) {
                    displayUsers.push(user);
                }
            }
        }
        return displayUsers;
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updateCohortUser', { id: value.id, is_active: !value.is_active }).then(res => {
                this.props.refresh();
                this.setState((prevState) => ( { showDialog: false, dialogValue: null } ) );
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, dialogValue: null } ) );
        }
    }

    render = () => {
        const fields = [
            { header: 'Id', value: 'user_id' },
            { header: 'First Name', value: 'first_name', filterable: true, sortable: true},
            { header: 'Last Name', value: 'last_name', filterable: true, sortable: true}
        ];
        if (this.props.isEditting !== true) {
            fields.push(
                { header: 'Company', value: 'company', filterable: true, sortable: true },
                { header: 'Email', value: 'email', filterable: true, sortable: true }
            );
        }
        return (
            <div style={{ marginBottom: '30px', borderStyle: 'solid', padding: '30px' }}>
                <h3>Users</h3>
                <fieldset>
                    <legend style={{ textAlign: 'left' }}>Active Users</legend>
                    <KokoroTable style={{ width: '100%' }} fields={[ 
                        ...fields,
                        { header: 'Deactivate', value: 'deactivate' }
                    ]}
                    data={this.filterActiveUsers(this.props.cohortUsers, true)}
                    tableName="ActiveCohortUsers" />
                </fieldset> 
                <fieldset>
                    <legend style={{ textAlign: 'left' }}>Inactive Users</legend>
                    <KokoroTable style={{ width: '100%' }} fields={[ 
                        ...fields,
                        { header: 'Reactivate', value: 'reactivate' }
                    ]}
                    data={this.filterActiveUsers(this.props.cohortUsers, false)}
                    tableName="InactiveCohortUsers" />
                </fieldset>
                <button className="cohortAddButton" onClick={() => { this.setState((prevState) => ( { showModal: true } ) ); }}>Add</button>
                <CohortEditUsers closeModal={this.handleCloseModal} showModal={this.state.showModal} organizations={this.props.organizations} users={this.props.users} cohortUserLookup={this.props.cohortUserLookup} cohort={this.props.cohort} />
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Company Status?" value={this.state.dialogValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }
}

export default CohortUsers;