// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from 'firebase/firestore/lite';
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider, browserLocalPersistence, setPersistence } from "firebase/auth";
import { clearUser } from "../utils/requests";

const provider = new GoogleAuthProvider();

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDYh_cXH4W2V27B4tDitIT8WEvgIyPQCfI",
    authDomain: "kokoro-measurements.firebaseapp.com",
    projectId: "kokoro-measurements",
    storageBucket: "kokoro-measurements.appspot.com",
    messagingSenderId: "643930156497",
    appId: "1:643930156497:web:77207387c465823c8c44f0",
    measurementId: "G-VBMR14HYYS"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database
const getSurvey = async (guid) => {
    const responsesDocRef = doc(db, `responses`, guid);
    const responsesDocSnap = await getDoc(responsesDocRef);
    if (responsesDocSnap.exists()) {
        const response = responsesDocSnap.data();
        if (response.isExpired) {
            //Survey expired
            throw new Error(7);
        }
        if (response.isComplete !== true) {
            if (response.surveyId) {
                const surveyRef = doc(db, 'surveys', response.surveyId);
                const surveySnap = await getDoc(surveyRef);
                if (surveySnap.exists()) {
                    return surveySnap.data();                    
                } else {
                    // Survey data not available
                    throw new Error(1);
                }
            } else {
                // SurveyId is missing from user data
                throw new Error(4);
            }
        } else {
            // Survey already completed
            throw new Error(2);
        }
    } else {
        // Guid doesn't exist
        throw new Error(3);
    }
}

const getApiKey = async (uid) => {
    const userDocRef = doc(db, `users`, uid);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
        return userDocSnap.data();
    }
    return null;
}

const auth = getAuth(app);

const getUser = () => {
    return auth.currentUser;
}

const isLoggedIn = () => {
    const user = getUser();
    return !!(user && user.uid);
}

const logIn = () => {
    return setPersistence(auth, browserLocalPersistence).then(() => {
        return signInWithPopup(auth, provider)
    }).catch((error) => {
        console.log(error);
        return error;
    });
}

const logOut = async () => {
    await signOut(auth);
    clearUser();
}

export default getSurvey;
export { logIn, logOut, getUser, isLoggedIn, getApiKey, GoogleAuthProvider };