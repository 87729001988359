import React from 'react';
import KokoroTable from '../../Utils/KokoroTable';
import CompanyAddModal from './CompanyAddModal';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import CompanyDetailsModal from './CompanyDetailsModal';

class CompanyManagement extends React.Component {
    state = {
        showAddModal: false,
        showDetailsModal: false,
        detailsModalValue: null,
        showDialog: false,
        companies: null,
        statusChangeValue: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getCompanies();
    }

    // Add process to monitor for terminate date
    // Alternative Email for users
    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Company Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ); }}>Add New Company</button>
                <CompanyAddModal showAddModal={this.state.showAddModal} handleAddModal={this.handleAddModal} />
                <CompanyDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsModalValue} closeModal={this.handleDetailsModal} />
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[{ header: 'Name', value: 'name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Contact Name', value: 'contact_name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Contact Email', value: 'contact_email', filterable: true, sortable: true }, { header: 'Contact Phone', value: 'contact_phone', filterable: true, sortable: true }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status'} ]} data={this.state.companies} maxItems={10} tableName="Companies" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Company Status?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    getCompanies = () => {
        request('getCompanies').then(res => {
            const companies = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const company of res.data.rows) {
                    if (company.id === 1) {
                        company.modify_status = 'N/A';
                    } else {
                        company.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: company } ) ) } }>{company.is_active === true ? 'Deactivate' : 'Reactivate'}</button>);
                    }
                    company.details = (<button onClick={() => { this.setState((prevState) => ( { showDetailsModal: true, detailsModalValue: company } ) ) } }>Details</button>)
                    company.status = company.is_active === true ? 'Active' : 'Inactive';
                    companies.push(company);
                }
            }
            this.setState((prevState) => ( { companies, showAddModal: false, showDialog: false, statusChangeValue: null, showDetailsModal: false, detailsModalValue: null } ) );
        }, reason => {
    
        });
    }

    handleAddModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showAddModal: false } ) );
        }
    }

    handleDetailsModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsModalValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updateCompany', { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }
}

export default CompanyManagement;