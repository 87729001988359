import React from 'react';
import KokoroModal from '../../../Utils/KokoroModal';

const CohortCreateTemplate = (props) => {
    return (
        <div>
            { props.showModal === true && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Create Cohort Template</h3>
                        <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw', margin: 'auto' }}>
                            <legend style={{ textAlign: 'left' }}>Template</legend>
                            <div><label>Name:</label><input type="text" /></div>
                            <div><label>Save Dates:</label><input type="checkbox" /></div>
                            <div><button>Save</button><button>Cancel</button></div>
                        </fieldset>
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

export default CohortCreateTemplate;