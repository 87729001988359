import React from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { request } from '../../../utils/requests';

import "react-datepicker/dist/react-datepicker.css";

class SurveyMembershipDetails extends React.Component {
    state = {
        surveyMembership: null,
        survey: null,
        organizations: null,
        userMembership: null,
        memberLookup: [],
        orgUsers: null,
        users: null,
        orgId: null,
        schedule: null,
        dateChanges: { },
        manualSchedule: { ...this.defaultSchedule },
        manualError: null,
        smId: 0,
        search: ""
    }

    today = new Date();
    defaultSchedule = { send_at: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1), expires_at: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 2),  reminder_count: 1, reminder_frequency: 2};
    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurveyMembership();
        }
    }

    updateIsActive = (sender) => {
        sender.preventDefault();
        sender.target.disabled = true;
        request('surveyMembership/setIsActive', { is_active: !this.state.surveyMembership.is_active, id: this.state.smId }).then(res => {
            this.getSurveyMembership();
            sender.target.disabled = false;
        }, reason => {

        });
    }

    updateIsAutomated = (sender) => {
        sender.preventDefault();
        sender.target.disabled = true;
        request('surveyMembership/setAutomateTiming', { automate_timing: !this.state.surveyMembership.automate_timing, id: this.state.smId }).then(res => {
            this.getSurveyMembership();
            sender.target.disabled = false;
        }, reason => {

        });
    }

    updateAutomatedTimings = (sender) => {
        sender.target.disabled = true;
        request('surveyMembership/updateAutomatedTimings', { id: this.state.smId, dateChanges: this.state.dateChanges }).then(res => {
            this.getSurveyMembership();
            sender.target.disabled = false;
        }, reason => {

        });
    }

    addManualSchedule = (sender) => {
        sender.target.disabled = true;
        const manualSchedule = this.state.manualSchedule;
        if (manualSchedule.reminder_count && manualSchedule.reminder_frequency && manualSchedule.expires_at && manualSchedule.send_at) {
            if (manualSchedule.reminder_count < 1 || manualSchedule.reminder_frequency < 1) {
                this.setState( (prevState) => ( { manualError: "Error reminder values must be greater than 1" } ) );
                sender.target.disabled = false;
                return;
            }
            manualSchedule.send_at = new Date(manualSchedule.send_at);
            manualSchedule.expires_at = new Date(manualSchedule.expires_at);
            if (manualSchedule.send_at > manualSchedule.expires_at) {
                this.setState( (prevState) => ( { manualError: `Error send at has to be less than expires at send_at=${manualSchedule.send_at} vs expires_at=${manualSchedule.expires_at}` } ) );
                sender.target.disabled = false;
                return;
            }
            const date = Date.now();
            if (manualSchedule.send_at < date) {
                this.setState( (prevState) => ( { manualError: "Error send at must be greater than today" } ) );
                sender.target.disabled = false;
                return;
            }
            request('surveyGroups/addGroup', { id: this.state.smId, manualSchedule: manualSchedule }).then(res => {
                this.getSurveyMembership();
                sender.target.disabled = false;
            }, reason => {

            });
        } else {
            this.setState( (prevState) => ( { manualError: "Must set all fields to add a schedule manually" } ) );
            sender.target.disabled = false;
        }
    }

    updateMembers = (sender) => {
        sender.target.disabled = true;
        const ids = [];
        const users = document.getElementsByClassName("remove-member-cb");
        for(let i = 0; i < users.length; i++) {
            const element = users[i];
            if (element.checked) {
                ids.push(element.value);
            }
        };
        if (Object.entries(ids).length > 0) {
            request('surveyMembership/users/removeMembers', { ids }).then(res => {
                this.getSurveyMembership();
                sender.target.disabled = false;
            }, reason => {
        
            });
        } else {
            sender.target.disabled = false;
        }
    }

    addMembers = (sender) => {
        sender.target.disabled = true;
        const ids = {};
        const users = document.getElementsByClassName("users-add-cb");
        for(let i = 0; i < users.length; i++) {
            const element = users[i];
            if (element.checked) {
                ids[element.value] = {user_id: element.value, org_id: null, smId: this.state.smId };
            }
        };
        const orgMembers = document.getElementsByClassName("org-members-add-cb");
        for(let i = 0; i < orgMembers.length; i++) {
            const element = orgMembers[i];
            if (element.checked) {
                ids[element.value] = {user_id: element.value, org_id: this.state.orgId, smId: this.state.smId };
            }
        };
        if (Object.entries(ids).length > 0) {
            request('surveyMembership/users/addMembers', { ids }).then(res => {
                this.getSurveyMembership();
                sender.target.disabled = false;
            }, reason => {
        
            });
        } else {
            sender.target.disabled = false;
        }
    }
    
    render = () => {
        const surveyMembership = this.state.surveyMembership;
        const survey = this.state.survey;
        const manualError = this.state.manualError;
        const validTiming = surveyMembership ? surveyMembership.reminder_count && surveyMembership.expiration_days && surveyMembership.monthly_interval && surveyMembership.reminder_frequency : false;
        return (
            <div>
                <div>
                    <h2>Survey Membership Details Admin</h2>
                    <h3>Survey Membership: {(surveyMembership ? surveyMembership.name : "")}</h3>
                    <h3>Survey Display Name: {(survey ? survey.display_name : "")}</h3>
                    <h3>Survey Name: {(survey ? survey.name : "")}</h3>
                    <h3>Survey Id: {(surveyMembership ? surveyMembership.survey_id : "")}</h3>
                    <h3>Survey Membership Id: {this.state.smId}</h3>
                    <h3><button onClick={this.getSurveyMembership}>Refresh</button></h3>
                    <h3>
                        <label>Is Active</label>
                        <input type="checkbox" id="isActive"
                            checked={surveyMembership ? surveyMembership.is_active : false}
                            onChange={this.updateIsActive}>
                        </input>
                    </h3>
                </div>
                <div>
                    <h3>Timing</h3>
                    <label>Automate Timing</label>
                    <input id='isAutomated' type="checkbox" checked={surveyMembership ? surveyMembership.automate_timing : false } onChange={this.updateIsAutomated} />
                    {
                        (surveyMembership && surveyMembership.automate_timing) && (
                            <div>
                                {!validTiming && (<div style={{ color: 'red' }}>SETTINGS ARE NOT VALID FOR AUTOMATED</div>)}
                                <div>
                                    {this.renderDateSettings()}
                                </div>
                                <button onClick={this.updateAutomatedTimings}>Save</button>
                            </div>
                        ) || (
                            <div>
                                <div>Manual Scheduling</div>
                                {manualError && (<div style={{ color: 'red' }}>{manualError}</div>)}
                                {this.renderManualSchedule()}
                                <button onClick={this.addManualSchedule}>Add</button>
                            </div>
                        )
                    }
                </div>
                <div>
                    <h2>Schedule</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {this.renderSchedule()}
                    </div>
                </div>
                <div>
                    <div>
                        <h3>Current Members</h3>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {this.renderUserMemberships(true)}
                        </div>
                    </div>
                    <div>
                        <h3>Inactive Members</h3>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {this.renderUserMemberships(false)}
                        </div>
                    </div>
                    <button style={{ marginTop: '20px' }} onClick={this.updateMembers}>Save</button>
                </div>
                <div>
                    <h2>Add Users</h2>
                    <button onClick={this.addMembers}>Save</button>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <div>
                        <h3>Users List</h3>
                        <h4><input type="textbox" onChange={ this.setSearch } /></h4>
                        {this.renderUsers(this.state.search)}
                        </div>
                        <div>
                            <h3>Organizations</h3>
                            {this.renderOrganizations()}
                            {this.renderOrgMembers()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getSmId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const smId = pathSplit[pathSplit.length - 1];
        return smId;  
    }

    getSurveyMembership = () => {
        const smId = this.getSmId()
        request(`surveyMembership/getSurveyMembership/${smId}`).then(res => {
            let surveyMembership = [];
            if (res && res.data && res.data.rows && res.data.rows.length === 1) {
                surveyMembership = res.data.rows[0];
            }
            this.setState((prevState) => ( { surveyMembership, manualSchedule: { ...this.defaultSchedule }, dateChanges: null, smId } ) );
            if (surveyMembership.survey_id) {
                this.getSchedule(smId, surveyMembership.survey_id);
            }
        }, reason => {
    
        });
    }

    getSchedule = (smId, surId) => {
        request(`surveyGroups/getGroups/${smId}`).then(res => {
            let schedule = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                schedule = res.data.rows;
            }
            this.setState((prevState) => ( { schedule } ) );
            this.getSurvey(smId, surId);
        }, reason => {
    
        });
    }

    getSurvey = (smId, surId) => {
        request(`surveys/getSurvey/${surId}`).then(res => {
            this.setState((prevState) => ( { survey: res && res.data && res.data.rows && res.data.rows.length === 1 ? res.data.rows[0] : [] } ) );
            this.getUserMembership(smId);
        }, reason => {
    
        });
    }



    getUserMembership = (smId) => {
        request(`surveyMembership/users/getMembers/${smId}`).then(res => {
            const memberLookup = [];
            let userMembership = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                userMembership = res.data.rows;
            }
            for(let i = 0; i < userMembership.length; i++) {
                const element = userMembership[i];
                if (element.is_active === true) {
                    memberLookup.push(element.user_id);
                }
            }
            this.setState((prevState) => ( { memberLookup, userMembership } ) );
            this.getOrganizations();
        }, reason => {
    
        });
    }

    getOrganizations = () => {
        request('getOrganizations').then(res => {
            let organizations = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                organizations = res.data.rows;
            }
            let orgId = 0;
            for(let i = 0; i < organizations.length; i++) {
                const element = organizations[i];
                if (element.is_active) {
                    orgId = element.id;
                    break;
                }
            }
            this.setState((prevState) => ( { organizations, orgId } ) );
            this.getOrgUsers();
        }, reason => {
    
        });
    }

    getOrgUsers = () => {
        request('orgmap/getAllUsers').then(res => {
            let orgUsers = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                orgUsers = res.data.rows;
            }
            this.setState((prevState) => ( { orgUsers } ) );
            this.getUsers();
        }, reason => {
    
        });
    }

    getUsers = () => {
        request('getUsers').then(res => {
            let users = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                users = res.data.rows;
            }
            this.setState((prevState) => ( { users } ) );
        }, reason => {
    
        });
    }
    
    getOrgMembership = (surId) => {
        request(`surveyMemberships/orgs/getOrgs/${surId}`).then(res => {
            // Obsolete
        }, reason => {
    
        });
    }
    
    renderDateSettings = () => {
        const surveyMembership = this.state.surveyMembership;
        const dateChanges = this.state.dateChanges;
        if (!surveyMembership) {
            return (<div></div>);
        }
        let startTime = null;
        let endTime = null;
        if (surveyMembership.start_date) {
            const splitT = surveyMembership.start_date.split('T');
            const splitTime = splitT[0].split('-');
            
            startTime = new Date(splitTime[0], splitTime[1] - 1, splitTime[2]);
        } else {
            const today = new Date();
            today.setDate(today.getDate() + 1);
            startTime = today;
        }
        if (surveyMembership.end_date) {
            const splitT = surveyMembership.end_date.split('T');
            const splitTime = splitT[0].split('-');
            
            endTime = new Date(splitTime[0], splitTime[1] - 1, splitTime[2]);
        } else {
            const today = new Date();
            today.setDate(today.getDate() + 2);
            endTime = today;
        }
    
        const monthly_interval = surveyMembership.monthly_interval ? surveyMembership.monthly_interval : 1;
        const expiration_days = surveyMembership.expiration_days ? surveyMembership.expiration_days : 1;
        const reminder_count = surveyMembership.reminder_count ? surveyMembership.reminder_count : 1;
        const reminder_frequency = surveyMembership.reminder_frequency ? surveyMembership.reminder_frequency : 1;
        
        return (
            <div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div><label>Start Date:</label><DatePicker selected={dateChanges && dateChanges.start_date ? dateChanges.start_date : startTime} onChange={(date) => { this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, start_date: date }; return { dateChanges } } ) } } /></div>
                    <div><label>End Date:</label><DatePicker selected={dateChanges && dateChanges.end_date ? dateChanges.end_date : endTime} onChange={(date) => this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, end_date: date }; return { dateChanges } } ) } /></div>
                </div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                    <div><label>Monthly Cadence:</label><input type="number" min="1" max="12" step="1" value={dateChanges && dateChanges.monthly_interval ? dateChanges.monthly_interval : monthly_interval} onChange={(sender) => { this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, monthly_interval: sender.target.value }; return { dateChanges } } ) } }/></div>
                    <div><label>Days til Expired:</label><input type="number" min="1" max="30" step="1" value={dateChanges && dateChanges.expiration_days ? dateChanges.expiration_days : expiration_days} onChange={(sender) => { this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, expiration_days: sender.target.value }; return { dateChanges } } ) } }/></div>
                </div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                    <div><label>Reminder Count:</label><input type="number" min="1" max="12" step="1" value={dateChanges && dateChanges.reminder_count ? dateChanges.reminder_count : reminder_count} onChange={(sender) => { this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, reminder_count: sender.target.value }; return { dateChanges } } ) } }/></div>
                    <div><label>Reminder Frequency:</label><input type="number" min="1" max="12" step="1" value={dateChanges && dateChanges.reminder_frequency ? dateChanges.reminder_frequency : reminder_frequency} onChange={(sender) => { this.setState((prevState) => { const dateChanges = { ...prevState.dateChanges, reminder_frequency: sender.target.value }; return { dateChanges } } ) } }/></div>
                </div>
            </div>
        );
    }

    setSearch = (sender) => {
        sender.preventDefault();
        this.setState( (prevState) => ( { search: sender.target.value } ) );
    }
    
    renderManualSchedule = () => {
        const manualSchedule = this.state.manualSchedule;
        return (
            <div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly' }}>
                <div><label>Send Date:</label><DatePicker selected={manualSchedule.send_at} onChange={(date) => this.setState((prevState) => { const manualSchedule = { ...prevState.manualSchedule, send_at: date }; return { manualSchedule } } ) } /></div>
                <div><label>Expires Date:</label><DatePicker selected={manualSchedule.expires_at} onChange={(date) => this.setState((prevState) => { const manualSchedule = { ...prevState.manualSchedule, expires_at: date }; return { manualSchedule } } ) } /></div>
            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                <div><label>Reminder Count:</label><input type="number" min="1" max="12" step="1" value={manualSchedule.reminder_count} onChange={(sender) => { this.setState( (prevState) => { const manualSchedule = { ...prevState.manualSchedule, reminder_count: sender.target.value }; return  { manualSchedule } } ) } }/></div>
                <div><label>Reminder Frequency:</label><input type="number" min="1" max="12" step="1" value={manualSchedule.reminder_frequency} onChange={(sender) => { this.setState( (prevState) => { const manualSchedule = { ...prevState.manualSchedule, reminder_frequency: sender.target.value }; return  { manualSchedule } } ) } }/></div>
            </div>
        </div>
        )
    }
    
    renderSchedule = () => {
        const schedule = this.state.schedule;
        const toRender = [];
        if (schedule) {
            schedule.forEach(element => {
                toRender.push(
                    <tr key={element.id}>
                        <td>{element.id}</td>
                        <td>{element.send_at}</td>
                        <td>{element.expires_at}</td>
                        <td>{element.reminder_count}</td>
                        <td>{element.reminder_frequency}</td>
                        <td>{element.is_cancelled === true ? 'true' : 'false'}</td>
                        <td>{element.processed === true ? 'true' : 'false'}</td>
                        <td>{element.processed === true ? <button><Link to={`../admin/surveyGroup/${element.id}`}>Details</Link></button> : element.is_cancelled === true ? 'Survey Group Was Cancelled' : 'Link available after Processed'}</td>
                    </tr>
                );
            });
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>send_at</th>
                        <th>expires_at</th>
                        <th>reminder_count</th>
                        <th>reminder_frequency</th>
                        <th>is_cancelled</th>
                        <th>is_processed</th>
                        <th>details</th>
                    </tr>
                </thead>
                <tbody>
                    {toRender}
                </tbody>
            </table>
        );
    }
    
    renderUserMemberships = (isActive) => {
        const userMembership = this.state.userMembership;
        const toRender = [];
        if (userMembership) {
            userMembership.forEach((element) => {
                if (element.member_active != isActive || element.is_active !== true) {
                    return;
                }
                toRender.push(
                    <tr key={`mem-row-${element.id}`}>
                        <td><input className="remove-member-cb" value={element.id} type="checkbox"/></td>
                        <td>{element.user_id}</td>
                        <td>{element.first_name}</td>
                        <td>{element.last_name}</td>
                        <td>{element.email}</td>
                        <td>{element.org_name}</td>
                    </tr>
                );
            });
        }
        return (
            <table>
                <thead>
                    <tr>
                        <td>Remove</td>
                        <td>User Id</td>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Email</td>
                        <td>Org Name</td>
                    </tr>
                </thead>
                <tbody>
                    {toRender}
                </tbody>
            </table>
        )
    }
    
    renderOrgMembers = () => {
        const orgUsers = this.state.orgUsers;
        const memberLookup = this.state.memberLookup;
        const orgId = this.state.orgId;
        const toRender = [];
        if (orgUsers) {
            orgUsers.forEach(element => {
                if (element.org_id != orgId || memberLookup.indexOf(element.user_id) > -1) {
                    return;
                }
                const key = `orgMember-${element.user_id}`;
                const label = `label-orgMember-${element.user_id}`;
                const br = `br-orgMember-${element.user_id}`;
                toRender.push(<input className="org-members-add-cb" type="checkbox" key={key} id={key} value={element.user_id} />);
                toRender.push(<label key={label} htmlFor={key}>{element.first_name} {element.last_name} - {element.email}</label>);
                toRender.push(<br key={br}></br>);
            });
        }
        return (
            <div>
                <form>
                    {toRender}
                </form>
            </div>
        );
    }
    
    renderUsers = (search) => {
        const users = this.state.users;
        const memberLookup = this.state.memberLookup;
        const toRender = [];
        search = search.toLowerCase();
        if (users) {
            for(let i = 0; i < users.length; i++) {
                const user = users[i];
                if (memberLookup.indexOf(user.id) > -1 || !user.is_active) {
                    continue;
                }
                if (search !== "" && user.first_name.toLowerCase().indexOf(search) === -1) {
                    continue;
                }
                const key = `users-${user.id}`;
                const label = `label-user-${user.id}`;
                const br = `br-user-${user.id}`;
    
                toRender.push(<input className="users-add-cb" type="checkbox" key={key} id={key} value={user.id} />);
                toRender.push(<label key={label} htmlFor={key}>{user.first_name} {user.last_name} - {user.email}</label>);
                toRender.push(<br key={br}></br>);
            }
        }
        return (
            <div>
                <form>
                    {toRender}
                </form>
            </div>
        );
    }
    
    renderOrganizations = () => {
        const organizations = this.state.organizations;
        const toRender = [];
        if (organizations) {
            organizations.forEach(element => {
                if (!element.is_active) {
                    return;
                }
                const key = `org-${element.id}`;
                toRender.push(<option key={key} value={element.id}>{element.name}</option>)
            });
        }
    
        return (<select id="organization-select" onChange={(sender) => { this.setState((prevState) => ( { orgId: sender.target.value } ) ); }}>{toRender}</select>)
    }
    
    renderOrgUsers = () => {
        const orgUsers = this.state.orgUsers;
        const toRender = [];
        if (orgUsers) {
            orgUsers.forEach(element => {
                if (!element.is_active) {
                    return;
                }
                const key = `orgUser-${element.user_id}`;
                const label = `label-orgUser-${element.user_id}`;
                const br = `br-orgUser-${element.user_id}`;
                toRender.push(<input className="org-users-add-cb" type="checkbox" key={key} id={key} value={element.user_id} />);
                toRender.push(<label key={label} htmlFor={key}>{element.user_id}</label>);
                toRender.push(<br key={br}></br>);
            });
        }
        return (<div><form>{toRender}</form></div>);
    }
}

export default SurveyMembershipDetails;