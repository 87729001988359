import React from 'react';
import { request } from '../../../utils/requests';

class PracticeDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        practice: null,
        error: null,
        changes: null,
        pId: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getPractice();
        }
    }

    getPractice = () => {
        const pId = this.getpId();
        request(`practices/getPractice/${pId}`).then(res => {
            let practice = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                practice = res.data.rows[0];
            }
            this.setState((prevState) => ( { practice, pId } ) );
        }, reason => {
    
        });
    }

    getpId = () => {
        const pathSplit = window.location.pathname.split('/');
        const pId = pathSplit[pathSplit.length - 1];
        return pId;  
    }

    onChange = (sender) => {
        sender.preventDefault();
        this.setState((prevState) => ({ changes: sender.target.value }));
    }

    savePreviewContent = (sender) => {
        const content = document.getElementById("htmlPreview").contentDocument.body.innerHTML;
        if (this.state.changes !== content) {
            alert("You should preview your current changes before saving - This protection is disabled for the moment");
            //return;
        }
        if (this.state.practice.content === content) {
            alert("There are no changes to save");
            return;
        }

        sender.target.disabled = true;
        const data = {
            pId: this.state.pId,
            content 
        }
        request(`practices/updatePractice`, data).then(res => {
            if (res.data.success === true) {
                this.getPractice();
                this.setState((prevState) => ( { error: null } ) );
            } else {
                this.setState((prevState) => ( { error: "Error updating practice: Reason Unknown" } ) );
            }
            sender.target.disabled = false;
        }, reason => {
            this.setState((prevState) => ( { error: `Error updating practice: Reason ${reason}` } ) );
            sender.target.disabled = false;
        });
    }

    previewPDF = (sender) => {
        if (!this.state.practice) {
            return;
        }
        request(`previewPractice`, { htmlContent: document.getElementById("htmlPreview").contentDocument.body.innerHTML, name: this.state.practice.name }, true).then(res => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.setAttribute('download', `${this.state.practice.name}.pdf`)
            link.click();
            link.remove();
            URL.revokeObjectURL(blobUrl);
        }, reason => {
            console.log(reason);
        });
    }

    updatePreview = (sender) => {
        const content = document.getElementById("contentText");
        document.getElementById("htmlPreview").contentDocument.body.innerHTML = content.value;
    }

    isPracticeUpdate = (sender) => {
        sender.preventDefault();
        if (!this.state.practice) {
            return;
        }

        sender.target.disabled = true;
        const data = {
            pId: this.state.pId,
            is_practice: sender.target.checked 
        }

        request(`practices/updateIsPractice`, data).then(res => {
            if (res.data.success === true) {
                this.getPractice();
                this.setState((prevState) => ( { error: null } ) );
            } else {
                this.setState((prevState) => ( { error: "Error updating practice: Reason Unknown" } ) );
            }
            sender.target.disabled = false;
        }, reason => {
            this.setState((prevState) => ( { error: `Error updating practice: Reason ${reason}` } ) );
            sender.target.disabled = false;
        });
    }

    render = () => {
        const savedValue = this.state.practice && this.state.practice.content ? this.state.practice.content : "";
        const value = this.state.changes ? this.state.changes : savedValue;
        const isPractice = this.state.practice && this.state.practice.is_practice === true ? true : false;
        return (
            <div>
                <div>
                    <h1>Practice Details</h1>
                    <h2>Practice Id: {this.state.practice ? this.state.practice.id : 'Loading'}</h2>
                    <h2>Practice Name: {this.state.practice ? this.state.practice.name : 'Loading' }</h2>
                    <h2>Is Practice: <input type="checkbox" id="thing" onClick={this.isPracticeUpdate} onChange={(sender) => {sender.preventDefault();}} checked={isPractice} /></h2>
                    <button onClick={this.updatePreview}>Preview</button>
                </div>
                <div>
                    <span style={{ display:'block', maxWidth: "100%"}} >
                        <textarea id="contentText" style={{ maxWidth: "100%", width: "100%", height: "300px" }} type="textarea" wrap="false" value={value} onChange={this.onChange} />
                    </span>
                    <span style={{ maxWidth: "100%"}}>
                        {this.state.error && (
                            <div style={{ color: "red" }}>Error attempting to save Practice: {this.state.error}</div>
                        )}
                        <iframe style={{ maxWidth: "100%", width: "100%", height: "900px" }} id="htmlPreview"></iframe>
                        <div style={{ display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={this.savePreviewContent}>Save</button>
                            <button onClick={this.previewPDF}>Preview PDF</button>
                        </div>
                    </span>
                </div>
            </div>
        )
    }
}

export default PracticeDetails;