import React from 'react';
import { Survey } from 'survey-react-ui';
import { request } from '../../../utils/requests';

class SurveyScore extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        score: "Calculating...",
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurveyAnswers(this.props.asguId);
        }
    }

    getSurveyAnswers = (sId) => {
        if (sId === -1) {
            return;
        }
        request(`userSurveyAnswers/getAnswers/${sId}`).then(res => {
            let surveyAnswers = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                surveyAnswers = res.data.rows;
            }
            this.getScore(surveyAnswers);
        }, reason => {
    
        });
    }

    getScore = (surveyAnswers) => {
        let score = 0;
        let noReverseScore = 0;
        let total = 0;
        const scoreBreakDown = {};
        if (surveyAnswers && surveyAnswers.length > 0) {
            for(const surveyAnswer of surveyAnswers) {
                if (this.props.surveyName === 'Self-Compassion') {
                    if (!scoreBreakDown[surveyAnswer.answer_category]) {
                        scoreBreakDown[surveyAnswer.answer_category] = {
                            total: 0,
                            score: 0,
                            normalizedScore: (score, total) => { return (score / total).toFixed(2); }
                        }
                    }
                    scoreBreakDown[surveyAnswer.answer_category].total++;
                }
                total++;
                if ((!surveyAnswer.answer_key) !== false) {
                    continue;
                }
                const answer_key = surveyAnswer.answer_key;
                let myScore = surveyAnswer.matrix_value > 0 ? surveyAnswer.matrix_value : surveyAnswer.value;
                noReverseScore += myScore;
                if (surveyAnswer.reverse_coded) {
                    myScore = 6 - myScore;
                }
                score += myScore;
                if (this.props.surveyName === 'Self-Compassion') {
                    scoreBreakDown[surveyAnswer.answer_category].score += myScore;
                }
            }
        }
        if (this.props.surveyName === 'Self-Compassion') {
            score = (score / total).toFixed(2);
        }
        this.setState((prevState) => ( { score } ) );
        if (this.props.calculatedScore) {
            if (this.props.surveyName === 'Self-Compassion') {
                this.props.calculatedScore(scoreBreakDown);
            } else {
                this.props.calculatedScore(score);
            }
            
        }
    }

    scoreValue = () => {
        console.log("here");
    }
    
    render = () => {
        return (<div>{this.state.score}</div>);
    }
}

export default SurveyScore;