import React from 'react';
import { Navigate } from 'react-router-dom';

const Landing = (props) => {
    const landing = getLandingPage(props.level);

    return (
        <Navigate to={`/admin/${landing}`} replace={true} />
    )
}

const getLandingPage = (level) => {
    switch (level) {
        case 3:
            return 'cohort';
        case 2:
            return 'partners';
        case 1:
            return 'practices';
            break;
        default:
            return 'unauthorized';
    }
}

export default Landing;