import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class Practices extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        practices: null,
        error: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getPractices();
        }
    }

    getPractices = () => {
        request(`practices/getPractices`).then(res => {
            let practices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                practices = res.data.rows;
            }
            this.setState((prevState) => ( { practices } ) );
        }, reason => {
    
        });
    }

    renderPractices = () => {
        const toRender = [];
        if (this.state.practices && this.state.practices.length > 0) {
            for (const practice of this.state.practices) {
                toRender.push(<tr key={practice.id}><td>{practice.name}</td><td><Link to={`${practice.id}`}>Edit</Link></td></tr>)
            }
        }
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Edit Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {toRender}
                    </tbody>
                </table>
            </div>
        );
    }

    renderAddPractices = () => {
        return (<div>
            <label>Name: </label><input id="addPracticeInput" type="text"></input>
            { this.state.error && (
                <h3 style={{ color: "red" }}>An Error Occurred: {this.state.error}</h3>
            )
            }
            <button onClick={this.addPractice}>Add</button>
        </div>)
    }

    addPractice = (sender) => {
        const practiceInput = document.getElementById("addPracticeInput");
        if (practiceInput.value === "") {
            return;
        }
        sender.target.disabled = true;
        practiceInput.disabled = true;
        const data = { name: practiceInput.value };
        request(`practices/addPractice`, data).then(res => {
            if (res.data.success === true) {
                practiceInput.value = "";
                this.setState((prevState) => ( { error: null } ) );
            } else {
                this.setState((prevState) => ( { error: "Error occurred adding practice, reason unknown" } ) );
            }
            this.getPractices();
            sender.target.disabled = false;
            practiceInput.disabled = false;
        }, reason => {
            this.setState((prevState) => ( { error: `Error occurred adding practice: ${reason}` } ) );
            sender.target.disabled = false;
            practiceInput.disabled = false;
        });
        
    }

    render = () => {
        return (
            <div>
                <h1>Practices</h1>
                {this.renderAddPractices()}
                {this.renderPractices()}
            </div>
        );
    }
}

export default Practices;