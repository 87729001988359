import React from 'react';
import CohortStages from './CohortStages';
import CohortSurveys from './CohortSurveys';
import CohortTitle from './CohortTitle';
import CohortUsers from './CohortUsers';
import EditCohort from './EditCohort';
import '../../../../styles/Cohort.css';
import { request } from '../../../../utils/requests';
import CohortEditStage from './CohortEditStage';

class CohortDetails extends React.Component {
    state = {
        editting: false,
        cohort: {},
        surveys: [],
        organizations: [],
        cohortSurveys: [],
        cohortSurveyLookup: [],
        stages: [],
        types: [],
        users: [],
        cohortUsers: [],
        cohortUserLookup: {},
        showEditStageModal: false,
        editStageValue: undefined
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getCohort();
    }

    getCohortId = () => {
        const pathSplit = window.location.pathname.split('/');
        return pathSplit[pathSplit.length - 1];;  
    }

    getCohort = () => {
        const cohortId = this.getCohortId();
        request(`getCohort/${cohortId}`).then(res => {
            const cohort = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows[0] : [];
            this.getCohortStages(cohort);
        }, reason => {
    
        });
    }

    getCohortStages = (cohort) => {
        request(`getCohortStages/${cohort.id}`).then(res => {
            const stages = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            let i = 1;
            for (const stage of stages) {
                stage.step = i++;
                const start = new Date(stage.start);
                stage.startDate = `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear()}`;
                const due = new Date(stage.due);
                stage.dueDate = `${due.getMonth() + 1}/${due.getDate()}/${due.getFullYear()}`;
            }
            this.getSurveys(cohort, stages);
        }, reason => {
    
        });
    }

    getSurveys = (cohort, stages) => {
        request(`surveys/getSurveys`).then(res => {
            const surveys = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getCohortSurveys(cohort, stages, surveys);
        }, reason => {
    
        });
    }

    getCohortSurveys = (cohort, stages, surveys) => {       
        request(`getCohortSurveys/${cohort.id}`).then(res => {
            const cohortSurveys = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            const cohortSurveyLookup = {};
            for(const cohortSurvey of cohortSurveys) {
                cohortSurveyLookup[cohortSurvey.survey_id] = cohortSurvey;
            }
            this.getUsers(cohort, stages, surveys, cohortSurveys, cohortSurveyLookup);
        }, reason => {
    
        });
    }

    getUsers = (cohort, stages, surveys, cohortSurveys, cohortSurveyLookup) => {
        request(`getUsers`).then(res => {
            const users = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getCohortUsers(cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users);
        }, reason => {
    
        });
    }

    getCohortUsers = (cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users) => {
        request(`getCohortUsers/${cohort.id}`).then(res => {
            const cohortUsers = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            const cohortUserLookup = {};
            for(const cohortUser of cohortUsers) {
                cohortUserLookup[cohortUser.user_id] = cohortUser;
            }
            this.getOrganizations(cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users, cohortUsers, cohortUserLookup);
        }, reason => {
    
        });
        
    }

    getOrganizations = (cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users, cohortUsers, cohortUserLookup) => {
        request(`getOrganizations`).then(res => {
            const organizations = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getCohortStageTypes(cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users, cohortUsers, cohortUserLookup, organizations);
        }, reason => {
    
        });
    }

    getCohortStageTypes = (cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users, cohortUsers, cohortUserLookup, organizations) => {
        request(`getCohortStageTypes`).then(res => {
            const types = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.setState((prevState) => ( { cohort, stages, surveys, cohortSurveys, cohortSurveyLookup, users, cohortUsers, cohortUserLookup, organizations, types } ) );
        }, reason => {
    
        });
    }
    
    toggleEditting = (sender) => {
        this.setState((prevState) => ({ editting: !prevState.editting } ) );
    }

    render = () => {
        return (
            <div style={{ width: '100%', display: 'flex', position: 'relative', fontFamily: 'Montserrat', overflow: 'hidden', maxHeight: '90vh', height: '90vh' }}>
                <div style={{ display: 'block', padding: "30px", overflow: 'scroll', width: this.state.editting === false ? '96%' : '50%' }}>
                    <CohortTitle cohort={this.state.cohort} />
                    <CohortStages stages={this.state.stages} toggleEditStage={this.setEditStage} />
                    <CohortUsers cohortUsers={this.state.cohortUsers} cohort={this.state.cohort} users={this.state.users} organizations={this.state.organizations} cohortUserLookup={this.state.cohortUserLookup} refresh={this.initialRequest} isEditting={this.state.editting} />
                    <CohortEditStage editStageValue={this.state.editStageValue} showModal={this.state.showEditStageModal} closeModal={this.closeModal} types={this.state.types} cohortUsers={this.state.cohortUsers} cohortSurveys={this.state.cohortSurveys} cohort={this.state.cohort} />
                </div>
                <div id="bannerBlock" style={{ width: this.state.editting === false ? '4%' : '50%' }}>
                    <EditCohort editting={this.state.editting} toggleEditting={this.toggleEditting} data={{ refresh: this.initialRequest, stages: this.state.stages, surveys: this.state.surveys, cohort: this.state.cohort, cohortSurveys: this.state.cohortSurveys, cohortSurveyLookup: this.state.cohortSurveyLookup, types: this.state.types, cohortUsers: this.state.cohortUsers }} />
                </div>
            </div>
        );
    }

    setEditStage = (showEditStageModal, editStageValue) => {
        this.setState((prevState) => ( { showEditStageModal, editStageValue } ) );
    }

    closeModal = (refresh) => {
        this.setEditStage(false, undefined);
        if (refresh === true) {
            this.initialRequest();
        }
    }
}

export default CohortDetails;