import React, { useState } from 'react';
import { request } from '../../../utils/requests';
import KokoroModal from '../../Utils/KokoroModal';

const UserDetailsModal = (props) => {
    const [edit, setEdit] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [alt_email, setAltEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [alt_phone, setAltPhone] = useState('');
    const [text_permission, setTextPermission] = useState(null);
    const [prefers_text, setPrefersText] = useState(null);
    const [company_id, setCompany] = useState(-1);
    const firstNameInputValue = first_name.trim() === '' && props.value && props.value.first_name ? props.value.first_name : first_name;
    const lastNameInputValue = last_name.trim() === '' && props.value && props.value.last_name ? props.value.last_name : last_name;
    const emailInputValue = email.trim() === '' && props.value && props.value.email ? props.value.email : email;
    const altEmailInputValue = alt_email.trim() === '' && props.value && props.value.alt_email ? props.value.alt_email : alt_email;
    const phoneInputValue = phone.trim() === '' && props.value && props.value.phone ? props.value.phone : phone;
    const altPhoneInputValue = alt_phone.trim() === '' && props.value && props.value.alt_phone ? props.value.alt_phone : alt_phone;
    const textPermissionInputValue = (text_permission !== true && text_permission !== false) && props.value && props.value.text_permission ? props.value.text_permission === true : text_permission === true;
    const prefersTextInputValue = (prefers_text !== true && prefers_text !== false) && props.value && props.value.prefers_text ? props.value.prefers_text : prefers_text === true;
    const companyInputValue = company_id === -1 && props.value && props.value.company_id ? props.value.company_id : company_id;
    const canEdit = true;
    const editText = edit === true ? 'Cancel' : 'Edit';
    return (
        <div>
            {
                (props.showModal && props.value) && (
                    <KokoroModal closeModal={props.closeModal}>
                        <div>
                            <div>
                                <details>
                                    <div>
                                        <label>Id:</label>
                                        <span>{props.value.id}</span>
                                    </div>
                                    <div>
                                        <label>UUID:</label>
                                        <span>{props.value.uuid}</span>
                                    </div>
                                    <summary>
                                        <h3>{props.value.first_name} {props.value.last_name} User Details</h3>
                                        (Click to expand for more info)
                                    </summary>
                                </details>
                            </div>
                            <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Name</legend>
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>First Name:</label>
                                                <input type="text" value={firstNameInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setFirstName(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>First Name:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.first_name}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Last Name:</label>
                                                <input type="text" value={lastNameInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setLastName(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Last Name:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.last_name}</span>
                                            </div>
                                        )
                                    }
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Phone</legend>
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Phone:</label>
                                                <input type="text" value={phoneInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setPhone(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Phone:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.phone}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Alt Phone:</label>
                                                <input type="text" value={altPhoneInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setAltPhone(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Alt Phone:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.alt_phone}</span>
                                            </div>
                                        )
                                    }
                                    <div>
                                        <label>Text Permission:</label>
                                        <input type="checkbox" disabled={edit !== true} checked={textPermissionInputValue} onChange={
                                            (sender) => {
                                                setTextPermission(sender.target.checked);
                                            } }
                                        />
                                    </div>
                                    <div>
                                        <label>Prefers Text:</label>
                                        <input type="checkbox" disabled={edit !== true} checked={prefersTextInputValue} onChange={
                                            (sender) => {
                                                setPrefersText(sender.target.checked);
                                            } }
                                        />
                                    </div>
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Email</legend>
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Email:</label>
                                                <input type="text" value={emailInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setEmail(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Email:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.email}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Alt Email:</label>
                                                <input type="text" value={altEmailInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setAltEmail(sender.target.value);
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Alt Email:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.alt_email}</span>
                                            </div>
                                        )
                                    }
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Company</legend>
                                    {
                                        (edit === true && canEdit) && (
                                            <div>
                                                <label>Company:</label>
                                                <select value={companyInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        setCompany(parseInt(sender.target.value));
                                                    } }
                                                >
                                                    {getCompanyOptions(props.companies)}
                                                </select>
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Company:</label>
                                                <span style={{ textAlign: 'center', padding: '15px' }}>{props.value.company}</span>
                                            </div>
                                        )
                                    }
                                </fieldset>
                            </div>
                            { canEdit === true && (
                                <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                    { (edit === true && canEdit) && (
                                        <button onClick={
                                            (sender) => {
                                                const updates = {};
                                                if (first_name !== '' && first_name !== props.value.first_name) {
                                                    updates.first_name = first_name;
                                                }
                                                if (last_name !== '' && last_name !== props.value.last_name) {
                                                    updates.last_name = last_name;
                                                }
                                                if (email !== '' && email !== props.value.email) {
                                                    updates.email = email;
                                                }
                                                if (alt_email !== '' && alt_email !== props.value.alt_email) {
                                                    updates.alt_email = alt_email;
                                                }
                                                if (phone !== '' && phone !== props.value.phone) {
                                                    updates.phone = phone;
                                                }
                                                if (alt_phone !== '' && alt_phone !== props.value.alt_phone) {
                                                    updates.alt_phone = alt_phone;
                                                }
                                                if ((text_permission === true || text_permission === false) && text_permission !== props.value.text_permission) {
                                                    updates.text_permission = text_permission === true;
                                                }
                                                if ((prefers_text === true || prefers_text === false) && prefers_text !== props.value.prefers_text) {
                                                    updates.prefers_text = prefers_text === true;
                                                }
                                                if (company_id !== -1 && company_id !== props.value.company_id) {
                                                    updates.company_id = company_id;
                                                }
                                                const hasUpdates = Object.entries(updates).length > 0;
                                                if (hasUpdates) {
                                                    request('updateUser', { id: props.value.id, ...updates }).then(res => {
                                                        props.closeModal(true, false);
                                                        setEdit(!edit);
                                                        setFirstName('');
                                                        setLastName('');
                                                        setEmail('');
                                                        setAltEmail('');
                                                        setPhone('');
                                                        setAltPhone('');
                                                        setTextPermission(null);
                                                        setPrefersText(null);
                                                        setCompany(-1);
                                                    });
                                                }
                                            } }
                                        >
                                            Save
                                        </button>
                                    ) }
                                    <button onClick={
                                        (sender) => {
                                            setEdit(!edit);
                                            setFirstName('');
                                            setLastName('');
                                            setEmail('');
                                            setAltEmail('');
                                            setPhone('');
                                            setAltPhone('');
                                            setTextPermission(null);
                                            setPrefersText(null);
                                            setCompany(-1);
                                        } }
                                    >
                                        {editText}
                                    </button>
                                </div>
                            ) }
                        </div>
                    </KokoroModal>
                )
            }
        </div>
    );
}

const getCompanyOptions = (companies) => {
    const toRender = [];
    if (companies) {
        for (const company of companies) {
            toRender.push(<option value={`${company.id}`}>{company.name}</option>)
        }
    }
    return toRender;
}

export default UserDetailsModal;