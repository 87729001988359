import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { createBrowserHistory } from 'history';

import KokoroRouter from './components/KokoroRouter';

export const history = createBrowserHistory();
function App() {
  return (
    <div className="App">
        <BrowserRouter history={history}>
          <KokoroRouter />
        </BrowserRouter>
    </div>
  );
}

export default App;