import React from 'react';

class KokoroModal extends React.Component {
    constructor (props) {
        super(props);
    }

    state = {
        backgroundColor: this.props.styles && this.props.styles.backgroundColor ? this.props.styles.backgroundColor : 'rgba(0, 0, 0, .75)',
        marginTop: this.props.styles && this.props.styles.marginTop ? this.props.styles.marginTop : 0
    }

    render = () => {
        return (
            <div style={{ zIndex: 1000, position: 'absolute', top: 0, left: 0, width: '100%', height: '90vh', backgroundColor: this.state.backgroundColor }}>
                <div className='innerModal' style={{ position: 'relative', marginTop: this.state.marginTop, paddingTop: '3vh', paddingBottom: '3vh', paddingLeft: '3vh', paddingRight: '3vh', overflow: 'scroll' }}>
                    { this.props.hideX !== true && (<button style={{ position: 'relative', top: '20px', float: 'right', marginRight: '20px' }} onClick={() => {this.props.closeModal(false) }}>X</button>) }
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default KokoroModal;