import React, { useState } from 'react';
import KokoroTable from '../../../Utils/KokoroTable';
import KokoroModal from '../../../Utils/KokoroModal';
import CohortEditSurveys from './CohortEditSurveys';

const CohortSurveys = (props) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <details style={{ borderStyle: 'solid', padding: '30px', marginBottom: '20px' }}>
            <summary>
                <h2>
                    Edit Surveys
                </h2>
                (Click to expand/collapse)
            </summary>
            <h3>Current Surveys</h3>
            <KokoroTable fields={[ { header: 'Survey Name', value: 'survey', filterable: true, sortable: true }, { header: 'Survey Category', value: 'category', filterable: true, sortable: true } ]} data={props.data.cohortSurveys} />
            <button className="cohortAddButton" onClick={() => { setShowModal(true); }}>Add</button>
            <CohortEditSurveys showModal={showModal} setShowModal={setShowModal} data={props.data} />
        </details>
    );
}

export default CohortSurveys;