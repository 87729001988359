import React, { useState } from 'react';
import { request } from '../../../../utils/requests';
import KokoroModal from '../../../Utils/KokoroModal';
import KokoroTable from '../../../Utils/KokoroTable';

const CohortEditSurveys = (props) => {
    const [pendingSurveys, setPendingSurveys] = useState({});
    const [error, setError] = useState();
    return (
        <div>
            { props.showModal === true && (
                <KokoroModal closeModal={props.setShowModal}>
                    <div>
                        <h3>Select Surveys For Cohort:</h3>
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Available Surveys</legend>
                            <KokoroTable style={{ width: '100%' }} 
                                fields={[
                                    { header: 'Survey Name', value: 'name', filterable: true, sortable: true },
                                    { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                    { header: 'Add', value: 'add' }
                                ]}
                                data={ filterSurveys(props.data.surveys, props.data.cohortSurveyLookup, pendingSurveys, setPendingSurveys) }
                                tableName="AvailableSurveys"/>
                        </fieldset>
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Pending Surveys</legend>
                            <KokoroTable style={{ width: '100%' }} 
                                fields={[
                                    { header: 'Survey Name', value: 'name', filterable: true, sortable: true },
                                    { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                    { header: 'Remove', value: 'remove' }
                                ]}
                                data={ getPendingSurveyArray(pendingSurveys) }
                                tableName="AvailableSurveys"/>
                        </fieldset>
                        <div>
                            <button onClick={
                                (sender) => {
                                    sender.target.disabled = true;
                                    const entries = Object.entries(pendingSurveys);
                                    const toAdd = [];
                                    for (let i = 0; i < entries.length; i++) {
                                        const entry = entries[i][1];
                                        const pendingSurvey = { survey_id: entry.id, cohort_id: props.data.cohort.id };
                                        toAdd.push(pendingSurvey);
                                    }
                                    
                                    if (toAdd.length > 0) {
                                        request('addCohortSurveys', { surveys: toAdd }).then(res => {
                                            if (res.data.successful === true) {
                                                setError(undefined);
                                                setPendingSurveys({});
                                                props.setShowModal(false);
                                                props.data.refresh();
                                                sender.target.disabled = false;
                                            } else {
                                                setError("Failed to Add Users, Consult Engineering Team");
                                                sender.target.disabled = false;
                                            }
                                        });
                                    }
                                }
                            }>
                                Save
                            </button>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const filterSurveys = (surveys, cohortSurveyLookup, pendingSurveys, setPendingSurveys) => {
    const filteredSurveys = [];
    if (surveys) {
        for (let i = 0; i < surveys.length; i++) {
            const survey = surveys[i];
            survey.add = (<button onClick={() => { const newPendingSurveys = { ...pendingSurveys }; newPendingSurveys[survey.id] = survey; setPendingSurveys(newPendingSurveys); } }>Add</button>);
            survey.remove = (<button onClick={() => { const newPendingSurveys = { ...pendingSurveys }; delete newPendingSurveys[survey.id]; setPendingSurveys(newPendingSurveys); } }>Remove</button>);
            if ((!cohortSurveyLookup || !cohortSurveyLookup[survey.id]) && (!pendingSurveys || !pendingSurveys[survey.id])) {
                filteredSurveys.push(survey);
            }
        }
    }
    return filteredSurveys;
}

const getPendingSurveyArray = (pendingSurveys) => {
    const pendingSurveyArray = [];
    const entries = Object.entries(pendingSurveys);
    for (let i = 0; i < entries.length; i++) {
        const pendingSurvey = entries[i][1]; 
        pendingSurveyArray.push(pendingSurvey);
    }
    return pendingSurveyArray;
}

export default CohortEditSurveys;