import React from 'react';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import KokoroTable from '../../Utils/KokoroTable';
import PartnerAddModal from './PartnerAddModal';
import PartnerDetailsModal from './PartnerDetailsModal';

class PartnerManagement extends React.Component {
    state = {
        showAddModal: false,
        showDetailsModal: false,
        detailsModalValue: null,
        showDialog: false,
        partners: null,
        statusChangeValue: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getPartners();
    }

    getPartners = () => {
        request('getPartners').then(res => {
            const partners = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const partner of res.data.rows) {
                    if (partner.id === 1) {
                        partner.modify_status = 'N/A';
                    } else {
                        partner.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: partner } ) ) } }>{partner.is_active === true ? 'Deactivate' : 'Reactivate'}</button>);
                    }
                    partner.details = (<button onClick={() => { this.setState((prevState) => ( { showDetailsModal: true, detailsModalValue: partner } ) ) } }>Details</button>)
                    partner.status = partner.is_active === true ? 'Active' : 'Inactive';
                    partners.push(partner);
                }
            }
            this.setState((prevState) => ( { partners, showAddModal: false, showDialog: false, statusChangeValue: null, showDetailsModal: false, detailsModalValue: null } ) );
        }, reason => {
    
        });
    }

    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Partner Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ); }}>Add New Partner</button>
                <PartnerAddModal showAddModal={this.state.showAddModal} handleAddModal={this.handleAddModal} />
                <PartnerDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsModalValue} closeModal={this.handleDetailsModal} />                
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[{ header: 'Name', value: 'name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Contact Name', value: 'contact_name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Contact Email', value: 'contact_email', filterable: true, sortable: true }, { header: 'Contact Phone', value: 'contact_number', filterable: true, sortable: true }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status'} ]} data={this.state.partners} maxItems={10} tableName="Partners" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Partner Status?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    handleAddModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showAddModal: false } ) );
        }
    }
    

    handleDetailsModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsModalValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updatePartner', { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }
}

export default PartnerManagement;