import React from 'react';
import { request } from '../../../../utils/requests';
import KokoroDialog from '../../../Utils/KokoroDialog';
import KokoroTable from '../../../Utils/KokoroTable';
import QuestionCategoryAddModal from './QuestionCategoryAddModal';
import QuestionCategoryDetailsModal from './QuestionCategoryDetailsModal';

class QuestionCategories extends React.Component {
    state = {
        questionCategories: null,
        surveys: null,
        showDetailsModal: false,
        detailsValue: null,
        showAddModal: false,
        showDialog: false,
        statusChangeValue: null
    }
    
    updateStatusApi = 'updateStageType';

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getSurveys();
    }

    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Question Category Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ) } } >New Question Category</button>
                <QuestionCategoryAddModal closeModal={this.handleModal} showModal={this.state.showAddModal} surveys={this.state.surveys} />
                <QuestionCategoryDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsValue} surveys={this.state.surveys} closeModal={this.handleModal} refresh={this.initialRequest} />
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[ { header: 'Name', value: 'name', filterable: true, sortable: true }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status' } ]} data={this.state.questionCategories} maxItems={10} tableName="QuestionCategories" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Status for this Question Category?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    handleModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request(this.updateStatusApi, { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }

    getSurveys = () => {
        request(`surveys/getSurveys`).then(res => {
            const surveys = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getQuestionCategories(surveys);
        }, reason => {
    
        });
    }

    getQuestionCategories = (surveys) => {
        request(`getQuestionCategories`).then(res => {
            const questionCategories = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            for (const questionCategory of questionCategories) {
                questionCategory.status = questionCategory.is_active === true ? 'Active' : 'Inactive';
                questionCategory.details = (<button onClick={(sender) => { this.setState( (prevState) => ( { showDetailsModal: true, detailsValue: questionCategory } ) ); } }>Details</button>)
                questionCategory.modify_status = (<button disabled={true} onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: questionCategory } ) ) } }>{ questionCategory.is_active === true ? 'Deactivate' : 'Reactivate' }</button>);
            }
            this.setState((prevState) => ( { surveys, questionCategories, showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }, reason => {
    
        });
    }    
} 

export default QuestionCategories;