import React from 'react';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import KokoroTable from '../../Utils/KokoroTable';
import SurveyCategoryAddModal from './SurveyCategoryAddModal';
import SurveyCategoryDetailsModal from './SurveyCategoryDetailsModal';

class SurveyCategory extends React.Component {
    state = {
        categories: null,
        showDetailsModal: false,
        detailsValue: null,
        showAddModal: false,
        showDialog: false,
        statusChangeValue: null
    }
    
    updateStatusApi = 'surveyCategory/update';

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getCategories();
    }

    render = () => {
        return (
            <div>
                <h2>Survey Category Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ) } } >New Category</button>
                <SurveyCategoryAddModal closeModal={this.handleModal} showModal={this.state.showAddModal} />
                <SurveyCategoryDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsValue} closeModal={this.handleModal} />
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[ { header: 'Name', value: 'name', filterable: true, sortable: true }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status' } ]} data={this.state.categories} tableName="SurveyCategories" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Status for this Survey Category?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    handleModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request(this.updateStatusApi, { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }

    getCategories = () => {
        request('surveyCategory/getCategories').then(res => {
            const categories = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for(const category of res.data.rows) {
                    category.status = category.is_active === true ? 'Active' : 'Inactive';
                    category.details = (<button onClick={(sender) => { this.setState( (prevState) => ( { showDetailsModal: true, detailsValue: category } ) ); } }>Details</button>)
                    category.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: category } ) ) } }>{ category.is_active === true ? 'Deactivate' : 'Reactivate' }</button>);
                    categories.push(category);
                }
            }
            this.setState( (prevState) => ( { categories, showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }, reason => {
    
        });
    }    
} 


export default SurveyCategory;