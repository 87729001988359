import React from 'react';
import { request } from '../../../utils/requests';
import SurveyAnswers from './SurveyAnswers';


class SurveyReportDetails extends React.Component {
    state = {
        surveyReport: null,
        practices: null,
        assignedPractices: null,
        practiceLookup: [],
        surveyAnswers: [],
        sguId: 0,
        score: "Processing..."
    }   

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getPractices();
        }
    }
    render = (props) => {
        return (
            <div>
                <h2>Survey Report Details Admin</h2>
                <h3><button onClick={this.getPractices}>Refresh</button></h3>
                {this.renderSurveyReportDetails()}
                {this.renderPracticeControls()}
                <div>
                    <h2>Answers:</h2>
                    <SurveyAnswers key="answers" surveyAnswers={this.state.surveyAnswers} setScore={this.setScore} />
                </div>
            </div>
        )
    }

    setScore = (score) => {
        if (this.state.score != score) {
            this.setState((prevState) => ( { score } ) );
        }
    }

    getSguId = () => {
        const pathSplit = window.location.pathname.split('/');
        const sguId = pathSplit[pathSplit.length - 1];
        return sguId;  
    }

    getSurveyReport = (practices, practiceLookup) => {
        const sguId = this.getSguId();
        request(`surveyGroupUsers/getUser/${sguId}`).then(res => {
            let surveyReport = undefined;
            if (res && res.data && res.data.rows && res.data.rows.length === 1) {
                surveyReport = res.data.rows[0];
            }
            //this.setState((prevState) => ( { surveyReport, sguId } ) );
            const sId = surveyReport ? surveyReport.guid : -1;
            if (sId !== -1) {
                this.getAssignedPractices(surveyReport, sguId, practices, practiceLookup);
            }
        }, reason => {
    
        });
    }

    getAssignedPractices = (surveyReport, sguId, practices, practiceLookup) => {
        request(`practices/getAssignedPractice/${sguId}`).then(res => {
            let assignedPractices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                assignedPractices = res.data.rows;
            }
            for (const assignedPractice of assignedPractices) {
                practiceLookup[assignedPractice.practiceId].assigned = true;
            }
            //this.setState((prevState) => ( { assignedPractices } ) );
            this.getSurveyAnswers(surveyReport, assignedPractices, sguId, practices, practiceLookup);
        }, reason => {
    
        });
    }

    getPractices = () => {
        request(`practices/getReportPractices`).then(res => {
            let practices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                practices = res.data.rows;
            }
            //this.setState((prevState) => ( { practices } ) );
            const practiceLookup = {};
            for (const practice of practices) {
                practiceLookup[practice.id] = { name: practice.name, assigned: false };
            }
            this.getSurveyReport(practices, practiceLookup);
        }, reason => {
    
        });
    }

    getSurveyAnswers = (surveyReport, assignedPractices, sguId, practices, practiceLookup) => {
        request(`userSurveyAnswers/getAnswers/${sguId}`).then(res => {
            let surveyAnswers = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                surveyAnswers = res.data.rows;
            }
            this.setState((prevState) => ( { surveyAnswers, surveyReport, assignedPractices, sguId, practices, practiceLookup } ) );
        }, reason => {
    
        });
    }

    renderSurveyReportDetails = () => {
        const surveyReport = this.state.surveyReport;
        let toRender = (<div></div>);
        if (surveyReport) {
            toRender = (
                <div>
                    <h3>Survey Id: {surveyReport.guid}</h3>
                    <h3>First Name: {surveyReport.first_name}</h3>
                    <h3>Last Name: {surveyReport.last_name}</h3>
                    <h3>Email: {surveyReport.email}</h3>
                    <h3>Score: {this.state.score}</h3> 
                </div>
            );
        }
        return toRender;
    }

    addAssignedPractice = (sender) => {
        const select = document.getElementById("SelectedPractice");
        if (select.value <= 0) {
            return;
        }
        sender.target.disabled = true;
        const data = {
            asguId: this.state.sguId,
            practiceId: select.value
        }

        request(`practices/addAssignedPractice`, data).then(res => {
            if (res.data.success === true) {
                this.getPractices();
                this.setState((prevState) => ( { error: null } ) );
                sender.target.disabled = false;
            } else {
                this.setState((prevState) => ( { error: "Error updating practice: Reason Unknown" } ) );
                sender.target.disabled = false;
            }
        }, reason => {
            this.setState((prevState) => ( { error: `Error updating practice: Reason ${reason}` } ) );
            sender.target.disabled = false;
        });
    }

    removeAssignedPractice = (sender) => {
        request(`practices/removeAssignedPractice/${sender.target.id}`).then(res => {
            if (res.data.success === true) {
                this.getPractices();
                this.setState((prevState) => ( { error: null } ) );
                sender.target.disabled = false;
            } else {
                this.setState((prevState) => ( { error: "Error updating practice: Reason Unknown" } ) );
                sender.target.disabled = false;
            }
        }, reason => {
            this.setState((prevState) => ( { error: `Error updating practice: Reason ${reason}` } ) );
            sender.target.disabled = false;
        });
    }

    sendReport = () => {
        alert("This function will be available 1/2/2023");
    }

    renderPracticeControls = () => {
        return (
            <div>
                {this.renderPractices()}
                <button onClick={this.addAssignedPractice}>Add</button>
                {this.renderSelectedPractices()}
                <button onClick={this.sendReport}>Send</button>
            </div>
        )
    }

    renderPractices = () => {
        const toRender = [];
        const practices = this.state.practices;
        if (practices) {
            for (const practice of this.state.practices) {
                if (this.state.practiceLookup[practice.id].assigned === false) {
                    toRender.push(<option key={practice.id} value={practice.id}>{practice.name}</option>)
                }
            }
        }
        return (
            <span>
                <select id="SelectedPractice">
                    {toRender}
                </select>
            </span>

        )
    }

    renderSelectedPractices = () => {
        const toRender = [];
        const assignedPractices = this.state.assignedPractices;
        if (assignedPractices) {
            for (const assignedPractice of assignedPractices) {
                toRender.push(<tr key={assignedPractice.id}><td>{this.state.practiceLookup[assignedPractice.practiceId].name}</td><td><button id={assignedPractice.id} onClick={this.removeAssignedPractice}>X</button></td></tr>);
            }
        }
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {toRender}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default SurveyReportDetails;