import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';
import KokoroTable from '../../Utils/KokoroTable';


let once = -1;
const CohortUserPractices = (props) => {
    const [pendingPractices, setPendingPractices] = useState({});
    const [error, setError] = useState('');
    processPractices(props.practices, pendingPractices, setPendingPractices);
    return (
        <div>
            { (props.showModal) && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Add Practice Assignments</h3>
                        {
                            error !== '' && (
                                <h4 style={{ color: 'red' }}>{error}</h4>
                            )
                        }
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Practices Available</legend>
                            <KokoroTable style={{ width: '100%' }}
                                fields={[
                                    { header: 'Name', value: 'name', filterable: true },
                                    { header: 'Add', value: 'add' },
                                ]}
                                data={fitlerAvailable(props.practices, props.practiceLookup, pendingPractices)}
                                maxItems={5}
                                tableName="PracticeAvailable"
                            />
                        </fieldset>
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Practices Pending</legend>
                            <KokoroTable style={{ width: '100%' }}
                                fields={[
                                    { header: 'Name', value: 'name', filterable: true },
                                    { header: 'Remove', value: 'remove' },
                                ]}
                                data={pendingPracticesToArray(pendingPractices)}
                                maxItems={2}
                                tableName="PracticePending"
                            />
                        </fieldset>
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-evenly' }}>
                            <button onClick={
                                (sender) => {
                                    const entries = Object.entries(pendingPractices);
                                    if (entries.length > 0) {
                                        const practice_ids = [];
                                        for (const [key, value] of entries) {
                                            practice_ids.push(key);
                                        }
                                        request(`practices/addAssignedPractices`, { cohort_stage_id: props.cohort_stage_id, user_id: props.user_id, practice_ids: practice_ids}).then(res => {
                                            setPendingPractices({});
                                            setError();
                                            props.closeModal(false, true);
                                        }, reason => {
                                            setError('Add Practice Failed for Unknown Reason: Try Again');
                                        });
                                    }
                                }
                            }>
                                Save
                            </button>
                            <button onClick={
                                (sender) => {
                                    setPendingPractices({});
                                    setError();
                                    props.closeModal(false);
                                }
                            }>
                                Cancel
                            </button>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    )
}

const processPractices = (practices, pendingPractices, setPendingPractices) => {
    if (practices) {
        for (const practice of practices) {
            practice.add = (<button onClick={(sender) => { const newValue = { ...pendingPractices }; newValue[practice.id] = practice; setPendingPractices( { ...newValue } ); } }>Add</button>);
            practice.remove = (<button onClick={(sender) => { const newValue = { ...pendingPractices }; delete newValue[practice.id]; setPendingPractices( { ...newValue } ); } }>Remove</button>);
        }
    }
}

const pendingPracticesToArray = (pendingPractices) => {
    const toRender = [];
    if (pendingPractices) {
        for (const [key, value] of Object.entries(pendingPractices)) {
            toRender.push(value);
        }
    }
    return toRender;
}

const fitlerAvailable = (practices, practiceLookup, pendingPractices) => {
    const filteredPractices = [];
    for (const practice of practices) {
        if ((!pendingPractices || (pendingPractices && !pendingPractices[practice.id])) && (!practiceLookup || (practiceLookup && practiceLookup[practice.id].assigned === false))) {
            filteredPractices.push(practice);
        }
    }
    return filteredPractices;
}

export default CohortUserPractices;