import React, { useEffect }  from 'react';
import {VictoryChart, VictoryBar, VictoryAxis} from 'victory';
import saveSvgAsPng from 'save-svg-as-png';
import { request } from '../../../utils/requests';

const CohortReportGraph = (props) => {
    useEffect(() => {
        if (allGraphData.length === 0 || props.selectedStage === 0 || !props.user) {
            return;
        }
        const currentGraphData = props.allGraphData[0];
        const element = document.getElementsByClassName('VictoryContainer');
        if (element) {
            saveSvgAsPng.svgAsPngUri(element[0].getElementsByTagName('svg')[0], { encoderOptions: 1 }).then(uri => {
                request(`reportGraphs/addReportGraph`, {cohort_stage_id: props.selectedStage, user_id: props.user.user_id, img_text: uri, name: currentGraphData.graphLabel }).then((res => {
                    props.dequeueGraphData();
                }))
                
            });
        }
        
    });

    const allGraphData = props.allGraphData;
    if (allGraphData.length === 0 || props.selectedStage === 0 || !props.user) {
        return (<></>);
    }
    const currentGraphData = allGraphData[0];
    const graphType = currentGraphData.graphType;
    const graphData = currentGraphData.graphData;
    const maxRange = currentGraphData.maxRange;
    return (
        <>
            {
                (graphType === 'selfCompassion' && (
                    <VictoryChart id="svgToSave"
                        domain={{ y: [0, maxRange] }}
                        width={300}
                    >
                        <VictoryAxis />
                        <VictoryBar
                            style={{ data: { fill: "#734D8E" } }}
                            data={graphData}
                        />
                    </VictoryChart>
                )) || (
                    <VictoryChart
                        domain={{ x:[0, 4], y: [0, maxRange] }}
                        width={600}
                    >
                        <VictoryAxis dependentAxis tickCount={8} />
                        <VictoryAxis />
                        <VictoryBar
                            style={{ data: { fill: "#734D8E" } }}
                            data={graphData}
                        />
                    </VictoryChart>
                )
            }
        </>
    );
}

export default CohortReportGraph;