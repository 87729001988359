import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';
import KokoroTable from '../../Utils/KokoroTable';

let once;
const CompanyDetailsModal = (props) => {
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [contact_name, setContactName] = useState('');
    const [contact_email, setContactEmail] = useState('');
    const [contact_number, setContactNumber] = useState('');
    const [employees, setEmployees] = useState([]);
    const nameInputValue = name.trim() === '' && props.value && props.value.name ? props.value.name : name;
    const descriptionInputValue = description.trim() === '' && props.value && props.value.description ? props.value.description : description;
    const contactNameInputValue = contact_name.trim() === '' && props.value && props.value.contact_name ? props.value.contact_name : contact_name;
    const contactEmailInputValue = contact_email.trim() === '' && props.value && props.value.contact_email ? props.value.contact_email : contact_email;
    const contactNumberInputValue = contact_number.trim() === '' && props.value && props.value.contact_number ? props.value.contact_number : contact_number;
    if (props.value && once != props.value.id) {
        once = props.value.id;
        getEmployees(props.value.id, setEmployees);
    }
    const editText = edit === true ? 'Cancel' : 'Edit';
    const canEdit = props.value && props.value.id && props.value.id !== 1 ? true : false;
    return (
        <div>
            { ( props.showModal && props.value)  && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <details>
                            <div>Id: {props.value.id}</div>
                            <div>Uuid: {props.value.uuid}</div>
                            <summary>
                                <h3>{props.value.name} Company Details</h3>
                                (Click here for additional info)
                            </summary>
                        </details>
                        <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Company Name</legend>
                                {
                                    (edit === true && canEdit) && (
                                        <input type="text" value={nameInputValue} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setName(sender.target.value);
                                            } }
                                        />
                                    ) || (
                                        <div style={{ textAlign: 'center', padding: '15px' }}>{props.value.name}</div>
                                    )
                                }
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Description</legend>
                                {
                                    (edit === true && canEdit) && (
                                        <textarea value={descriptionInputValue  ? descriptionInputValue : ''} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setDescription(sender.target.value);
                                            } } 
                                        />
                                    ) || (
                                        <div style={{ textAlign: 'center', padding: '15px' }}>{props.value.description ? props.value.description : 'N/A'}</div>
                                    )
                                }
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Contact Info</legend>
                                { (edit === true && canEdit) && (
                                    <div>
                                        <div>
                                            <label>Contact Name:</label>
                                            <input type="text" value={contactNameInputValue} onChange={
                                                (sender) => {
                                                    sender.preventDefault();
                                                    setContactName(sender.target.value);
                                                } } 
                                            />
                                        </div>
                                        <div>
                                            <label>Contact Email:</label>
                                            <input type="text" value={contactEmailInputValue} onChange={
                                                (sender) => {
                                                    sender.preventDefault();
                                                    setContactEmail(sender.target.value);
                                                } } 
                                            />
                                        </div>
                                        <div>
                                            <label>Contact Number:</label>
                                            <input type="text" value={contactNumberInputValue} onChange={
                                                (sender) => {
                                                    sender.preventDefault();
                                                    setContactNumber(sender.target.value);
                                                } } 
                                            />
                                        </div>
                                    </div>
                                ) || (
                                    <div>
                                        <div>
                                            <label>Contact Name:</label>
                                            <span>{props.value.contact_name}</span>
                                        </div>
                                        <div>
                                            <label>Contact Email:</label>
                                            <span>{props.value.contact_email}</span>
                                        </div>
                                        <div>
                                            <label>Contact Number:</label>
                                            <span>{props.value.contact_number}</span>
                                        </div>
                                    </div>
                                ) }
                            </fieldset>
                            {
                                (edit !== true || canEdit === false) && (
                                    <fieldset style={{ width: '100%', maxWidth: '1000px', textAlign: 'center', padding: '.5vw' }}>
                                        <legend style={{ textAlign: 'left' }}>Employees</legend>
                                        <KokoroTable style={{ width: '100%', maxWidth: '1000px' }} fields={[{ header: 'Id', value: 'id'}, { header: 'First Name', value: 'first_name', filterable: true, sortable: true }, { header: 'Last Name', value: 'last_name', filterable: true, sortable: true}, { header: 'Email', value: 'email', filterable: true, sortable: true } ]} data={employees} />
                                    </fieldset>
                                )
                            }
                        </div>
                        { canEdit === true && (
                            <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                { (edit === true && canEdit) && (
                                    <button onClick={
                                        (sender) => {
                                            const updates = {};
                                            if (name !== '' && name !== props.value.name) {
                                                updates.name = name;
                                            }
                                            if (description !== '' && description !== props.value.description) {
                                                updates.description = description;
                                            }
                                            if (contact_name !== '' && contact_name !== props.value.contact_name) {
                                                updates.contact_name = contact_name;
                                            }
                                            if (contact_email !== '' && contact_email !== props.value.contact_email) {
                                                updates.contact_email = contact_email;
                                            }
                                            if (contact_number !== '' && contact_number !== props.value.contact_number) {
                                                updates.contact_number = contact_number;
                                            }

                                            const hasUpdates = Object.entries(updates).length > 0;
                                            if (hasUpdates) {
                                                request('updateCompany', { id: props.value.id, ...updates }).then(res => {
                                                    props.closeModal(true, false);
                                                    setEdit(false);
                                                });
                                            }
                                        } }
                                    >
                                        Save
                                    </button>
                                ) }
                                <button onClick={
                                    (sender) => {
                                        setEdit(!edit);
                                        setName('');
                                        setDescription('');
                                        setContactName('');
                                        setDescription('');
                                        setContactNumber('');
                                    } }
                                >
                                    {editText}
                                </button>
                            </div>
                        ) }
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const getEmployees = (id, setEmployees) => {
    request(`getEmployees/${once}`).then(res => {
        const employees = [];
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
            for(const row of res.data.rows) {
                if (Array.isArray(row)) {
                    for (const subRow of row) {
                        employees.push(subRow);
                    }
                } else {
                    employees.push(row);
                }
            }
            setEmployees(employees);
        }
    }, reason => {

    });
}

export default CompanyDetailsModal;