import React from 'react';
import { request } from '../../../utils/requests';

class OrgDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        org: null,
        members: null,
        users: null,
        message: null,
        orgId: null,
        search: ""
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getOrg();
        }
    }

    getOrgId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const orgId = pathSplit[pathSplit.length - 1];
        return orgId;
    }

    render = (props) => {
        return (
            <div>
                { this.state.message && (
                    <div>
                        <h1>PLEASE REFRESH PAGE IN 20 SECONDS</h1>
                    </div>
                    )
                }
                {
                    (this.state.org && !this.state.org.error) && (
                    <div>
                        <div>
                            <h2>Org Admin</h2>
                            <h3><button onClick={this.getOrg}>Refresh</button></h3>
                            <h3>{this.state.org.name} - {this.getOrgId()}</h3>
                            <h3>Is Active: <input type="checkbox" id="here" checked={this.state.org.is_active} onChange={this.setIsActive} /></h3>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div>
                                <button onClick={this.removeMembers}>Remove Users</button>
                                <div>
                                    Members:
                                    {this.renderMembers()}
                                </div>
                            </div>
                            <div>
                                <button onClick={this.addMembers}>Add Users</button>
                                <input type="textbox" value={this.state.searchText} onChange={this.updateSearch} />
                                <div>
                                    Users:
                                    {this.renderUsers(this.state.search)}
                                </div>
                            </div>
                        </div>
                    </div>
                ) || (
                    <p>An error occurred {(this.state.org ? this.state.org.error : 'Unknown error')}</p>
                )}
            </div>
        )
    }

    updateSearch = (sender) => {
        sender.preventDefault();
        this.setState( (prevState) => ( { search: sender.target.value } ) );
    }

    getOrg = () => {
        const orgId = this.getOrgId();
        request(`getOrganization/${orgId}`).then(res => {
           this.setState((prevState) => ({ org: res && res.data && res.data.rows.length === 1 ? res.data.rows[0] : [], orgId } ));
           this.getMembers(orgId);
        }, reason => {
            this.setState((prevState) => ({ org: { error: "Could not find Org with this ID" }, orgId } ));
        });
    }

    getMembers = (orgId) => {
        request(`orgmap/getUsers/${orgId}`).then(res => {
            this.setState((prevState) => ({ members: res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [] } ));
            this.getUsers(); 
        }, reason => {
    
        });
    }

    getUsers = () => {
        request('getUsers').then(res => {
            this.setState((prevState) => ({ users: res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [] } ));
        }, reason => {
        });
    }

    renderMembers = () => {
        const members = this.state.members;
        const toRender = [];
        if (members) {
            for(let i = 0;i < members.length; i++) {
                const member = members[i];
                
                if (!member.is_active) {
                    continue;
                }
                const key = `member-${member.id}`;
                const label = `label-user-${member.id}`;
                const br = `br-user-${member.id}`;
                toRender.push(<input className="members-cb" type="checkbox" key={key} id={key} value={member.id} />);
                toRender.push(<label key={label} htmlFor={key}>{`${member.user_id} - ${member.first_name} - ${member.last_name} - ${member.email}`}</label>);
                toRender.push(<br key={br}></br>);
            }
        }
        return (<form>{toRender}</form>);
    }
    
    renderUsers = (search) => {
        const members = this.state.members;
        const users = this.state.users;
        const skipIds = [];
        search = search.toLowerCase();
        if (members) {
            for (let i = 0; i < members.length; i++) {
                if (members[i].is_active === true) {
                    skipIds.push(members[i].user_id);
                }
            }
        }
        
        const toRender = [];
        if (users) {
            for(let i = 0;i < users.length; i++) {
                const user = users[i];
                if (!user.is_active) {
                    continue;
                }
                if (skipIds.indexOf(user.id) > -1) {
                    continue;
                }
                if (search !== "" && user.first_name.toLowerCase().indexOf(search) === -1) {
                    continue;
                }
                const key = `user-${user.id}`;
                const label = `label-user-${user.id}`;
                const br = `br-user-${user.id}`;
                toRender.push(<input type="checkbox" className='users-cb' key={key} id={key} value={user.id} />);
                toRender.push(<label key={label} htmlFor={key}>{user.first_name} {user.last_name} - {user.email}</label>);
                toRender.push(<br key={br}></br>);
            }
        }
        return (<div><form>{toRender}</form></div>);
    }

    setIsActive = (sender) => {
        sender.preventDefault();
        sender.target.disabled = true;
        request(`updateOrganization`, { id: this.state.orgId, is_active: !this.state.org.is_active}).then(res => {
            this.getOrg();
            sender.target.disabled = false;
        });
    }
    
    addMembers = async (sender) => {
        sender.target.disabled = true;
        const userCheckBoxes = document.getElementsByClassName("users-cb");
        for(let i = 0; i < userCheckBoxes.length; i++) {
            const cb = userCheckBoxes[i];
            if (cb.checked) {
                const data = {
                    org_id: this.state.orgId,
                    user_id: cb.value
                }
                await request('orgmap/addAssociation', data);
            }
        }
        this.getMembers(this.state.orgId);
        sender.target.disabled = false;
    }
    
    removeMembers = async (sender) => {
        sender.target.disabled = true;
        const membersCheckBoxes = document.getElementsByClassName("members-cb");
        for(let i = 0; i < membersCheckBoxes.length; i++) {
            const cb = membersCheckBoxes[i];
            if (cb.checked) {
                const data = {
                    id: cb.value,
                    is_active: false
                }
                await request('orgmap/updateAssociation', data);
            }
        }
        this.getMembers(this.state.orgId);
        sender.target.disabled = false;
    }
}

export default OrgDetails;