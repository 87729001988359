import React, { useState } from 'react';
import { request } from '../../../utils/requests';
import { Link, Navigate } from 'react-router-dom';
import KokoroTable from '../../Utils/KokoroTable';
import KokoroDialog from '../../Utils/KokoroDialog';
import OrganizationDetailsModal from './OrganizationDetailsModal';
import OrganizationAddModal from './OrganizationAddModal';

class Organizations extends React.Component {
    state = {
        organizations: [],
        partners: [],
        showDetailsModal: false,
        detailsValue: null,
        showAddModal: false,
        showDialog: false,
        statusChangeValue: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getPartners();
    }

    getPartners = () => {
        request('getPartners').then(res => {
            const partners = (res && res.data && res.data.rows && res.data.rows.length > 0) ? res.data.rows : [];
            this.getOrganizations(partners);
        }, reason => {
    
        });
    }

    getOrganizations = (partners) => {
        request('getOrganizations').then(res => {
            const organizations = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const organization of res.data.rows) {
                    organization.status = organization.is_active === true ? 'Active' : 'Inactive';
                    organization.details = (<button onClick={(sender) => { this.setState((prevState) => ( { showDetailsModal: true, detailsValue: organization } ) ) } }>Details</button>);
                    organization.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: organization } ) ) } }>{ organization.is_active === true ? 'Deactivate' : 'Reactivate' }</button>);
                    organizations.push(organization);
                }
            }
            this.setState( (prevState) => ( { organizations, partners, showDetailsModal: false, detailsValue: null, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }, reason => {
    
        });
    }

    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Organization Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ) } } >Add New Organization</button>
                <OrganizationDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsValue} closeModal={this.handleModal} partners={this.state.partners} />
                <OrganizationAddModal showModal={this.state.showAddModal} closeModal={this.handleModal} companies={this.state.companies} partners={this.state.partners} />
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[ { header: 'Name', value: 'name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Partner', value: 'partner', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status' } ]} data={this.state.organizations} maxItems={10} tableName="Organizations" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Status for this user?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    handleModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false, showDialog: false, statusChangeValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updateOrganization', { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }
}

export default Organizations;