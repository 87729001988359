import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';

const CohortAddModal = (props) => {
    const [error, setError] = useState();
    const [data, setData] = useState({ organization_id: 12 });
    const systemName = 'Cohort';
    const addApi = 'addCohort';
    return (
        <div>
            { props.showModal && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Add {systemName}</h3>
                        {
                            error && (
                                <h4 style={{ color: 'red' }}>Error: {error}</h4>
                            )
                        }
                        <div>
                            <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>{systemName} Name</legend>
                                    <input type="text" value={data.name} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setData({...data, name: sender.target.value});
                                        } }
                                    />
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>{systemName} Organization</legend>
                                    <select type="text" value={data.organization_id} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setData({...data, organization_id: parseInt(sender.target.value) });
                                        } }
                                    >
                                        { buildOrganizationOptions(props.organizations) }
                                    </select>
                                </fieldset>
                            </div>
                            <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', padding: '1vw', margin: 'auto', marginTop: '2vh' }}>
                                <button onClick={
                                    (sender) => {
                                        sender.target.disabled = true;
                                        // Validate fields
                                        if (!data.name || data.name.trim() === '') {
                                            setError(`User Must Set a ${systemName} Name`);
                                            sender.target.disabled = false;
                                            return;
                                        }

                                        if (!data.organization_id || data.organization_id < 1) {
                                            setError(`User Must Set a ${systemName} Organization`);
                                            sender.target.disabled = false;
                                            return;
                                        }
                                        
                                        // Request Add
                                        request(addApi, data).then(res => {
                                            if (res.data.successful === true) {
                                                setData({});
                                                setError();
                                                sender.target.disabled = false;
                                                props.closeModal(true);
                                            } else {
                                                setError(`Failed to Add ${systemName}, Possible Duplicate`);
                                                sender.target.disabled = false;
                                            }
                                        });
                                    } }
                                >
                                    Save
                                </button>
                                <button onClick={(sender) => { setData({}); setError(); props.closeModal(); } }>Cancel</button>
                            </div>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const buildOrganizationOptions = (organizations) => {
    const toRender = [];
    if (organizations) {
        for (let i = 0; i < organizations.length; i++) {
            const organization = organizations[i];
            toRender.push(<option key={`Cohort-AddModal-Partner-${organization.id}`} value={organization.id}>{organization.name}</option>);
        }
    }
    return toRender;
}

export default CohortAddModal;