import React from 'react';
import CohortEditStages from './CohortEditStages';
import CohortSurveys from './CohortSurveys';

class EditCohort extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        bannerTop: '0',
        bannerClosedLeft: '0',
        bannerOpenLeft: '0'
    }
    
    componentDidMount = () => {
        this.UpdateBanner();
    }

    componentDidUpdate = () => {
        this.UpdateBanner();
    }

    UpdateBanner = () => {
        var bannerBlock = document.getElementById("bannerBlock");
        var banner = document.getElementsByClassName('stageBanner');
        if (banner && banner.length > 0 && bannerBlock) {
            const height = `-${banner[0].clientHeight}px`;
            const openWidth = `-${banner[0].clientWidth}px`;
            const width = `-${banner[0].clientWidth - bannerBlock.clientWidth}px`;
            if (height !== this.state.bannerTop || width !== this.state.bannerClosedLeft || openWidth !== this.state.bannerOpenLeft) {
                this.setState((prevState) => ( { bannerTop: height, bannerClosedLeft: width, bannerOpenLeft: openWidth } ) );
            }
        }            
    }

    RenderDisplay = () => {
        return this.props.editting === true ? this.RenderDisplayOpen() : this.RenderDisplayClosed();
    }

    RenderDisplayOpen = (states) => {
        return (
            <div style={{ width: '100%', height: '90vh', overflow: 'scroll' }}>
                <div>
                    <div className='stageBanner' style={{ position: 'relative', width: '90vh', backgroundColor: '#ff793a', top: this.state.bannerTop, WebkitTransform: "rotate(-90deg)", transformOrigin: "bottom right", left: this.state.bannerOpenLeft }}>
                        <div><button onClick={this.props.toggleEditting}>Close</button></div>
                    </div>
                </div>
                <div style={{ padding: '20px', overflow: 'scroll' }}>
                    <h1>Edit Cohort</h1>
                    <CohortSurveys data={this.props.data} />
                    <CohortEditStages data={this.props.data} />
                </div>
            </div>
        );
    }
    
    RenderDisplayClosed = (states) => {
        return (
                <div className='stageBanner' style={{ position: 'relative', top: this.state.bannerTop, width: '90vh', WebkitTransform: "rotate(-90deg)", transformOrigin: "bottom right", left: this.state.bannerClosedLeft }}>
                    <div><button onClick={this.props.toggleEditting}>+</button></div>
                    <div style={{ backgroundColor: 'orange' } }>Edit Cohort</div>
                </div>
        );
    }

    render = () => {
        return (
            <div>
                {this.RenderDisplay()}
            </div>
        );
    }
} 





export default EditCohort;

/*
-moz-transform: rotate(90deg);
-o-transform: rotate(90deg);
-ms-transform: rotate(90deg);
transform: rotate(90deg);
*/