import React, { useState } from 'react';
import {
    Routes,
    Route
  } from "react-router-dom";
import Header from './Header';
import Organizations from './Admin/Organizations/Organizations';
import OrgDetails from './Admin/Organizations/OrgDetails';
import SurveyCategory from './Admin/SurveyCategory/SurveyCategory';
import Survey from './Admin/Surveys/Survey';
import SurveyDetails from './Admin/Surveys/SurveyDetails';
import QuestionDetails from './Admin/SurveyQuestions/QuestionDetails';
import SurveyMembership from './Admin/SurveyMembership/SurveyMembership';
import SurveyMembershipDetails from './Admin/SurveyMembership/SurveyMembershipDetails';
import SurveyGroup from './Admin/SurveyGroups/SurveyGroup';
import SurveyReportDetails from './Admin/SurveyReport/SurveyReportDetails';
import SurveyReports from './Admin/SurveyReport/SurveyReports';
import Landing from './Landing';
import SurveyPreview from './SurveyPreview';
import AuthorizationRequired from './AuthorizationRequired';
import Practices from './Admin/Practices/Practices';
import PracticeDetails from './Admin/Practices/PracticeDetails';
import Test from './Graphs/Test';
import CohortDetails from './Admin/Cohort/Details/CohortDetails';
import Cohorts from './Admin/Cohort/Cohorts';
import UserManagement from './Admin/Users/UserManagement';
import CohortReporting from './Admin/Reporting/CohortReporting';
import CohortUserReporting from './Admin/Reporting/CohortUserReporting';
import CompanyManagement from './Admin/Company/CompanyManagement';
import PartnerManagement from './Admin/Partner/PartnerManagement';
import StageTypes from './Admin/Cohort/StageTypes/StageTypes';
import QuestionCategories from './Admin/SurveyQuestions/QuestionCategory/QuestionCategories';

const KokoroRouter = () => {
  const [authorized, setIsAuthorized] = useState(false);
  const [level, setLevel] = useState(-1);
  return (
    <div>
      <Header setIsAuthorized={setIsAuthorized} setLevel={setLevel} />
      { authorized &&
        (
          <div className='sd-root-modern'>
            <Routes>
              <Route path="/" element={<Landing level={level} />} exact={true} />
              <Route path="/admin" element={<Landing level={level} />} exact={true} />
              <Route path="/admin/partners" element={<PartnerManagement />} exact={true} />
              <Route path="/admin/companies" element={<CompanyManagement />} exact={true} />
              <Route path="/admin/organizations" element={<Organizations />} exact={true} />
              <Route path="/admin/organizations/:orgId" element={<OrgDetails />} exact={true} />
              <Route path="/admin/users" element={<UserManagement />} exact={true} />
              <Route path="/admin/surveyCategory" element={<SurveyCategory />} exact={true} />
              <Route path="/admin/survey" element={<Survey />} exact={true} />
              <Route path="/admin/survey/:surId" element={<SurveyDetails />} exact={true} />
              <Route path="/admin/questions/:qId" element={<QuestionDetails />} exact={true} />
              <Route path="/admin/surveyMembership" element={<SurveyMembership />} exact={true} />
              <Route path="/admin/surveyMembership/:smId" element={<SurveyMembershipDetails />} exact={true} />
              <Route path="/admin/surveyGroup/:sgId" element={<SurveyGroup />} exact={true} />
              <Route path="/admin/surveyReport/:sId" element={<SurveyReportDetails />} exact={true} />
              <Route path="/admin/surveyPreview/:sId" element={<SurveyPreview />} exact={true} />
              <Route path="/admin/practices" element={<Practices />} exact={true} />
              <Route path="/admin/practices/:pId" element={<PracticeDetails />} exact={true} />
              <Route path="/admin/reports" element={<SurveyReports />} exact={true} />
              <Route path="/admin/graphs/test" element={<Test />} exact={true} />
              <Route path="/admin/cohort" element={<Cohorts />} exact={true} />
              <Route path="/admin/cohort/details/:cId" element={<CohortDetails />} exact={true} />
              <Route path="/admin/cohort/report/:cId" element={<CohortReporting />} exact={true} />
              <Route path="/admin/cohort/report/:cId/userReport/:uCId" element={<CohortUserReporting />} exact={true} />
              <Route path="/admin/stageTypes" element={<StageTypes />} exact={true} />
              <Route path="/admin/questionCategories" element={<QuestionCategories />} exact={true} />
              <Route path="/admin/unauthorized" element={<AuthorizationRequired />} exact={true} />
            </Routes>
          </div>
        ) || (
          <AuthorizationRequired />
        )
      }
    </div>
  )
}

export default KokoroRouter;