import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import '../styles/DarkTheme.css';
import menuImage from '../images/menu.png';
import Menu from './Menu';
import { isAuthorized } from '../utils/requests';
import { isLoggedIn } from './Firebase';
//import { darkImage } from '../images/dark-mode.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        menu: false,
        isMobile: false,
        isAuthorized: false,
        level: -1,
        darkMode: JSON.parse( localStorage.getItem('darkMode') ),
        opsMenu: false,
        surveysMenu: false,
        cohortMenu: false,
        systemMenu: false
    }

    componentDidMount = () => {
        this.determineIfMobile();
        window.addEventListener('resize', event => {
            this.determineIfMobile();
        });
        if (isLoggedIn()) {
            this.authorizedCheck();
        } else {
            setTimeout(this.authorizedCheck, 1000);
        }
    }

    authorizedCheck = () => {
        isAuthorized(this.setIsAuthorized);
    }

    toggleDarkMode = () => {
        this.setDarkMode(!this.state.darkMode);
        localStorage.setItem('darkMode', JSON.stringify( !this.state.darkMode ) );
        this.setState((state, props) => ( ( { darkMode: !state.darkMode } ) ) );
    }

    setDarkMode = () => {
        if (this.state.darkMode === true) {
            document.getElementById("root").classList.add("DarkTheme");
        } else {
            document.getElementById("root").classList.remove("DarkTheme");
        }
    }

    setIsAuthorized = (isAuthorized, level) => {
        this.setState((prevState) => ( { isAuthorized, level } ) );
        this.props.setIsAuthorized(isAuthorized);
        this.props.setLevel(level);
    }

    checkIfMobile = () => {
        const root = document.getElementsByClassName("sd-root-modern");
        if (root.length > 0) {
            if (this.state.isMobile) {
                if (!root[0].classList.contains("sd-root-modern--mobile")) {
                    root[0].classList.add("sd-root-modern--mobile");
                }
            } else {
                if (root[0].classList.contains("sd-root-modern--mobile")) {
                    root[0].classList.remove("sd-root-modern--mobile");
                }
            }
        }
    }
    
    determineIfMobile = () => {
        const vWidth = window.visualViewport.width;
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
            ) || vWidth < 1200) {
            this.setState((state, props) => ( { isMobile: true } ));
        } else {
            this.setState((state, props) => ( { isMobile: false } ));
        }
        this.checkIfMobile();
    }

    toggleMenu = () => {
        this.setState( (state, props) => ( { menu: !state.menu } ));
    }

    render = () => {
        this.checkIfMobile();
        this.setDarkMode();
        return (
            <div className="kheader">
                <div style={{ position: 'relative', maxWidth: '1366px', width: '100%', margin: 'auto' }}>
                    <div className="kcontainer">
                        <span>KOKORO</span>                    
                    </div>
                    <div style={{ zIndex: '1000', top: '1.15vh', right: '15px', position: 'absolute', cursor: 'pointer' }}>
                        <img src={menuImage} alt="menu-button" style={{ maxWidth: '30px' }} onClick={ () => { this.toggleMenu(); } } />
                    </div>
                    <div style={{ zIndex: '1000', right: '0px', position: 'absolute' }}>
                        {
                            this.state.menu && (
                                <Menu darkMode={this.state.darkMode} toggleDarkMode={this.toggleDarkMode} setIsAuthorized={this.setIsAuthorized} />
                            )
                        }
                    </div>
                </div>
                { (this.state.isAuthorized === true && this.state.level === 3 && 
                    (<div className="knavbar">
                        <div onMouseEnter={(sender) => { this.setState((prevState) => ({ opsMenu: !prevState.opsMenu }))}} onMouseLeave={(sender) => { this.setState((prevState) => ({ opsMenu: !prevState.opsMenu }))}}>
                            <div className="show">Ops Management</div>
                            { this.state.opsMenu === true && (
                                <ul className="headerLinks">
                                <li><Link to={'../admin/partners'}>Partners</Link></li>
                                <li><Link to={'../admin/companies'}>Companies</Link></li>
                                <li><Link to={'../admin/organizations'}>Organizations</Link></li>
                                <li><Link to={'../admin/users'}>Users</Link></li>
                            </ul>
                            ) }
                        </div>
                        <div onMouseEnter={(sender) => { this.setState((prevState) => ({ surveysMenu: !prevState.surveysMenu }))}} onMouseLeave={(sender) => { this.setState((prevState) => ({ surveysMenu: !prevState.surveysMenu }))}}>
                            <div className="show">Surveys</div>
                            { this.state.surveysMenu === true && (
                                <ul className="headerLinks">
                                <li><Link to={'../admin/surveyCategory'}>Survey Category</Link></li>
                                <li><Link to={'../admin/survey'}>Surveys</Link></li>
                                <li><Link to={'../admin/questionCategories'}>Question Categories</Link></li>
                            </ul>
                            ) }
                        </div>
                        <div onMouseEnter={(sender) => { this.setState((prevState) => ({ cohortMenu: !prevState.cohortMenu }))}} onMouseLeave={(sender) => { this.setState((prevState) => ({ cohortMenu: !prevState.cohortMenu }))}}>
                            <div className="show">Cohort</div>
                            { this.state.cohortMenu === true && (
                                <ul className="headerLinks">
                                <li><Link to={'../admin/cohort'}>Cohort Management</Link></li>
                                <li><Link to={'../admin/stageTypes'}>Stage Types</Link></li>
                            </ul>
                            ) }
                        </div>
                        <div onMouseEnter={(sender) => { this.setState((prevState) => ({ systemMenu: !prevState.systemMenu }))}} onMouseLeave={(sender) => { this.setState((prevState) => ({ systemMenu: !prevState.systemMenu }))}}>
                            <div className="show">System Styling</div>
                            { this.state.systemMenu === true && (
                                <ul className="headerLinks">
                                <li><Link to={'../admin/practices'}>Practices</Link></li>
                            </ul>
                            ) }
                        </div>
                    </div>)) || (this.state.isAuthorized === true && this.state.level === 2 && 
                    (<div className="knavbar">
                        <div onMouseEnter={(sender) => { this.setState((prevState) => ({ opsMenu: !prevState.opsMenu }))}} onMouseLeave={(sender) => { this.setState((prevState) => ({ opsMenu: !prevState.opsMenu }))}}>
                            <div className="show">Ops Management</div>
                            { this.state.opsMenu === true && (
                                <ul className="headerLinks">
                                <li><Link to={'../admin/partners'}>Partners</Link></li>
                                <li><Link to={'../admin/companies'}>Companies</Link></li>
                                <li><Link to={'../admin/organizations'}>Organizations</Link></li>
                                <li><Link to={'../admin/users'}>Users</Link></li>
                            </ul>
                            ) }
                        </div>
                    </div>)) || (this.state.isAuthorized === true && this.state.level === 1 && 
                    (<div className="knavbar">
                        <Link to={'../admin/practices'}>Practices</Link>
                    </div>))
                }
            </div>
        );
    }
}



export default Header;