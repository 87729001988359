import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { request } from '../../../../utils/requests';
import KokoroModal from '../../../Utils/KokoroModal';
import KokoroTable from '../../../Utils/KokoroTable';

const CohortEditStage = (props) => {
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const [statuses, setStatuses] = useState();

    useEffect(() => {
        if (props.editStageValue) {
            request(`cohortUserStages/getStatuses/${props.editStageValue.id}`).then(res => {
                for(var row of res.data.rows) {
                    row.emailed = row.email_sent === true ? 'Sent' : 'Not Sent';
                    row.completed = row.is_complete === true ? 'Finished' : 'Not Finished';
                    row.issues = '';
                    if (row.survey === 'KOKORO Consent Form' && row.is_complete === true) {
                        if (row.first_name.toLowerCase() !== row.vfirst_name.toLowerCase()) {
                            row.issues = `${row.issues} First Name: ${row.first_name} != ${row.vfirst_name}`;
                        }
                        if (row.last_name.toLowerCase() !== row.vlast_name.toLowerCase()) {
                            row.issues = `${row.issues} Last Name: ${row.last_name} != ${row.vlast_name}`;
                        }
                        if (row.email.toLowerCase() !== row.vemail.toLowerCase()) {
                            row.issues = `${row.issues} ${row.email} != ${row.vemail}`;
                        }
                    }
                }
                setStatuses(res.data.rows);
            }, reason => {

            })
        } else {
            setStatuses();
        }
    }, [props.editStageValue]);

    const editText = edit === true ? 'Cancel' : 'Edit';
    const canEdit = true;

    const type = !data.type && props.editStageValue ? props.editStageValue.type_id : data.type;
    const description = !data.description && props.editStageValue ? props.editStageValue.description : data.description;
    const due = !data.due && props.editStageValue ? new Date(props.editStageValue.due) : (data.due ? data.due : '');
    const start = !data.start && props.editStageValue ? new Date(props.editStageValue.start) : (data.start ? data.start : '');
    const nudges = !data.nudges && props.editStageValue ? props.editStageValue.nudges : (data.nudges ? data.nudges : []);
    const selectedUsers = !data.selectedUsers && props.editStageValue && props.editStageValue.user_ids ? props.editStageValue.user_ids : (data.selectedUsers ? data.selectedUsers: []);
    const selectedSurveys = !data.selectedSurveys && props.editStageValue && props.editStageValue.survey_ids ? props.editStageValue.survey_ids : (data.selectedSurveys ? data.selectedSurveys: {});
    return (
        <div>
            { (props.editStageValue && props.showModal === true) && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Edit Stage {props.editStageValue.step} - {props.editStageValue.type}</h3>
                        <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                            { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Type</legend>
                                    <select value={type} onChange={(sender) => { sender.preventDefault(); setData( { ...data, type: parseInt(sender.target.value) })}}>
                                        {buildTypeOptions(props.types)}
                                    </select>
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Type</legend>
                                    <div>{props.editStageValue.type}</div>
                                </fieldset>
                            ) }
                            { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Description</legend>
                                    <textarea value={description} onChange={(sender) => { sender.preventDefault(); setData( { ...data, description: sender.target.value } ) } } />
                                </fieldset>
                             ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Description</legend>
                                    <div>{props.editStageValue.description}</div>
                                </fieldset>
                             ) }
                             { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Initial Date</legend>
                                    <div><DatePicker selected={start} onChange={(date) => {  setData( { ...data, start: date } ) } } /></div>
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Initial Date</legend>
                                    <div>{props.editStageValue.startDate}</div>
                                </fieldset>
                            ) }
                            { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Due Date</legend>
                                    <div><DatePicker selected={due} onChange={(date) => { setData( { ...data, due: date } ) } } /></div>
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Due Date</legend>
                                    <div>{props.editStageValue.dueDate}</div>
                                </fieldset>
                            ) }
                            { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Nudges: <button onClick={(sender) => { const prevNudges = nudges ? [...nudges] : []; prevNudges.push(''); setData( { ...data, nudges: prevNudges } ); } } >+</button></legend>
                                    {buildNudges(nudges, data, setData)}
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Nudges:</legend>
                                        {getReadOnlyNudges(props.editStageValue.nudges)}
                                </fieldset>
                            ) }
                            { edit === true && (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Select Users:</legend>
                                    <div>
                                        {buildUsers(props.cohortUsers, selectedUsers, data, setData)}
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px' }}>
                                            <button onClick={
                                                (sender) => {
                                                    const selectAllUsers = {};
                                                    for (let i = 0; i < props.cohortUsers.length; i++) {
                                                        const user = props.cohortUsers[i];
                                                        if (user.is_active === true) {
                                                            selectAllUsers[user.user_id] = user;
                                                        }
                                                        setData( { ...data, selectedUsers: selectAllUsers });
                                                    }
                                                } } >Check All</button>
                                            <button onClick={
                                                (sender) => {
                                                    setData( { ...data, selectedUsers: [] });        
                                                }
                                            }>Uncheck All</button>
                                        </div>
                                    </div>
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Selected Users:</legend>
                                        {getReadOnlyUsers(props.editStageValue.user_ids, props.cohortUsers)}
                                </fieldset>
                            ) }
                            { edit === true && (
                                <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Select Surveys:</legend>
                                    <div style={{ textAlign: 'left' }}>
                                        {buildSurveys(props.cohortSurveys, selectedSurveys, data, setData)}
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px' }}>
                                            <button onClick={
                                                (sender) => {
                                                    const selectAllSurveys = {};
                                                    for (let i = 0; i < props.cohortSurveys.length; i++) {
                                                        const survey = props.cohortSurveys[i];
                                                        if (survey.is_active === true) {
                                                            selectAllSurveys[survey.internal_id] = survey;
                                                        }
                                                        setData( { ...data, selectedSurveys: selectAllSurveys } );
                                                    }
                                                } } >Check All</button>
                                            <button onClick={
                                                (sender) => {
                                                    setData( { ...data, selectedSurveys: [] });        
                                                }
                                            }>Uncheck All</button>
                                        </div>
                                    </div>
                                </fieldset>
                            ) || (
                                <fieldset style={{ width: '40%', textAlign: 'left', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Selected Surveys:</legend>
                                    {getReadOnlySurveys(props.editStageValue.survey_ids, props.cohortSurveys)}
                                </fieldset>
                            ) }
                        </div>
                        { canEdit === true && (
                            <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                { (edit === true && canEdit) && (
                                    <button onClick={
                                        (sender) => {
                                           
                                            const hasUpdates = Object.entries(data).length > 0;
                                            console.log(data);
                                            if (hasUpdates) {
                                                const updateData = { id: props.editStageValue.id, ...data };
                                                if (updateData.selectedSurveys) {
                                                    const entries = Object.entries(updateData.selectedSurveys);
                                                    const surveyIds = [];
                                                    for(const [key, _] of entries) {
                                                        surveyIds.push(key);
                                                    }
                                                    updateData["selectedSurveys"] = surveyIds;
                                                }
                                                if (updateData.selectedUsers) {
                                                    const entries = Object.entries(updateData.selectedUsers);
                                                    const userIds = [];
                                                    for(const [key, _] of entries) {
                                                        userIds.push(key);
                                                    }
                                                    updateData["selectedUsers"] = userIds;
                                                }
                                                request('updateCohortStage', updateData).then(res => {
                                                    setEdit(false);
                                                    setData({});
                                                    props.closeModal(true, false);
                                                });
                                            }
                                        } }
                                    >
                                        Save
                                    </button>
                                ) }
                                <button onClick={
                                    (sender) => {
                                        setEdit(!edit);
                                        setData({});
                                    } }
                                >
                                    {editText}
                                </button>
                            </div>
                        ) }
                        { statuses && (
                            <KokoroTable
                                style={{ width: '100%' }}
                                fields= {[
                                    { header: 'id', value: 'id' },
                                    { header: 'Survey', value: 'survey' },
                                    { header: 'First Name', value: 'first_name' },
                                    { header: 'Last Name', value: 'last_name' },
                                    { header: 'Email', value: 'email' },
                                    { header: 'Emailed', value: 'emailed' },
                                    { header: 'Completed', value: 'completed' },
                                    { header: 'Issues', value: 'issues' }
                                ]}
                                maxItems={10}
                                data={statuses}
                                tableName="stageStatus"
                            />
                        )}
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const getReadOnlyNudges = (nudges) => {
    const toRender = [];
    if (nudges) {
        for (let i = 0; i < nudges.length; i++) {
            const nudge = nudges[i];
            const nudgeData = nudge !== '' ? new Date(nudge) : undefined;
            const nudgeDate = nudgeData ? `${nudgeData.getMonth()}/${nudgeData.getDate()}/${nudgeData.getFullYear()}` : '';
            toRender.push(<div key={`RO-Nudge-${i}`}>Nudge #{i}: {nudgeDate}</div>);
        }
    }
    return toRender;
}

const getReadOnlyUsers = (users, cohortUsers) => {
    const toRender = [];
    if (users && cohortUsers) {
        for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < cohortUsers.length; j++) {
                if (cohortUsers[j].user_id === users[i]) {
                    toRender.push(<div key={`RO-User-${cohortUsers[j].user_id}`}>{cohortUsers[j].first_name} {cohortUsers[j].last_name}</div>);
                    break;
                }
            }
        }
    }
    return toRender;
}

const getReadOnlySurveys = (surveys, cohortSurveys) => {
    const toRender = [];
    if (surveys && cohortSurveys) {
        for (let i = 0; i < surveys.length; i++) {
            for (let j = 0; j < cohortSurveys.length; j++) {               
                if (cohortSurveys[j].internal_id === surveys[i]) {
                    toRender.push(<div key={`RO-Survey-${cohortSurveys[j].internal_id}`}>{cohortSurveys[j].survey}</div>);
                    break;
                }
            }
        }
    }
    return toRender;
}

const buildTypeOptions = (types) => {
    const toRender = [];
    if (types) {
        for (let i = 0; i < types.length; i++) {
            const type = types[i];
            toRender.push(<option key={`AddState-Type-${type.id}`} value={type.id}>{type.name}</option>);
        }
    }
    return toRender;
}

const buildNudges = (nudges, data, setData) => {
    const toRender = [];
    if (nudges) {
        for (let i = 0; i < nudges.length; i++) {
            const nudge = nudges[i] === '' ? '' : new Date(nudges[i]);
            toRender.push( <div key={`CohortAdd-Nudge-${i}`}><label>Nudge #{i}:</label><DatePicker selected={nudge} onChange={(date) => { const prevNudges = nudges ? [...nudges] : []; prevNudges[i] = date; setData( { ...data, nudges: prevNudges } ) } } /><button onClick={(sender) => { const prevNudges = nudges ? [...nudges] : []; prevNudges.splice(i, 1); setData( { ...data, nudges: prevNudges } ); } } >-</button></div>)
        }
    }
    return toRender;
}

const buildUsers = (users, selectedUsers, data, setData) => {
    if (Array.isArray(selectedUsers)) {
        const tempUsers = {};
        for(const key of selectedUsers) {
            tempUsers[key] = {};
        }
        selectedUsers = tempUsers;
    }
    const prevSelectedUsers = { ...selectedUsers };

    const toRender = [];
    if (users) {
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.is_active !== true) {
                continue;
            }
            const name = `${user.first_name} ${user.last_name}`;
            const key = `StageAdd-User-Select-${user.user_id}`;
            toRender.push(
                <div key={key}>
                    <input type="checkbox" checked={prevSelectedUsers[user.user_id] !== undefined} name={key} value={user.user_id} onChange={(sender) => {
                        if (sender.target.checked === true) {
                            prevSelectedUsers[user.user_id] = user;
                        } else {
                            delete prevSelectedUsers[user.user_id];
                        }
                        setData( { ...data, selectedUsers: prevSelectedUsers } )
                        } }
                    />
                    <label htmlFor={key}>{name}</label>
                </div>
            );
        }
    }

    return toRender;
}

const buildSurveys = (cohortSurveys, selectedSurveys, data, setData) => {
    if (Array.isArray(selectedSurveys)) {
        const tempSurveys = {};
        for(const key of selectedSurveys) {
            tempSurveys[key] = {};
        }
        selectedSurveys = tempSurveys;
    }
    const prevSelectedSurveys = { ...selectedSurveys };
    const toRender = [];
    if (cohortSurveys) {
        for (let i = 0; i < cohortSurveys.length; i++) {
            const cohortSurvey = cohortSurveys[i];
            if (cohortSurvey.is_active !== true) {
                continue;
            }
            const key= `StageAdd-Survey-Select-${cohortSurvey.internal_id}`;
            toRender.push(
                <div key={key}>
                    <input type="checkbox" checked={prevSelectedSurveys[cohortSurvey.internal_id] !== undefined} name={key} onChange={(sender) => { 
                        if (sender.target.checked === true) {
                            prevSelectedSurveys[cohortSurvey.internal_id] = cohortSurvey;
                        } else {
                            delete prevSelectedSurveys[cohortSurvey.internal_id];
                        }
                        setData( { ...data, selectedSurveys: prevSelectedSurveys } ); 
                        } }
                    />
                    <label htmlFor={key}>{cohortSurvey.survey}</label>
                </div>
            );
        }
    }

    return toRender;
}

export default CohortEditStage;