import React from 'react';

const SurveyAnswers = (props) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>AnswerKey</th>
                    <th>QId</th>
                    <th>Question Text</th>
                    <th>AId</th>
                    <th>Answer Label</th>
                    <th>Answer Text</th>
                    <th>Value</th>
                    <th>Reverse Coded</th>
                </tr>
            </thead>
            <tbody>
                {renderAnswers(props.surveyAnswers, props.setScore, false)}
                <tr key="Unanswered"><td colSpan="8"><h2>Unanswered</h2></td></tr>
                {renderAnswers(props.surveyAnswers, props.setScore, true)}
            </tbody>
        </table>
    );
}

const renderAnswers = (surveyAnswers, setScore, unanswered) => {
    const toRender = [];
    let score = 0;
    let index = 0;
    if (surveyAnswers && surveyAnswers.length > 0) {
        for(const surveyAnswer of surveyAnswers) {
            if ((!surveyAnswer.answer_key) !== unanswered) {
                continue;
            }
            const answer_key = surveyAnswer.answer_key ? surveyAnswer.answer_key : `unaswered-${++index}`;
            let myScore = surveyAnswer.matrix_value > 0 ? surveyAnswer.matrix_value : surveyAnswer.value;
            if (surveyAnswer.reverse_coded) {
                myScore = 6 - myScore;
            }
            score += myScore;
            toRender.push(<tr key={answer_key}><td>{answer_key}</td><td>{surveyAnswer.survey_question_id}</td><td>{surveyAnswer.question_text}</td><td>{surveyAnswer.survey_answer_id}</td><td>{surveyAnswer.answer_label}</td><td>{surveyAnswer.answer_text}</td><td>{myScore}</td><td>{surveyAnswer.reverse_coded === true ? "true" : "false"}</td></tr>);
        }
    }
    if (unanswered === false) {
        setScore(score);
    }
    return toRender;
}

export default SurveyAnswers;