import CryptoJS from 'crypto-js';
import axios from 'axios';
import { getApiKey, getUser, isLoggedIn, logIn } from '../components/Firebase';

let user;
let userSettings;
const getLocalApi = () => {
    if (window.location.href.indexOf("http://192.168.1.84") > -1) {
        return "http://192.168.1.84:3001";
    }
    else if (window.location.href.indexOf("http://192.168.1.98") > -1) {
        return "http://192.168.1.98:3001";
    }
    else if (window.location.href.indexOf("http://localhost") > -1) {
        return "http://localhost:3001";
    }
    return "https://internal.joinkokoro.com";
}

const setUser = async (gUser) => {
    user = gUser;
    if (user && user.uid) {
        userSettings = await getApiKey(user.uid);
    }
}

const clearUser = () => {
    user = undefined;
    userSettings = undefined;
}

const isAuthorized = (setAuthorized) => {
    generateBearerToken().then(token => {
        setAuthorized(token !== null, userSettings ? userSettings.level : -1);
    });
    
}

const generateBearerToken = async () => {
    if (!userSettings) {
        const gUser = await getUser();
        if (isLoggedIn()) {
            await setUser(gUser);
        }
    }
    if (userSettings) {
        const date = new Date();
        var ciphertext = CryptoJS.AES.encrypt(`${userSettings.apiKey}-${date}`, userSettings.passPhrase).toString();
        return `${userSettings.pKey}-${ciphertext}`;
    }
    return null;
}

const request = (endPoint, data = {}, needBlob = false) => {
    return generateBearerToken().then(token => {
        if (token === null) {
            return { error: 'Token not available'};
        }
        data["Authorization"] = token;
        const apiHostLocation = getLocalApi();
        const apiRealizedEndPoint = `${apiHostLocation}/api/${endPoint}`;
        const configuration = {};
        if (needBlob === true) {
            configuration.responseType = 'blob';
        }
        return axios.post(apiRealizedEndPoint, data, configuration);
    });
}

export default getLocalApi;
export { request, setUser, clearUser, isAuthorized };