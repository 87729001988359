import React from 'react';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import KokoroTable from '../../Utils/KokoroTable';
import UserAddModal from './UserAddModal';
import UserDetailsModal from './UserDetailsModal';

class UserManagement extends React.Component {
    state = {
        users: [],
        companies: [],
        showDetailsModal: false,
        detailsValue: null,
        showAddModal: false,
        showDialog: false,
        statusChangeValue: null
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getCompanies();
        }
    }

    // Add process to monitor for terminate date
    // Alternative Email for users
    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>User Management</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ) } } >Add New User</button>
                <UserDetailsModal showModal={this.state.showDetailsModal} value={this.state.detailsValue} closeModal={this.handleModal} companies={this.state.companies} />
                <UserAddModal showModal={this.state.showAddModal} closeModal={this.handleModal} companies={this.state.companies} />
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[ { header: 'First Name', value: 'first_name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Last Name', value: 'last_name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Email', value: 'email', filterable: true, sortable: true }, { header: 'Company', value: 'company', filterable: true, sortable: true }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Details', value: 'details' }, { header: 'Modify Status', value: 'modify_status' } ]} data={this.state.users} maxItems={10} tableName="Users" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Status for this user?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
                
            </div>
        );
    }

    getCompanies = () => {
        request('getCompanies').then(res => {
            const companies = (res && res.data && res.data.rows && res.data.rows.length > 0) ? res.data.rows : [];
            this.getUsers(companies);
        }, reason => {

        });
    }

    getUsers = (companies) => {
        request('getUsers').then(res => {
            const users = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const user of res.data.rows) {
                    user.status = user.is_active === true ? "Active" : "Inactive";
                    user.details = (<button onClick={(sender) => { this.setState((prevState) => ( { showDetailsModal: true, detailsValue: user } ) ) } }>Details</button>);
                    user.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: user } ) ) } }>{user.is_active === true ? 'Deactivate' : 'Reactivate'}</button>);;
                    users.push(user);
                }
            }
            this.setState((prevState) => ( { users, companies, showDetailsModal: false, detailsValue: null, showDialog: false, statusChangeValue: null, showAddModal: false } ) );
        }, reason => {
    
        });
    }

    handleModal = (reload) => {
        if (reload === true) {
            this.getCompanies();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsValue: null, showAddModal: false, showAddModal: false } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updateUser', { id: value.id, is_active: !value.is_active }).then(res => {
                this.getCompanies();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }
}

export default UserManagement;