import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class SurveyDetails extends React.Component {
    state = {
        survey: null,
        categories: null,
        questions: null,
        reverseUpdates: {},
        categoryUpdates: {},
        surId: 0,
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurvey();
        }
    }

    render = (props) => {
        return (
            <div>
                <div>
                    <h2>Survey Details Admin</h2>
                    <h3>Survey Name: {(this.state.survey ? this.state.survey.name : "")}</h3>
                    <h3>Survey Id: {this.state.surId}</h3>
                    <h3><button><Link to={`/admin/surveyPreview/${this.state.surId}`}>Preview</Link></button></h3>
                    <h3><button onClick={this.getSurvey}>Refresh</button></h3>
                </div>
                <div>
                    <div>
                        <h3>Questions List</h3>
                        {this.renderQuestions()}
                        <button disabled={Object.entries(this.state.reverseUpdates).length === 0 && Object.entries(this.state.categoryUpdates).length === 0} onClick={this.updateQuestions}>Save</button>
                    </div>
                </div>
            </div>
        );
    }

    updateQuestions = (sender) => {
        sender.target.disabled = true;
        request('surveyQuestions/updateQuestions', { reverse: this.state.reverseUpdates, category: this.state.categoryUpdates }).then(res => {
            this.getSurvey();
            sender.target.disabled = false;
        }, reason => {

        });
    }

    getSurId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const surId = pathSplit[pathSplit.length - 1];
        return surId;
    }

    getSurvey = () => {
        const surId = this.getSurId();
        request(`surveys/getSurvey/${surId}`).then(res => {
            const survey = res && res.data && res.data.rows && res.data.rows.length === 1 ? res.data.rows[0] : undefined;
            if (survey) {
                this.getCategories(survey);
            }
        }, reason => {
    
        });
    }

    getCategories = (survey) => {
        request(`question/categories/getSurveyCategories/${survey.id}`).then(res => {
            const categories = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getQuestions(survey, categories)
        });
    }

    getQuestions = (survey, categories) => {
        request(`surveyQuestions/getQuestions/${survey.id}`).then(res => {
            const questions = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.setState((prevState) => ( { survey, categories, questions, surId: survey.id, reverseUpdates: { }, categoryUpdates: { } } ) );
        }, reason => {
    
        });
    }

    buildCategoryInput = (element) => {
        const categoryUpdate = this.state.categoryUpdates;
        const categories = this.state.categories;
        const selected = categoryUpdate[element.id] ? categoryUpdate[element.id] : element.question_category_id;
        const options = [];
        for(let i = 0; i < categories.length; i++) {
            const id = categories[i].id;
            const value = categories[i].name;
            options.push(<option key={`${element.id}-qCat-${id}`} value={id}>{value}</option>);
        }
        return (<select key={`${element.id}-qCat`} value={selected} onChange={(sender) => {
            if (element.question_category_id == sender.target.value) {
                delete categoryUpdate[element.id];
            } else {
                categoryUpdate[element.id] = sender.target.value;
            }
            this.setState( (prevState) => ( { categoryUpdate } ) );
        }}>
            {options}
        </select>);
    }

    renderQuestions = () => {
        const questions = this.state.questions;
        const reverseUpdates = this.state.reverseUpdates;
        const toRender = [];
        if (questions) {
            const blackList = ['control_matrix'];
            questions.forEach(element => {
                const reversed = reverseUpdates[element.id] === true || reverseUpdates[element.id] === false ? reverseUpdates[element.id] === true : element.reverse_coded === true;
                const changed = reverseUpdates[element.id] === true || reverseUpdates[element.id] === false;
                const toggleButton = (<button onClick={() => { 
                    if (reverseUpdates[element.id] === true || reverseUpdates[element.id] === false) {
                        delete reverseUpdates[element.id];
                    } else {
                        reverseUpdates[element.id] = !(element.reverse_coded === true);
                    }
                    this.setState((prevState) => ( { reverseUpdates: reverseUpdates } ) );
                 }}>Toggle</button>);
                const reversedText = `${reversed === true ? 'True' : 'False'}${changed ? '*' : ''}`;
                const reverseAllowed = blackList.indexOf(element.type) === -1;
                const categorySelect = this.buildCategoryInput(element);
                toRender.push(<tr key={element.id}><td>{element.id}</td><td>{element.type}</td><td>{element.question_name}</td><td>{element.question_text}</td><td>{reverseAllowed ? categorySelect : 'Use Details' }</td><td>{reverseAllowed ? reversedText : 'Use Details'}</td><td>{reverseAllowed ? toggleButton : 'Use Details'}</td><td><button><Link to={`../admin/questions/${element.id}`}>Details</Link></button></td></tr>);
            });
        }
        return (<table><thead><tr><th>Id</th><th>Type</th><th>Name</th><th>Text</th><th>Category</th><th>Reverse Coded</th><th>Toggle Reverse Coded</th><th>Details</th></tr></thead><tbody>{toRender}</tbody></table>)
    }
}

export default SurveyDetails;