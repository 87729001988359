import React from 'react';
import { request } from '../../../utils/requests';
import KokoroTable from '../../Utils/KokoroTable';
import SurveyScore from '../SurveyReport/SurveyScore';
import CohortUserPractices from './CohortUserPractices';
import CohortUserReportDetails from './CohortUserReportDetails';
import KokoroDialog from '../../Utils/KokoroDialog';
import CohortReportGraph from './CohortReportGraph';

class CohortUserReporting extends React.Component {
    state = {
        stageFilter: 0,
        cohortData: [],
        allGraphData: [],
        nextThing: undefined,
        user: undefined,
        stages: undefined,
        showDetailsModal: false,
        detailsModalValue: null,
        showPracticeModal: false,
        showDialog: false,
        showApprovedDialog: false,
        statusChangeValue: null
    }

    once = false;
    componentDidMount = () => {
        if (this.once !== true) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getPractices();
    }

    lastDownload = 0;
    dequeueGraphData = () => {
        this.setState((prevState) => { 
            const allGraphData = prevState.allGraphData.length > 1 ? prevState.allGraphData.slice(1) : [];
            if (allGraphData.length === 0 && this.state.nextThing) {
                if (Date.now() - this.lastDownload < 10000) {
                    return  { allGraphData };
                }
                this.lastDownload = Date.now();
                this.state.nextThing();
            }
            return { allGraphData };
        });
    }

    getCohortId = () => {
        const pathSplit = window.location.pathname.split('/');
        return pathSplit[pathSplit.length - 3];;  
    }

    getUserId = () => {
        const pathSplit = window.location.pathname.split('/');
        return pathSplit[pathSplit.length - 1];;  
    }

    getStageId = () => {
        const params = (new URL(document.location)).searchParams;
        return params.get("stage_id");
    }

    getUserSurveys = (data) => {
        request(`cohortUserStages/getCohort/${data.cohort_id}`, { user_id: data.user_id }).then(res => {
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                this.processData(res.data.rows, data);
            }
        }, reason => {

        });
    }

    getAssignedPractices = (data) => {
        const cohort_id = this.getCohortId();
        const user_id = this.getUserId();
        const cohort_stage_id = this.getStageId();
        request(`practices/getAssignedPractice/${cohort_stage_id}/${user_id}`).then(res => {
            let assignedPractices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                assignedPractices = res.data.rows;
            }
            for (const assignedPractice of assignedPractices) {
                data.practiceLookup[assignedPractice.practice_id].assigned = true;
                assignedPractice.name = data.practiceLookup[assignedPractice.practice_id].name;
                const isDisabled = assignedPractice.report_approved === true;
                assignedPractice.remove = (
                    <button disabled={isDisabled} onClick={(sender) => {
                        this.setState((prevState) => ( { showDialog: true, statusChangeValue: assignedPractice } ) );
                    }}>
                        Remove
                    </button>
                );
            }
            this.getUserSurveys({...data, assignedPractices, cohort_id, cohort_stage_id, user_id });
        }, reason => {
    
        });
    }

    refreshAssignedPractices = (data) => {
        request(`practices/getAssignedPractice/${data.stage_id}/${data.user_id}`).then(res => {
            let assignedPractices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                assignedPractices = res.data.rows;
            }
            for (const assignedPractice of assignedPractices) {
                data.practiceLookup[assignedPractice.practice_id].assigned = true;
                assignedPractice.name = data.practiceLookup[assignedPractice.practice_id].name;
                assignedPractice.remove = (
                    <button onClick={(sender) => {
                        this.setState((prevState) => ( { showDialog: true, statusChangeValue: assignedPractice } ) );
                    }}>
                        Remove
                    </button>
                );
            }
            this.setState((prevState) => ({ assignedPractices, ...data }));
        }, reason => {
    
        });
    }

    refreshGetPractices = (stage_id, user_id) => {
        request(`practices/getReportPractices`).then(res => {
            let practices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                practices = res.data.rows;
            }
            const practiceLookup = {};
            for (const practice of practices) {
                practiceLookup[practice.id] = { name: practice.name, assigned: false };
            }
            this.refreshAssignedPractices({ practices, practiceLookup, stage_id, user_id });
        }, reason => {
    
        });
    }

    getPractices = () => {
        request(`practices/getReportPractices`).then(res => {
            let practices = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                practices = res.data.rows;
            }
            const practiceLookup = {};
            for (const practice of practices) {
                practiceLookup[practice.id] = { name: practice.name, assigned: false };
            }
            this.getAssignedPractices({ practices, practiceLookup });
        }, reason => {
    
        });
    }

    processData = (cohortData, data) => {
        const user = {};
        const stagesMap = {};
        const stages = [];
        for(const data of cohortData) {
            let completed_on = 'N/A';
            if (data.completed_on) {
                const completed = new Date(data.completed_on);
                completed_on = `${completed.getMonth() + 1}-${completed.getDate()}-${completed.getFullYear()}`;
            }
            
            let assigned_date = 'N/A';
            if (data.assigned_date) {
                const assigned = new Date(data.assigned_date);
                assigned_date = `${assigned.getMonth() + 1}-${assigned.getDate()}-${assigned.getFullYear()}`;
            }
            
            data.name = `${data.first_name} ${data.last_name}`;
            if (!user.user_id) {
                user.user_id = data.user_id;
                user.name = data.name;
            }
            if (!stagesMap[data.cohort_stage_id]) {
                stagesMap[data.cohort_stage_id] = { stage_name: data.stage_name, cohort_stage_id: data.cohort_stage_id};
                stages.push(stagesMap[data.cohort_stage_id]);

            }
            data.stage = (<div>{data.stage_name}</div>);
            data.score = 'Not Loaded Yet';
            data.completed = completed_on;
            data.assigned = assigned_date;
            data.score = (<SurveyScore key={`ss-${data.uuid}`} asguId={data.uuid} surveyName={data.survey} calculatedScore={(score) => { data.calculatedScore = score; }}/>);
            data.calculatedScore = -1;
            data.details = (<button onClick={(sender) => { this.setState((prevState) => ( { detailsModalValue: data, showDetailsModal: true } ) ) } }>Details</button>);
        }
        const stageFilter = this.getStageId();
        this.setState((prevState) => ({ ...data, cohortData, user, stages, stageFilter: stageFilter ? stageFilter : 0 }));
    }

    renderStageFilter = () => {
        const toRender = [];
        if (this.state.stages) {
            for (let i = 0; i < this.state.stages.length; i++) {
                const stage = this.state.stages[i];
                toRender.push(<option key={`stageFilter-${stage.cohort_stage_id}`} value={stage.cohort_stage_id}>{stage.stage_name}</option>);
            }
        }
        return toRender;
    }

    filterStages = (cohortData, selectedStage) => {
        if (selectedStage == 0) {
            return cohortData;
        }
        const filteredData = [];
        if (cohortData) {
            for (let i = 0; i < cohortData.length; i++) {
                const surveyValue = cohortData[i];
                if (surveyValue.cohort_stage_id == selectedStage) {
                    filteredData.push(surveyValue);
                }
            }
        }
        return filteredData;
    }

    closeDetailsModal = (updatedValue, refreshRequired) => { 
        this.setState((prevState) => ( { showDetailsModal: updatedValue, showPracticeModal: updatedValue } ) );
        if (refreshRequired === true) {
            this.initialRequest();
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        if (value && value.id && affirmative === true) {
            request(`practices/removeAssignedPractice/${value.id}`).then(res => {
                this.initialRequest();
            });
        }
    }

    handleApproveDialog = (value, affirmative) => {
        this.setState((prevState) => ( { showApprovedDialog: false } ) );
        if (affirmative === true) {
            const allGraphData = [];
            const reportData = {};
            const whiteList = [ 'Resilience Scale', 'Anxiety Scale', 'Self-Compassion', 'Depression Scale' ];
            for(const surveyResponse of this.state.cohortData) {
                if (whiteList.indexOf(surveyResponse.survey) < 0) {
                    continue;
                }
                if (!reportData[surveyResponse.survey]) {
                    reportData[surveyResponse.survey] = [];
                }
                reportData[surveyResponse.survey].push(surveyResponse);
            }
            
            const selfCompScores = {};
            for(const [key, value] of Object.entries(reportData)) {
                if (key === 'Self-Compassion') {
                    for(let i = 0; i < value.length; i++) {
                        const val = value[i];
                        let name;
                        switch(i) {
                            case 0:
                                name = 'sc-baseline';
                                break;
                            case 1:
                                name = 'sc-midline';
                                break;
                            case 2:
                                name = 'sc-endline';
                                break;
                        }
                        request(`reportGraphs/addReportGraph`, {cohort_stage_id: this.state.stageFilter, user_id: this.state.user.user_id, img_text: val.completed.replace(new RegExp('-', 'g'), '/'), name });
                        for (const [cat, score] of Object.entries(val.calculatedScore)) {
                            if (!selfCompScores[cat]) {
                                selfCompScores[cat] = [];
                            }
                            selfCompScores[cat].push(score.normalizedScore(score.score, score.total))
                        }
                    }
                    if (value.length < 2) {
                        request(`reportGraphs/addReportGraph`, {cohort_stage_id: this.state.stageFilter, user_id: this.state.user.user_id, img_text: 'TBD', name: 'sc-midline' });
                    }
                    if (value.length < 3) {
                        request(`reportGraphs/addReportGraph`, {cohort_stage_id: this.state.stageFilter, user_id: this.state.user.user_id, img_text: 'TBD', name: 'sc-endline' });
                    }
                } else {
                    let graphLabel;
                    switch(key) {
                        case 'Resilience Scale':
                            graphLabel = 'res-graph'
                            break;
                        case 'Anxiety Scale':
                            graphLabel = 'anx-graph'
                            break;
                        case 'Depression Scale':
                            graphLabel = 'dep-graph'
                            break;
                    }
                    const graphData = { graphType: 'bigGraph', maxRange: value[0].max_score, graphLabel, graphData: []};
                    for (let i = 0; i < value.length; i++) {
                        const val = value[i];
                        const y = val.calculatedScore;
                        let x;
                        switch(i) {
                            case 0:
                                x = `Baseline\r\n(${val.completed})`
                                break;
                            case 1:
                                x = `Midline\r\n(${val.completed})`
                                break;
                            case 2:
                                x = `Endline\r\n(${val.completed})`
                                break;
                        }
                        graphData.graphData.push({ x, y: Number(y), label: y });
                    }
                    if (value.length < 2) {
                        graphData.graphData.push({ x: 'Midline\r\n(TBD)', y: 0 });
                    }
                    if (value.length < 3) {
                        graphData.graphData.push({ x: 'Endline\r\n(TBD)', y: 0 });
                    }
                    allGraphData.push(graphData);
                }
            }
            for (const [cat, scores] of Object.entries(selfCompScores)) {
                let graphLabel;
                switch(cat) {
                    case 'Common Humanity':
                        graphLabel = 'com-graph';
                        break;
                    case 'Self-Judgement':
                        graphLabel = 'jud-graph';
                        break;
                    case 'Isolation':
                        graphLabel = 'iso-graph';
                        break;
                    case 'Self Kindness':
                        graphLabel = 'kin-graph';
                        break;
                    case 'Over-Identification':
                        graphLabel = 'ide-graph';
                        break;
                    case 'Mindfulness':
                        graphLabel = 'min-graph';
                        break;
                    default:
                        continue;
                }
                const graphData = { graphType: 'selfCompassion', maxRange: 5, graphLabel, graphData: []};
                for(let i = 0; i < scores.length; i++) {
                    const y = scores[i];
                    let x;
                    switch(i) {
                        case 0:
                            x = 'B';
                            break;
                        case 1:
                            x = 'M';
                            break;
                        case 2:
                            x = 'E';
                            break;
                    }
                    graphData.graphData.push({ x, y: Number(y), label: y });
                }
                if (scores.length < 2) {
                    graphData.graphData.push({ x: 'M', y: 0 });
                }
                if (scores.length < 3) {
                    graphData.graphData.push({ x: 'E', y: 0 });
                }
                allGraphData.push(graphData);
            }
            this.setState((prevState) => ({ allGraphData }));
            return;
            request(`practices/approveReport`, { cohort_stage_id: this.state.cohort_stage_id, user_id: this.state.user_id }).then(res => {
                this.initialRequest();
            }, reason => {
                console.log(reason);
            });
        }       
    }

    previewPDF = () => {
        request(`previewPDF`, { stage_id: this.state.stageFilter, user_id: this.state.user_id }, true).then(res => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.setAttribute('download', `${this.state.user.user_id}.pdf`)
            link.click();
            link.remove();
            URL.revokeObjectURL(blobUrl);
        }, reason => {
            console.log(reason);
        });
    }
    

    render = () => {
        const reportState = this.state.assignedPractices && this.state.assignedPractices.length > 0 && this.state.assignedPractices[0].report_sent === true ? 'Report Sent' : (this.state.assignedPractices && this.state.assignedPractices.length > 0 && this.state.assignedPractices[0].report_approved === true  ? 'Report Approved' : 'Approve Report');
        const isDisabled = this.state.assignedPractices && this.state.assignedPractices.length > 0 && this.state.assignedPractices[0].report_approved === true;
        return (
            <div style={{ padding: '2vw' }}>
                <h3>Cohort Reporting User Details</h3>
                <h4>User: {this.state.user ? this.state.user.name : 'Loading...'}</h4>
                <h4>User Id: {this.state.user ? this.state.user.user_id : 'Loading...'}</h4>
                <CohortUserReportDetails value={this.state.detailsModalValue} showModal={this.state.showDetailsModal} closeModal={this.closeDetailsModal} />
                <CohortUserPractices showModal={this.state.showPracticeModal} closeModal={this.closeDetailsModal} practices={this.state.practices} practiceLookup={this.state.practiceLookup} cohort_stage_id={this.state.stageFilter} user_id={this.state.user_id} />
                <div>
                    <label>Stage Filter:</label>
                    <select value={this.state.stageFilter} onChange={(sender) => { sender.preventDefault(); this.setState((prevState) => ({ stageFilter: sender.target.value } ) ); this.refreshGetPractices(sender.target.value, this.state.user_id )}}>
                        <option value={0}>None</option>
                        {this.renderStageFilter()}
                    </select>
                </div>
                { this.stageFilter != 0 && (
                    <div>
                        <h3>Practices</h3>
                        <KokoroTable style={{ width: '100%' }} 
                            fields={[
                                {header: 'Name', value: 'name', filterable: true },
                                {header: 'Remove', value: 'remove' }
                            ]}
                            data={this.state.assignedPractices}
                            maxItems={5}
                            tableName="UserPractices"
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                            <button disabled={this.stageFilter === 0 || isDisabled === true} onClick={(sender) => { this.setState((prevState) => ({ showPracticeModal: true }))}}>Edit Practices</button>
                            <button onClick={
                                (sender) => { 
                                    this.setState((prevState) => ({ nextThing: this.previewPDF}));
                                    this.handleApproveDialog(undefined, true);
                                }
                            }>
                                Preview PDF
                            </button>
                            <button disabled={this.stageFilter === 0 || isDisabled === true} onClick={(sender) => {
                                this.setState(prevState => ({ showApprovedDialog: true }));
                            }}>{reportState}</button>
                            <KokoroDialog title="Status Change" message="Are you sure you want to approve this report?" value={undefined} showDialog={this.state.showApprovedDialog} closeModal={this.handleApproveDialog} />
                        </div>
                    </div>
                )}
                <div>
                    <CohortReportGraph allGraphData={this.state.allGraphData} dequeueGraphData={this.dequeueGraphData} selectedStage={this.state.stageFilter} user={this.state.user} />
                    <KokoroTable style={{ width: '100%' }} 
                        fields={[
                            { header: 'Survey', value: 'survey', filterable: true },
                            { header: 'Assigned Date', value: 'assigned' },
                            { header: 'Completed Date', value: 'completed' },
                            { header: 'Score', value: 'score' },
                            { header: 'Details', value: 'details' }
                        ]} 
                        subSections={[{ value: '', color: 'gray' }, { value: 'stage', color: 'orange' }]}
                        data={this.filterStages(this.state.cohortData, this.state.stageFilter)}
                        maxItems={15}
                        tableName="CohortReports" />
                </div>
                <KokoroDialog title="Status Change" message="Are you sure you want to remove the practice?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }
}

export default CohortUserReporting;