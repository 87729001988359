import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class SurveyGroup extends React.Component {
    state = {
        surveyGroup: null,
        surveyGroupUsers: null,
        sgId: 0
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurveyGroup();
        }
    }
    render = () => {
        return (<div>
            <h2>Survey Group Details Admin</h2>
            <h3><button onClick={this.getSurveyGroup}>Refresh</button></h3>
            {this.renderSurveyGroup()}
            <div>
                {this.renderSurveyGroupUsers()}
            </div>
        </div>);
    }

    getSgId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const sgId = pathSplit[pathSplit.length - 1];
        return sgId;  
    }

    getSurveyGroup = () => {
        const sgId = this.getSgId();
        request(`surveyGroups/getGroup/${sgId}`).then(res => {
            let surveyGroup = [];
            if (res && res.data && res.data.rows && res.data.rows.length === 1) {
                surveyGroup = res.data.rows[0];
            }
            this.setState((prevState) => ( { surveyGroup, sgId } ) );
            this.getSurveyGroupUsers(sgId);
        }, reason => {
    
        });
    }
    
    getSurveyGroupUsers = (sgId) => {
        request(`surveyGroupUsers/getUsers/${sgId}`).then(res => {
            let surveyGroupUsers = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                surveyGroupUsers = res.data.rows;
            }
            this.setState((prevState) => ( { surveyGroupUsers } ) );
        }, reason => {
    
        });
    }

    renderSurveyGroup = () => {
        const surveyGroup = this.state.surveyGroup;
        let toRender = (<div></div>);
        if (surveyGroup) {
            toRender = (
                <div>
                    <h3>Survey Group Id: {surveyGroup.id}</h3>
                    <h3>Send at: {surveyGroup.send_at}</h3>
                    <h3>Expires at: {surveyGroup.expires_at}</h3>
                    <h3>Reminder Count: {surveyGroup.reminder_count}</h3>
                    <h3>Reminder Frequency: Every {surveyGroup.reminder_frequency} days</h3>
                </div>
            );
        }
        return toRender;
    }
    
    renderSurveyGroupUsers = () => {
        const surveyGroupUsers = this.state.surveyGroupUsers;
        const toRender = [];
        if (surveyGroupUsers) {
            for(const surveyGroupUser of surveyGroupUsers) {
                toRender.push(
                    <tr key={surveyGroupUser.guid}>
                        <td>{surveyGroupUser.guid}</td>
                        <td>{surveyGroupUser.first_name}</td>
                        <td>{surveyGroupUser.last_name}</td>
                        <td>{surveyGroupUser.email}</td>
                        <td>{surveyGroupUser.is_email_sent === true ? 'Yes' : 'No'}</td>
                        <td>{surveyGroupUser.is_complete === true ? 'Yes' : 'No' }</td>
                        <td>{surveyGroupUser.reminder_count}</td>
                        <td>{surveyGroupUser.last_reminder}</td>
                        <td>{surveyGroupUser.is_complete === true && surveyGroupUser.report_ready !== true ? (<Link to={`/admin/surveyReport/${surveyGroupUser.guid}`}>Generate Report</Link>) : surveyGroupUser.is_complete !== true && surveyGroupUser.report_ready !== true ? 'No' : surveyGroupUser.report_ready === true ? 'Yes' : 'No' }</td>
                        <td>{surveyGroupUser.report_sent === true ? 'Yes' : 'No'}</td>
                    </tr>
                );
            }
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Email Sent</th>
                        <th>Completed</th>
                        <th>Reminder Remaining</th>
                        <th>Last Reminder</th>
                        <th>Report Ready</th>
                        <th>Report Sent</th>
                    </tr>
                </thead>
                <tbody>
                    {toRender}
                </tbody>
            </table>
        );
    }
    
}




export default SurveyGroup;