import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class QuestionDetails extends React.Component {
    state = {
        question: null,
        answers: null,
        categories: null,
        reverseUpdates: {},
        categoryUpdates: {},
        qId: 0
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getQuestion();
        }
    }

    render = (props) => {
        return (
            <div>
                <div>
                    <h2>Question Details Admin</h2>
                    <h3>Question Name: {(this.state.question ? this.state.question.question_name : '')}</h3>
                    <h3>Question Type: {(this.state.question ? this.state.question.type : '')}</h3>
                    <h3>Question Id: {this.state.qId}</h3>
                    <h3><button onClick={this.getQuestion}>Refresh</button></h3>
                </div>
                <div>
                    <h3>Answers</h3>
                    {this.renderAnswers()}
                    <button disabled={Object.entries(this.state.reverseUpdates).length === 0 && Object.entries(this.state.categoryUpdates).length === 0} onClick={this.updateAnswers}>Save</button>
                </div>
                
            </div>
        );
    }

    updateAnswers = (sender) => {
        sender.target.disabled = true;
        request('surveyAnswers/updateAnswers', { reverse: this.state.reverseUpdates, category: this.state.categoryUpdates }).then(res => {
            this.getQuestion();
            sender.target.disabled = false;
        }, reason => {

        });
    }

    getQId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const qId = pathSplit[pathSplit.length - 1];
        return qId;  
    }

    getQuestion = () => {
        const qId = this.getQId();
        request(`surveyQuestions/getQuestion/${qId}`).then(res => {
            const question = res && res.data && res.data.rows && res.data.rows.length === 1 ? res.data.rows[0] : undefined;
            if (question) {
                this.getCategories(question);
            }
        }, reason => {
    
        });
    }

    getAnswers = (question, categories) => {
        const qId = question.id;
        request(`surveyAnswers/getAnswers/${qId}`).then(res => {
            const answers = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.setState((prevState) => ( { question, categories, answers, categoryUpdates: {}, reverseUpdates: {}, qId } ) );
        }, reason => {
    
        });
    }

    getCategories = (question) => {
        request(`question/categories/getSurveyCategories/${question.survey_id}`).then(res => {
            const categories = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getAnswers(question, categories)
        });
    }

    buildCategoryInput = (element) => {
        const categoryUpdate = this.state.categoryUpdates;
        const categories = this.state.categories;
        const selected = categoryUpdate[element.id] ? categoryUpdate[element.id] : element.question_category_id;
        const options = [];
        for(let i = 0; i < categories.length; i++) {
            const id = categories[i].id;
            const value = categories[i].name;
            options.push(<option key={`${element.id}-qCat-${id}`} value={id}>{value}</option>);
        }
        return (<select key={`${element.id}-qCat`} value={selected} onChange={(sender) => {
            if (element.question_category_id == sender.target.value) {
                delete categoryUpdate[element.id];
            } else {
                categoryUpdate[element.id] = sender.target.value;
            }
            this.setState( (prevState) => ( { categoryUpdate } ) );
        }}>
            {options}
        </select>);
    }

    renderAnswers = () => {
        const answers = this.state.answers;
        const reverseUpdate = this.state.reverseUpdates;
        const type = this.state.question ? this.state.question.type : '';
        const toRender = [];
        if (answers) {
            const whiteList = [ 'control_matrix' ];
            const allowReverse = whiteList.indexOf(type) > -1;
            answers.forEach((element) => {
                const reverseCoded = reverseUpdate[element.id] === true || reverseUpdate[element.id] === false ? reverseUpdate[element.id] === true : element.reverse_coded === true
                const changed = reverseUpdate[element.id] === true || reverseUpdate[element.id] === false;
                const buttonToggle = (
                    <button onClick={() => {
                        if (reverseUpdate[element.id] === true || reverseUpdate[element.id] === false) {
                            delete reverseUpdate[element.id];
                        } else {
                            reverseUpdate[element.id] = !element.reverse_coded;
                        }
                        this.setState( (prevState) => ( { reverseUpdate: reverseUpdate } ) );
                        //this.getQuestion();
                    }}>Toggle</button>
                );
                const categoryInput = this.buildCategoryInput(element);
                toRender.push(
                    <tr key={element.id}>
                        <td>{element.id}</td>
                        <td>{element.label}</td>
                        <td>{element.text}</td>
                        <td>{element.value}</td>
                        <td>{element.max_text}</td>
                        <td>{element.max_value}</td>
                        <td>{element.min_text}</td>
                        <td>{element.min_value}</td>
                        <td>{allowReverse ? categoryInput : 'Use Question Details'}</td>
                        <td>{allowReverse ? `${reverseCoded}${changed ? '*' : ''}` : 'Use Question Details'}</td>
                        <td>{allowReverse ? buttonToggle : 'Use Question Details'}</td>
                        <td><Link to={`../admin/answers/${element.id}`}>Details</Link></td>
                    </tr>
                );
            });
        }
    
        return (
            <table>
                <thead>
                    <tr>
                        <td>id</td>
                        <td>label</td>
                        <td>text</td>
                        <td>value</td>
                        <td>max_text</td>
                        <td>max_value</td>
                        <td>min_text</td>
                        <td>min_value</td>
                        <td>category</td>
                        <td>reverse_coded</td>
                        <td>toggle</td>
                        <td>details</td>
                    </tr>
                </thead>
                <tbody>
                    {toRender}
                </tbody>
            </table>
        );
    }
} 


export default QuestionDetails;