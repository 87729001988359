import React, { useState } from 'react';
import { request } from '../../../../utils/requests';
import KokoroModal from '../../../Utils/KokoroModal';
import KokoroTable from '../../../Utils/KokoroTable';

class StageTypeDetailsModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        name: '',
        color: '',
        edit: false,
    }

    updateApi = 'updateStageType';

    render = () => {
        const nameInputValue = this.state.name.trim() === '' && this.props.value && this.props.value.name ? this.props.value.name : this.state.name;
        const colorInputValue = this.state.color.trim() === '' && this.props.value && this.props.value.color ? this.props.value.color : this.state.color;
        const editText = this.state.edit === true ? 'Cancel' : 'Edit';
        const canEdit = true;
        return (
            <div>
                {
                    (this.props.showModal && this.props.value) && (
                        <KokoroModal closeModal={this.props.closeModal}>
                            <div>
                                <h3>Stage Type Details</h3>
                                <div style={{ color: colorInputValue }}>Color Preview</div>
                                <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Name</legend>
                                        { (this.state.edit === true && canEdit) && (
                                            <div>
                                                <label>Name:</label>
                                                <input type="text" value={nameInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        this.setState((prevState) => ( { name: sender.target.value } ) );
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Name: </label>
                                                <span>{this.props.value.name}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Color</legend>
                                        { (this.state.edit === true && canEdit) && (
                                            <div>
                                                <label>Color:</label>
                                                <input type="text" value={colorInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        this.setState((prevState) => ( { color: sender.target.value } ) );
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Color: </label>
                                                <span>{this.props.value.color}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                </div>
                                { 
                                    canEdit === true && (
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                            { (this.state.edit === true && canEdit) && (
                                                <button onClick={
                                                    async (sender) => {
                                                        const updates = {};
                                                        if (this.state.name !== '' && this.state.name !== this.props.value.name) {
                                                            updates.name = this.state.name;
                                                        }
                                                        if (this.state.color !== '' && this.state.color !== this.props.value.color) {
                                                            updates.color = this.state.color;
                                                        }
                                                        
                                                        const hasUpdates = Object.entries(updates).length > 0;
                                                        if (hasUpdates) {
                                                            request(this.updateApi, { id: this.props.value.id, ...updates }).then(res => {
                                                                this.setState((prevState) => ( { edit: false, name: '', color: '' } ) );
                                                                this.props.closeModal(true);
                                                            });
                                                        }
                                                    } }
                                                >
                                                    Save
                                                </button>
                                            ) }
                                            <button onClick={
                                                (sender) => {
                                                    this.setState((prevState) => ( { edit: !prevState.edit, name: '', color: '' } ) );
                                                } }
                                            >
                                                {editText}
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </KokoroModal>
                    )
                }
            </div>
        );
    }
}

export default StageTypeDetailsModal;
