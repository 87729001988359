import React from 'react';

const CohortTitle = (props) => {
    return (
        <div style={{ marginBottom: '30px', borderStyle: 'solid', padding: '10px' }}>
            <details>
                <summary><h3>{props.cohort.name}</h3>(Click for more details)</summary>
                <div>Id: {props.cohort.id}</div>
                <div>UUID: {props.cohort.uuid}</div>
            </details>
        </div>
    );
}

export default CohortTitle;