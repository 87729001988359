import React from 'react';
import {VictoryChart, VictoryTheme, VictoryPolarAxis, VictoryArea, VictoryPie, VictoryBar, VictoryAxis} from 'victory';
import saveSvgAsPng from 'save-svg-as-png';

const Test = () => {
    return (<div>{chart()}
    <button onClick={savePng}>Save</button></div>);
}

const savePng = (sender) => {
    saveSvgAsPng.svgAsPngUri(document.getElementsByTagName('svg')[2], { encoderOptions: 1 }).then(uri => {
        console.log(uri);
    });
}

const chart = () => {
    const sampleData = [
        {x: "Emp", y: 5},
        {x: "Lone", y: 4},
        {x: "Dep", y: 3},
        {x: "Comp", y: 2}
      ];
      const sampleData2 = [
        {x: "Emp", y: 6},
        {x: "Lone", y: 3},
        {x: "Dep", y: 4},
        {x: "Comp", y: 3}
      ];
      const barData = [
        {x: 'B', y: 5, label: '5'},
        {x: 'M', y: 4, label: '4'},
        {x: 'E', y: 2.5, label: '2.5'},
      ];
      const resData = [
        {x: 'Baseline\r\n(7/22/21)', y: 12, label: 12},
        {x: 'Midline\r\n(12/22/21)', y: 35, label: 35},
        {x: 'Endline\r\n(TBD)', y: 0 },
      ]
    return (
      <>
        <VictoryChart
          domain={{ y: [0, 5] }}
          width={300}
        >
          <VictoryAxis />
          <VictoryBar
            style={{ data: { fill: "#734D8E" } }}
            data={barData}
          />
        </VictoryChart>
        <VictoryChart
          domain={{ x:[0, 4], y: [0, 40] }}
        >
          <VictoryAxis dependentAxis tickCount={8} />
          <VictoryAxis />
          <VictoryBar
            style={{ data: { fill: "#734D8E" } }}
            data={resData}
          />
        </VictoryChart>
      </>
    );
    
}

/*

     <VictoryPie 
          colorScale={["tomato", "orange", "gold", "cyan", "navy" ]}
          innerRadius={100}
          labelRadius={({ innerRadius }) => innerRadius + 60 }
          labels={({datum}) => `${datum.x}, ${datum.y}`}
          data={sampleData}
          style={{ labels: { fontSize: 10 }}}
        />

*/

export default Test;