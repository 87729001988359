import React from 'react';
import { NavLink } from 'react-router-dom';
import { request } from '../../../utils/requests';
import KokoroTable from '../../Utils/KokoroTable';
import SurveyScore from '../SurveyReport/SurveyScore';

class CohortReporting extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        selectedUser: 0,
        users: {},
        cohortData: []
    }

    once = false;
    componentDidMount = () => {
        if (this.once !== true) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getUserSurveys();
    }

    getCohortId = () => {
        const pathSplit = window.location.pathname.split('/');
        return pathSplit[pathSplit.length - 1];;  
    }

    getUserSurveys = () => {
        const cId = this.getCohortId();
        request(`cohortUserStages/getCohort/${cId}`).then(res => {
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                this.processData(res.data.rows);
            }
        }, reason => {

        });
    }

    processData = (cohortData) => {
        const users = {};
        for(const data of cohortData) {
            let completed_on = 'N/A';
            if (data.completed_on) {
                const completed = new Date(data.completed_on);
                completed_on = `${completed.getMonth() + 1}-${completed.getDate()}-${completed.getFullYear()}`;
            }
            
            let assigned_date = 'N/A';
            if (data.assigned_date) {
                const assigned = new Date(data.assigned_date);
                assigned_date = `${assigned.getMonth() + 1}-${assigned.getDate()}-${assigned.getFullYear()}`;
            }
            
            data.name = `${data.first_name} ${data.last_name}`;
            if (!users[data.user_id]) {
                users[data.user_id] = { user_id: data.user_id, name: data.name };
            }
            data.stage = (<div>{data.stage_name}<NavLink to={`userReport/${data.user_id}?stage_id=${data.cohort_stage_id}`}><button>Details</button></NavLink></div>);
            data.score = 'Not Loaded Yet';
            data.completed = completed_on;
            data.assigned = assigned_date;
            data.score = (<SurveyScore key={`ss-${data.uuid}`} asguId={data.uuid} surveyName={data.survey} />)
        }
        this.setState((prevState) => ({ cohortData, users }));
    }

    renderFilter = () => {
        const toRender = [];
        const users = this.state.users;
        if (users) {
            for (const [key, value] of Object.entries(users)) {
                toRender.push(<option key={`userFilter-${key}`} value={key}>{value.name}</option>)
            }
        }
        return (
            <select value={this.state.selectedUser}
                onChange={
                    (sender) => {
                        sender.preventDefault();
                        this.setState((prevState) => ({ selectedUser: sender.target.value }));
                    }
                }
            >
                <option value={0}>None</option>
                { toRender }
            </select>
        );
    }

    filterUsers = (cohortData, filter) => {
        if (filter == '0') {
            return cohortData;
        }
        const returnData = [];
        for (const data of cohortData) {
            if (data.user_id == filter) {
                returnData.push(data);
            }
        }
        return returnData;
    }

    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Cohort Reporting</h2>
                <div>
                    <label>Filter User:</label>
                    {this.renderFilter()}
                </div>
                <div>
                    <KokoroTable style={{ width: '100%' }} 
                        fields={[
                            { header: 'Survey', value: 'survey', filterable: true },
                            { header: 'Assigned Date', value: 'assigned' },
                            { header: 'Completed Date', value: 'completed' },
                            { header: 'Score', value: 'score' } 
                        ]} 
                        subSections={[{ value: 'name', color: 'gray' }, { value: 'stage', color: 'orange' }]}
                        data={this.filterUsers(this.state.cohortData, this.state.selectedUser)}
                        maxItems={5}
                        tableName="CohortReports" />
                </div>
            </div>
        );
    }
}

export default CohortReporting;