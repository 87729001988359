import React from 'react';

const Error = (props) => {
    return (
        <div className="sd-root-modern">
            <div className='survey-message'>{getMessage(props.errorState)}</div>
        </div>
    )
};

const getMessage = (errorId) => {
    switch(errorId) {
        case "1":
            // Survey tied to user information does not exist in database
            return (
                <div>
                    <p>Unfortunately, we've encountered an error loading your survey.</p>
                    <p>Please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 1.</p>
                </div>
            );

        case "2":
            // Survey is already set to complete
            return (
                <div>
                    <p>Congrats - you've already completed this survey!</p>
                    <p>If you think this message was generated in error, please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using.</p>
                </div>
            );
        case "3":
            // User survey id (Guid) doesn't exist in database
            return (
                <div>
                    <p>Unfortunately, the survey link you are using has an invalid survey id. It should look similar to pulse.joinkokoro.com/df636e47-6db2-4673-a4f9-23a3e92adc5a.</p>
                    <p>Please re-click on the survey link we sent you or make sure to copy and paste the full survey link we sent you.</p>
                    <p>If you still run into this problem, please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 3.</p>
                </div>
                );
        case "4":
            // User data doesn't have either surveyname or survey version (internal issue)
            return (
                <div>
                    <p>Unfortunately, we've encountered an error loading your survey.</p>
                    <p>Please help us make this right by notifying pulse@joinkok<a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 4.</p>
                </div>
            )
        case "5":
            // User went to home page without a guid in url
            return (
                <div>
                    <p>Unfortunately, the survey link you are using has an invalid survey id. It should look similar to pulse.joinkokoro.com/df636e47-6db2-4673-a4f9-23a3e92adc5a.</p>
                    <p>Please re-click on the survey link we sent you or make sure to copy and paste the full survey link we sent you.</p>
                    <p>If you still run into this problem, please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 5.</p>
                </div>
            )
        case "6":
            // Server is offline
            return (
                <div>
                    <p>Unfortunately, we've encountered an error loading your survey.</p>
                    <p>Please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 6.</p>
                </div>
            )
        case "7":
            // Survey has expired based on expiration date tied to user info
            return (
                <div>
                    <p>This survey has expired and is no longer accepting submissions.</p>
                    <p>Please reach out to <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> if you have any questions.</p>
                </div>
            )
        case "8":
            // Survey is missing information to be able to submit when sent to server (this should never happen)
            return (
                <div>
                    <p>Your survey submission was missing information and was not processed. Sorry about this! Please visit the survey link again and retry.</p>
                    <p>If you still run into this problem, please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case 8.</p>
                </div>
            )
        default:
            // No status code here we'll need to figure out why we added an error state (this should never happen)
            return (
                <div>
                    <p>Unfortunately, we've encountered an error loading your survey.</p>
                    <p>Please help us make this right by notifying <a href="mailto:pulse@joinkokoro.com">pulse@joinkokoro.com</a> with the survey link you're using and noting this is Error Case Default.</p>
                </div>
            )
    }
}

export default Error;