import React from 'react';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import Error from './Error';
import Loading from './Loading';
import { request } from '../utils/requests';

StylesManager.applyTheme("defaultV2");

class SurveyPreview extends React.Component {
    state = {
        survey: null,
        answerMatrix: null,
        error: null,
        sId: 0
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getAnswerMatrix();
        }
    }

    checkForAutoAdvance = () => {      
        const aBox = document.getElementById('advanceBox');
        if (aBox.checked) {
            this.automateSurvey();
        }
    }

    fixEmbeddedHtml = () => {
        const elements = document.querySelectorAll('.sd-question__description .sv-string-viewer');
        if (elements && elements.length > 0) {
            elements.forEach(element => {
                if (element.innerHTML.indexOf('&lt;') > -1) {
                    element.innerHTML = element.innerText;
                }
            });
        }

        const tables = document.getElementsByTagName('table');
        if (tables && tables.length > 0) {
            
            for(const table of tables) {
                const tBody = table.querySelectorAll('tbody')[0];
                const tBodyRows = tBody.querySelectorAll('tr');
                if (tBodyRows.length === 1) {
                    var tHeadRow = table.querySelector('thead tr');
                    var firstHeaderTd = tHeadRow.querySelectorAll('td')[0];
                    var firstTd = tBodyRows[0].querySelectorAll('td')[0];
                    firstTd.style.display = 'none';
                    firstTd.style.visibility = 'hidden';
                    firstHeaderTd.style.visibility = 'hidden';
                    firstHeaderTd.style.display = 'none';
                }
            };
        }
    }

    automateSurvey = () => {
        const rows = document.getElementsByClassName('sd-table__row');
        for(const row of rows) {
            const columns = row.getElementsByTagName('td');
            const num = Math.floor(Math.random() * (columns.length - 1)) + 1;
            const item = columns[num].getElementsByClassName('sd-item')
            if (item && item.length > 0) {
                item[0].click();
            }
        }
    }

    autoNext = () => {
        if(!this.state.survey.nextPage()) {
            this.state.survey.completeLastPage();
        }
    }

    verifyAllAnswered = () => {
        const rows = document.getElementsByClassName('sd-table__row');
        for(const row of rows) {
            const columns = row.getElementsByClassName('sd-radio--checked');
            if (columns.length === 0) {
                return true;
            }
        }
    }

    render = (props) => {
        return (
            <div>
                {
                    (this.state.survey === null && !this.state.error && (
                        <Loading />
                    )) || (this.state.error && (
                        <Error errorState={this.state.error} />
                    )) || (
                        <div>
                            <label>Auto Advance</label><input id="advanceBox" type="checkbox" />
                            <button id="randomButton" onClick={this.automateSurvey}>Randomize Answers</button>
                            <button onClick={this.autoNext}>Next</button>
                            <Survey model={this.state.survey} />
                        </div>
                    )
                }
            </div>
        )
    }

    getSId = () => {
        const pathName = window.location.pathname;
        const pathSplit = window.location.pathname.split('/');
        const sId = pathSplit[pathSplit.length - 1];
        return sId;
    }

    getAnswerMatrix = () => {
        const sId = this.getSId();
        request(`surveyAnswers/getSurveyAnswerMatrix/${sId}`).then(res => {
            const answerMatrix = {};
            if (res && res.data && res.data.rows.length > 0) {
                for (const row of res.data.rows) {
                    if (!answerMatrix[row.question_id]) {
                        answerMatrix[row.question_id] = {};
                    }
                    const questionData = answerMatrix[row.question_id];
                    questionData.question_text = row.question_text;
                    questionData.type = row.type;
                    questionData.reverseCoded = row.questionReverse;
                    if (!questionData[row.answer_id]) {
                        questionData[row.answer_id] = {};
                    }
                    const answerData = questionData[row.answer_id];
                    answerData.text = row.answer_label;
                    answerData.value = row.value;
                    answerData.reverseCoded = row.answerreverse;
                    if (!answerData.matrix) {
                        answerData.matrix = {};
                    }
                    if (row.matrix_label) {
                        answerData.matrix[row.matrix_label] = row.matrix_value;
                    }
                }
                this.getSurvey(answerMatrix, sId);
            }
        }, reason => {
            this.getSurvey(null, sId);
        });
    }

    getSurvey = (answerMatrix, sId) => {
        request(`surveyPreview/getPreview/${sId}`).then(res => {
            if (res && res.data && res.data.rows[0]) {
                const json = JSON.parse(res.data.rows[0].json);
                const survey = new Model(json.surveyData);
                survey.myGuid = 'Survey-Preview';
                survey.mySurveyId = sId;
                survey.myAnswerMap = json.questionMap;
                survey.onAfterRenderPage.add(this.fixEmbeddedHtml);
                survey.onAfterRenderPage.add(this.checkForAutoAdvance);
                survey.onComplete.add(this.processResult);
                this.setState((prevState) => ({ survey, sId, answerMatrix }));
            }
        }, reason => {
    
        });
    
    }
    
    blackList = [
        'push',
        'shift',
        'unshift',
        'pop',
        'splice',
        'onArrayChanged'
    ];

    processResult = (survey) => {
        const resultData = [];
        for (const key in survey.data) {
            const question = survey.getQuestionByName(key);
            if (question)  {
                const myMap = survey.myAnswerMap[key];
                const type = myMap.type;
                if (type === 'control_textbox' || type === 'text') {
                    const answer = Object.entries(myMap.answers);
                    const item = {
                        questionId: myMap.id,
                        answerId: answer[0][0],
                        value: question.value,
                        type
                    }
                    resultData.push(item);
                } else if (type === 'control_yesno') {
                    const item = {
                        questionId: myMap.id,
                        answerId: question.value,
                        value: '',
                        type
                    }
                    resultData.push(item);
                } else if (type === 'checkbox') {
                    const aValue = Array.isArray(question.value) && question.value.length > 0 ? question.value[0] : question.value;
                    const item = {
                        questionId: myMap.id,
                        answerId: myMap.answers[question.value],
                        value: aValue,
                        type
                    }
                    resultData.push(item);
                } else {
                    const qValue = Object.entries(question.value);
                    if (qValue.length === 0) {
                        const answer = Object.entries(myMap.answers);
                        const item = {
                            questionId: myMap.id,
                            answerId: answer.length === 1 ? answer[0][0] : myMap.answers[question.value],
                            value: question.value,
                            type
                        }
                        resultData.push(item);
                    }
                    else {
                        for (const [id, val] of qValue) {
                            if (this.blackList.indexOf(id) > -1) {
                                continue;
                            }
                            const answer = Object.entries(myMap.answers);
                            let answerId;
                            let value = val;
                            let lookup = val;
                            switch(type) {
                                case 'control_checkbox':
                                    answerId = myMap.answers[answer[0][1]];
                                    break;
                                case 'control_widget':
                                case 'control_radio':
                                    answerId = answer[0][0];
                                    break;
                                case 'control_matrix':
                                    lookup = id;
                                    break;
                            }
                            const item = {
                                questionId: myMap.id,
                                answerId: answer.length === 1 ? answerId : myMap.answers[lookup],
                                value,
                                type
                            }
                            resultData.push(item);
                        }
                    }
                }
            }
        }
    
        const data = {
            guid: survey.myGuid,
            surveyId: survey.mySurveyId,
            results: resultData
        }

        console.log(`Answer Data:`, data);
        this.outputScore(resultData);
    }

    outputScore = (data) => {
        const answerMatrix = this.state.answerMatrix;
        console.log(`Question/Answer Matrix:`, answerMatrix);
        let score = 0;
        let noReverseScore = 0;
        for (const answerData of data) {
            const question = answerMatrix[answerData.questionId];
            const answer = question[answerData.answerId];
            if (question.type === 'control_matrix') {
                const val = answer.matrix[answerData.value];
                if (answer.reverseCoded === true) {
                    score += (6 - val);
                } else {
                    score += val;
                }
                noReverseScore += val;
            } else {
                const val = answer.value;
                if (question.reverseCoded === true) {
                    score += (6 - val);
                } else {
                    score += val;
                }
                noReverseScore += val;
            }
        }
        console.log(`User Score: ${score}`);
        console.log(`No Reverse Code User Score: ${noReverseScore}`);
    }
}

export default SurveyPreview;