import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class Survey extends React.Component {
    state = {
        surveys: null,
        detailId: 0
    }

    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurveys();
        }
    }
    render = () => {
        return (
            <div>
                <div>
                    <h2>Survey Admin</h2>
                </div>
                <div>
                    {this.renderSurveys()}
                    <button><Link to={this.state.detailId}>Details</Link></button>
                    <button><Link to={`/admin/surveyPreview/${this.state.detailId}`}>Preview</Link></button>
                    <button onClick={() => { this.getSurveys() }}>Refresh</button>
                </div>
            </div>
        );
    }

    getSurveys = () => {
        request('surveys/getSurveys').then(res => {
            let detailId = 0;
            let surveys = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                surveys = res.data.rows;
                detailId = res.data.rows[0].id;
            }
            this.setState((prevState) => ({ surveys, detailId }));
        }, reason => {
    
        });
    }

    renderSurveys = () => {
        const surveys = this.state.surveys;
        const toRender = [];
        if (surveys) {
            surveys.forEach(element => {
                const key = `survey-${element.id}`;
                toRender.push(<option key={key} value={element.id}>{element.name}</option>)    
            });
        }
        return (<select onChange={(sender) => { this.setState(() => ({ detailId: sender.target.value } ) ); }} id="survey-select">{toRender}</select>);
    }
} 

export default Survey;