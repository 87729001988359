import React, { useState } from 'react';
import KokoroTable from '../../../Utils/KokoroTable';
import KokoroModal from '../../../Utils/KokoroModal';
import CohortAddStage from './CohortAddStage';
import CohortCreateTemplate from './CohortCreateTemplate';
import CohortEditStage from './CohortEditStage';
import KokoroDialog from '../../../Utils/KokoroDialog';

class CohortEditStages extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        showAddStageModal: false,
        showEditStageModal: false,
        editStageValue: null,
        showRemoveDialog: false,
        removeStageValue: null,
        showCreateTemplateModal: false
    }

    setAddStageModal = (showAddStageModal) => {
        this.setState((prevState) => ({ showAddStageModal }));
    }

    setEditStageModal = (showEditStageModal, editStageValue) => {
        this.setState((prevState) => ({ showEditStageModal, editStageValue }));
    }

    setShowRemoveDialog = (showRemoveDialog, removeStageValue) => {
        this.setState((prevState) => ({ showRemoveDialog, removeStageValue }));
    }

    setCreateTemplateModal = (showCreateTemplateModal) => {
        this.setState((prevState) => ({ showCreateTemplateModal }));
    }

    render = () => {
        this.processStages();
        return (
            <details style={{ borderStyle: 'solid', textAlign: "center", padding: '10px' }}>
                <summary>
                    <h2>
                        Edit Stages
                    </h2>
                    (Click to expand/collapse)
                </summary>
                <div>
                    <h3>
                        Current Stages
                    </h3>
                    <KokoroTable style={{ width: '100%' }}
                        fields={[
                            { header: 'Step', value: 'step' },
                            { header: 'Type', value: 'type' },
                            { header: 'Start', value: 'startDate' },
                            { header: 'Due', value: 'dueDate' },
                            { header: 'Done', value: 'complete' },
                            { header: 'Edit', value: 'edit' },
                            { header: 'Rem', value: 'remove' }
                        ]}
                        data={this.props.data.stages} 
                        tableName="EditStages" />
                    <CohortEditStage editStageValue={this.state.editStageValue} showModal={this.state.showEditStageModal} closeModal={this.closeModal} types={this.props.data.types} cohortUsers={this.props.data.cohortUsers} cohortSurveys={this.props.data.cohortSurveys} cohort={this.props.data.cohort} />
                    <KokoroDialog title="Remove Stage" message="Are you sure you want to delete this?" closeModal={this.removeStage} value={this.state.removeStageValue} />
                </div>
                <div>
                    <button className="cohortAddButton" onClick={() => { this.setAddStageModal(true); }}>Add</button>
                    <CohortAddStage showModal={this.state.showAddStageModal} closeModal={this.closeModal} types={this.props.data.types} cohortUsers={this.props.data.cohortUsers} cohortSurveys={this.props.data.cohortSurveys} cohort={this.props.data.cohort} />
                    <button className="cohortAddButton" disabled={true} onClick={() => { this.setCreateTemplateModal(true); }}>Create Template</button>
                    <CohortCreateTemplate showModal={this.showCreateTemplateModal} closeModal={this.closeModal} />
                </div>
            </details>
        );
    }

    processStages = () => {
        const toReturn = [ ...this.props.data.stages ];
        if (toReturn.length > 0) {
            for (const stage of toReturn) {
                stage.edit = (<button onClick={(sender) => { this.setEditStageModal(true, stage); }}>Edit</button>)
                stage.remove = (<button onClick={(sender) => { this.setShowRemoveDialog(true, stage); }}>X</button>)
                stage.complete = stage.completed === true ? (<div>&#x2705;</div>) : '';
            }
        }
        return toReturn; 
    }

    removeStage = (value, affirmative) => {
        if (!value || affirmative !== true) {
            this.setState((prevState) => ({ removeStageValue: null, showRemoveDialog: false } ));
            return;
        }
        // If we get to here stage exists and answer is true
        // Do remove/put this in a callback
        this.setState((prevState) => ({ removeStageValue: null, showRemoveDialog: false } ));
        // Refresh dataset
    }

    closeModal = (refresh) => {
        this.setEditStageModal(false, undefined);
        this.setShowRemoveDialog(false, undefined);
        this.setAddStageModal(false);
        this.setCreateTemplateModal(false);
        if (refresh === true) {
            this.props.data.refresh();
        }
    }
}

export default CohortEditStages;