import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';

class SurveyMembership extends React.Component {
    once = false;
    state = {
        surveys: null,
        surveyLookup: null,
        nameList: null,
        surveyMemberships: null,
        detailsId: 0
    }
    
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.getSurveys();
        }
    }

    render = () => {       
        return (<div>
            <div>
                <h2>Survey Membership Admin</h2>
                <h3><button onClick={this.getSurveys}>Refresh</button></h3>
            </div>
            <div>
                <h3>Survey Memberships:</h3>
                {this.renderSurveyMembership()}
                <Link to={`${this.state.detailsId}`}>Details</Link>
            </div>
            <div>
                <h3>Add Survey Membership</h3>
                {this.renderSurveys()}
                <input type='text' id="addSurveyName"></input>
                <button onClick={this.addSurveyMembership}>Add</button>
            </div>
        </div>);
    }
    
    addSurveyMembership = () => {
        const name = document.getElementById("addSurveyName");
        const id = document.getElementById("addSurveyId");
        if (this.state.nameList.indexOf(name.value) > -1) {
            alert("Can't add a Survey Membership with a name that exists");
            return;
        }
        if (name.value && id.value) {
            request('surveyMembership/addSurveyMembership', { name: name.value, survey_id: id.value }).then(res => {
                this.getSurveyMemberships();
            }, reason => {
                
            });
        }
    }

    getSurveys = () => {
        request('surveys/getSurveys').then(res => {
            if (res && !res.error) {
                const surveyLookup = {};
                for (let i = 0; i < res.data.rows.length; i++) {
                    const element = res.data.rows[i];
                    surveyLookup[element.id] = element.name;
                }
                this.setState( (prevState) => ( { surveys: res.data.rows, surveyLookup } ) );
                this.getSurveyMemberships();
            } else if (res.error) {
                console.log(res.error);
            }
        });
    }

    renderSurveyMembership = () => {
        const toRender = [];
        if (this.state.surveyMemberships && this.state.surveyMemberships.length > 0) {
            this.state.surveyMemberships.forEach((element) => {
                toRender.push(<option key={element.id} value={element.id}>{element.name} - {this.state.surveyLookup[element.survey_id]}</option>)
            });
        }
        return (
            <select onChange={(sender) => {
                this.setState( (prevState) => ( { detailsId: sender.target.value }))
            }}>
                {toRender}
            </select>
        );
    }

    getSurveyMemberships = () => {
        request('surveyMembership/getSurveyMemberships').then(res => {
            let surveyMemberships = [];
            let detailsId = 0;
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                surveyMemberships = res.data.rows;
            }
            const nameList = [];
            for (let i = 0; i < surveyMemberships.length; i++) {
                const element = surveyMemberships[i];
                if (element.is_active && detailsId === 0) {
                    detailsId = element.id;
                }
                nameList.push(element.name);
            }
            this.setState( (prevState) => ({ nameList, surveyMemberships, detailsId }));
        }, reason => {
    
        });
    }

    renderSurveys = () => {
        const toRender = [];
        if (this.state.surveys) {
            this.state.surveys.forEach((element) => {
                toRender.push(<option key={element.id} value={element.id}>{element.name}</option>);
            });
        }
        return (
            <select id="addSurveyId">
                {toRender}
            </select>
        )
    }
}

export default SurveyMembership;