import React from 'react';

const CohortStages = (props) => {
    return (
        <div style={{ borderStyle: 'solid', marginBottom: '30px' }}>
            <h3>Timeline</h3>
            (Click a Stage for additional details)
            <div style={{ padding: '50px', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', textAlign: 'center' }}>
                {renderStages(props.stages, props.toggleEditStage)}
            </div>
        </div>
    );
}

const renderStages = (stages, toggleEditStage) => {
    const toRender = [];
    if (stages) {
        for(const stage of stages) {
            const date = `${stage.startDate} - ${stage.dueDate}`;
            const nudge = stage.nudge ? `Nudges On: ${stage.nudge}` : '';
            const color = stage.completed === true ? 'gray' : stage.color;
            toRender.push(
                <div key={`TimeLine-${stage.id}`} style={{ width: '140px', textAlign: 'center' }} onClick={
                    (sender) => {
                       toggleEditStage(true, stage);
                    }
                }>
                    <div className="schStg" style={{ color }}>{stage.type}</div>
                    <div style={{ margin: 'auto', marginTop: '10px', marginBottom: '20px', borderRadius: '75px', background: 'white', height: '75px', width: '75px', borderStyle: 'solid', borderWidth: '3px', borderColor: color }}>
                        <div style={{ height: '12.5px', width: '1px' }}></div>
                        <div style={{ margin: 'auto', borderRadius: '50px', background: color, height: '50px', width: '50px', color: 'white' }}>
                            <div style={{ paddingTop: '15px'}}>{stage.step}</div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <div className="schStg">{date}</div>
                        <div className="schStg">{nudge}</div>
                    </div>
                </div>
            );
        }
    }
    return toRender;
}

export default CohortStages;