import React, { useState } from 'react';
import darkImage from '../images/dark-mode.png';
import lightImage from '../images/light-mode.png';
import { isAuthorized, setUser } from '../utils/requests';
import { logIn, logOut, GoogleAuthProvider, isLoggedIn } from './Firebase';

const Menu = (props) => {
    const [loggedIn, setLoggedIn ] = useState(isLoggedIn());
    return (
        <div className='optionMenu' style={{ backgroundColor: '#be8de0', width: '125px', padding: '15px' }}>
            { (loggedIn !== true &&
            (<div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'right', fontSize: '14px', lineHeight: '15px' }} onClick={() => {
                logIn().then((result) => {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const user = result.user;
                    setUser(user);
                    setLoggedIn(isLoggedIn());
                    isAuthorized(props.setIsAuthorized);
                }).catch((error) => {
                    console.log(error);
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const email = error.customData.email;
                    const credential = GoogleAuthProvider.credentialFromError(error);
                });;
            }}>Login</div>))
            || (<div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'right', fontSize: '14px', lineHeight: '15px' }} onClick={async () => { await logOut(); window.location.reload(); isAuthorized(props.setIsAuthorized); }}>Log Out</div>) }
            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'right', fontSize: '11px', lineHeight: '15px' }} onClick={ () => { props.toggleDarkMode() } }>
                DARK MODE {props.darkMode ? "ON" : "OFF" }<img alt="darkmode" src={props.darkMode ? darkImage : lightImage} style={{ maxWidth: '15px' }} />
            </div>
        </div>
    );
}

export default Menu;