import React from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import KokoroTable from '../../Utils/KokoroTable';
import CohortAddModal from './CohortAddModal';

class Cohorts extends React.Component {
    state = {
        cohorts: [],
        organizations: [],
        showAddModal: false,
        showDetailsModal: false,
        detailsModalValue: null,
        showDialog: false,
        statusChangeValue: null
    }
    
    once = false;
    componentDidMount = () => {
        if (!this.once) {
            this.once = true;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getOrganizations();
    }

    getOrganizations = () => {
        request('getOrganizations').then(res => {
            const organizations = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            this.getCohorts(organizations);
        }, reason => {
    
        });
    }

    getCohorts = (organizations) => {
        request('getCohorts').then(res => {
            const cohorts = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const cohort of res.data.rows) {
                    cohort.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: cohort } ) ) } }>{cohort.is_active === true ? 'Deactivate' : 'Reactivate'}</button>);
                    cohort.stage_count = cohort.stage_count ? cohort.stage_count : 0;
                    cohort.user_count = cohort.user_count ? cohort.user_count : 0;
                    cohort.next_stage = cohort.next_stage ? cohort.next_stage : 'N/A';
                    cohort.stage_start = cohort.stage_start ? cohort.stage_start : 'N/A';
                    cohort.stage_end = cohort.stage_end ? cohort.stage_end : 'N/A';
                    cohort.details = (<button><Link to={`../admin/cohort/details/${cohort.id}`}>Details</Link></button>)
                    cohort.report = (<button><Link to={`../admin/cohort/report/${cohort.id}`}>Report</Link></button>)
                    cohort.status = cohort.is_active === true ? 'Active' : 'Inactive';
                    cohorts.push(cohort);
                }
            }
            this.setState((prevState) => ( { cohorts, organizations, showAddModal: false, showDialog: false, statusChangeValue: null, showDetailsModal: false, detailsModalValue: null } ) );
        }, reason => {
    
        });
    }

    render = () => {
        return (
            <div style={{ padding: '2vw' }}>
                <h2>Cohort List</h2>
                <button onClick={() => { this.setState((prevState) => ( { showAddModal: true } ) ); }}>Add New Cohort</button>
                <div>
                    <KokoroTable style={{ width: '100%' }} fields={[ { header: 'Name', value: 'name', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Org', value: 'organization', filterable: true, sortable: true, dataTextAlign: 'left' }, { header: 'Stage Count', value: 'stage_count' }, { header: 'User Count', value: 'user_count' }, { header: 'Next Stage', value: 'next_stage' }, { header: 'Next Stage Start', value: 'stage_start' }, { header: 'Next Stage End', value: 'stage_end' }, { header: 'Details Link', value: 'details' }, { header: 'Report Link', value: 'report' }, { header: 'Status', value: 'status', filterable: true, filterType: 'dropdown' }, { header: 'Modify Status', value: 'modify_status' } ]} data={this.state.cohorts} maxItems={10} tableName="Cohorts" />
                </div>
                <CohortAddModal showModal={this.state.showAddModal} closeModal={this.handleAddModal} organizations={this.state.organizations} />
                <KokoroDialog title="Status Change" message="Are you sure you want to change the Cohort Status?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
            </div>
        );
    }

    handleAddModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showAddModal: false } ) );
        }
    }
    

    handleDetailsModal = (reload) => {
        if (reload === true) {
            this.initialRequest();
        } else {
            this.setState((prevState) => ( { showDetailsModal: false, detailsModalValue: null } ) );
        }
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('updateCohort', { id: value.id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }
}

export default Cohorts;