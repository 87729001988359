import React, { useState } from 'react';
import { request } from '../../../../utils/requests';
import KokoroModal from '../../../Utils/KokoroModal';
import KokoroTable from '../../../Utils/KokoroTable';

const CohortEditUsers = (props) => {
    const [orgFilter, setOrgFilter] = useState('-1');
    const [pendingUsers, setPendingUsers] = useState({});
    const [error, setError] = useState(undefined);
        
    return (
        <div>
            { props.showModal === true && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Edit Cohort Users</h3>
                        {
                            error && (
                                <h4 style={{ color: 'red' }}>Error: {error}</h4>
                            )
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                            <div style={{ width: '42%', paddingLeft: '3%', paddingRight: '3%' }}>
                                <h4>Available Users</h4>
                                { true && (
                                <div>
                                    <label>Organization Filter:</label>
                                    <select value={orgFilter} onChange={(sender) => { sender.preventDefault(); setOrgFilter(sender.target.value); } }>
                                        <option value="-1">No Org</option>
                                        { generateOrganizationFilter(props.organizations) }
                                    </select>
                                </div> ) }
                            </div>
                        </div>
                        
                        <div style={{ padding: '30px'  }}>
                            <fieldset>
                            <legend style={{ textAlign: 'left' }}>Available Users</legend>
                                <KokoroTable style={{ width: '100%' }} 
                                    fields={[ 
                                        { header: 'Id', value: 'id' },
                                        { header: 'First Name', value: 'first_name', filterable: true, sortable: true },
                                        { header: 'Last Name', value: 'last_name', filterable: true, sortable: true },
                                        { header: 'Email', value: 'email', filterable: true, sortable: true },
                                        { header: 'Company', value: 'company', filterable: true, sortable: true },
                                        { header: 'Add', value: 'add' } ]}
                                        data={ filterUsers(props.users, props.cohortUserLookup, pendingUsers, orgFilter, setPendingUsers) }
                                        tableName="AvailableUsers" />
                            </fieldset>
                            <fieldset>
                            <legend style={{ textAlign: 'left' }}>Pending Users</legend>
                                <KokoroTable style={{ width: '100%' }} 
                                    fields={[
                                        { header: 'Id', value: 'id' },
                                        { header: 'First Name', value: 'first_name', filterable: true, sortable: true }, 
                                        { header: 'Last Name', value: 'last_name', filterable: true, sortable: true }, 
                                        { header: 'Email', value: 'email', filterable: true, sortable: true }, 
                                        { header: 'Company', value: 'company', filterable: true, sortable: true }, 
                                        { header: 'Org', value: 'org', filterable: true, sortable: true }, 
                                        { header: 'Remove', value: 'remove' }
                                    ]} 
                                    data={getPendingArray(pendingUsers)}
                                    tableName="PendingUsers" />
                            </fieldset>
                            <div>
                                <button onClick={
                                    (sender) => {
                                        sender.target.disabled = true;
                                        const entries = Object.entries(pendingUsers);
                                        const toAdd = [];
                                        for (let i = 0; i < entries.length; i++) {
                                            const entry = entries[i][1];
                                            const pendingUser = { user_id: entry.id, cohort_id: props.cohort.id };
                                            if (entry.org_id) {
                                                pendingUser.organization_id = entry.org_id
                                            }
                                            toAdd.push(pendingUser);
                                        }
                                        
                                        if (toAdd.length > 0) {
                                            request('addCohortUsers', { users: toAdd }).then(res => {
                                                if (res.data.successful === true) {
                                                    setError(undefined);
                                                    setOrgFilter(-1);
                                                    setPendingUsers({});
                                                    props.closeModal(true);
                                                    sender.target.disabled = false;
                                                } else {
                                                    setError("Failed to Add Users, Consult Engineering Team");
                                                    sender.target.disabled = false;
                                                }
                                            });
                                        }
                                    }
                                }>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    )
}

const generateOrganizationFilter = (organizations) => {
    const toRender = [];
    if (organizations) {
        for (let i = 0; i < organizations.length; i++) {
            const organization = organizations[i];
            toRender.push(<option value={`${organization.name}-----${organization.id}`} key={`CohortEditUser-Org-${organization.id}`}>{organization.name}</option>)
        }
    }
    return toRender;
}

const filterUsers = (users, cohortUserLookup, pendingUsers, orgFilter, setPendingUsers) => {
    const orgSplit = orgFilter.split('-----');
    const org = orgSplit.length === 1 ? undefined : orgSplit[0];
    const org_id = orgSplit.length === 1 ? undefined : parseInt(orgSplit[1]);
    const filteredUsers = [];
    if (users) {
        for(let i = 0; i < users.length; i++) {
            const user = users[i];
            user.add = (<button key={`CohortEditUser-Add-${user.id}`} onClick={() => { const newPendingUsers = { ...pendingUsers }; newPendingUsers[user.id] = user; user.org_id = org_id; user.org = org; setPendingUsers(newPendingUsers); }}>Add</button>);
            user.remove = (<button key={`CohortEditUser-Remove-${user.id}`} onClick={() => { const newPendingUsers = { ...pendingUsers }; delete newPendingUsers[user.id]; setPendingUsers(newPendingUsers); }}>Remove</button>);
            if ((!cohortUserLookup || !cohortUserLookup[user.id]) && (!pendingUsers || !pendingUsers[user.id]) && (!org_id || (user.organization_list && user.organization_list.indexOf(org_id) > -1))) {
                filteredUsers.push(user);
            }
        }
    }
    return filteredUsers;
}

const getPendingArray = (pendingUsers) => {
    const pending = [];
    if (pendingUsers) {
        const entries = Object.entries(pendingUsers);
        for (let i = 0; i < entries.length; i++) {
            const pendingUser = entries[i][1];
            pending.push(pendingUser);
        }
    }
    return pending;
}

export default CohortEditUsers;