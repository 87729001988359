import React from 'react';
import KokoroModal from './KokoroModal';

const KokoroDialog = (props) => {
    return (
        <div>
            { props.showDialog && (
                <KokoroModal styles={{ backgroundColor: 'rgba(66, 66, 66, .5)', marginTop: '25vh' }} hideX={true} closeModal={props.closeModal}>
                    <div style={{ width: '40%', height: '10vh', margin: 'auto', border: '1px solid pink', backgroundColor: 'white', opacity: 1 }}>
                        <h3>{props.title}</h3>
                        <div>{props.message}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '60%', margin: 'auto', paddingTop: '20px' }}>
                            <button onClick={(sender) => { props.closeModal(props.value, true)}}>
                                Yes
                            </button>
                            <button onClick={(sender) => { props.closeModal(props.value, false)}}>
                                No
                            </button>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    )
}

export default KokoroDialog;