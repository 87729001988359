import React, { useState } from 'react';
import { request } from '../../../../utils/requests';
import KokoroDialog from '../../../Utils/KokoroDialog';
import KokoroModal from '../../../Utils/KokoroModal';
import KokoroTable from '../../../Utils/KokoroTable';

class StageTypeDetailsModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        name: '',
        assignedSurveys: {},
        pendingSurveys: {},
        edit: false,
        showDialog: false,
        statusChangeValue: null
    }

    once = undefined;
    componentDidMount = () => {
        if (this.props.value && (!this.once || this.once !== this.props.value.id)) {
            this.once = this.props.value.id;
            this.initialRequest();
        }
    }

    componentDidUpdate = () => {
        if (this.props.value && (!this.once || this.once != this.props.value.id)) {
            this.once = this.props.value.id;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getAssignedSurveys(this.props.value.id);
    }

    getAssignedSurveys = (id) => {
        request(`questionCategories/getAssigned/${id}`).then(res => {
            const surveys = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
            const assignedSurveys = {};
            for (const assignedSurvey of surveys) {
                assignedSurvey.status = assignedSurvey.is_active === true ? 'Active' : 'Inactive';
                assignedSurvey.modify_status = (<button onClick={(sender) => { this.setState((prevState) => ( { showDialog: true, statusChangeValue: assignedSurvey } ) ); } }>{assignedSurvey.is_active === true ? 'Deactivate' : 'Reactivate'}</button>)
                assignedSurveys[assignedSurvey.survey_id] = assignedSurvey;
            }
            this.setState((prevState) => ( { assignedSurveys, pendingSurvey: {}, showDialog: false, statusChangeValue: null } ) );
        }, reason => {

        });
    }

    handleCloseStatusDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request(this.updateStatusApi, { id: value.map_id, is_active: !value.is_active }).then(res => {
                this.initialRequest();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }

    updateApi = 'updateQuestionCategory';
    updateStatusApi = 'questionCategories/updateMap';

    render = () => {
        const nameInputValue = this.state.name.trim() === '' && this.props.value && this.props.value.name ? this.props.value.name : this.state.name;
        const editText = this.state.edit === true ? 'Cancel' : 'Edit';
        const canEdit = this.props.value ? this.props.value.id !== 1 : false;
        return (
            <div>
                {
                    (this.props.showModal && this.props.value) && (
                        <KokoroModal closeModal={this.props.closeModal}>
                            <div>
                                <h3>Question Category Details</h3>
                                <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Name</legend>
                                        { (this.state.edit === true && canEdit) && (
                                            <div>
                                                <label>Name:</label>
                                                <input type="text" value={nameInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        this.setState((prevState) => ( { name: sender.target.value } ) );
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Name: </label>
                                                <span>{this.props.value.name}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                    { (this.state.edit === true && canEdit === true) && (
                                            <div style={{ width: '100%' }}>
                                                <fieldset style={{  textAlign: 'center', padding: '.5vw' }}>
                                                    <legend style={{ textAlign: 'left' }}>Available Surveys</legend>
                                                    <KokoroTable style={{ width: '100%' }} 
                                                        fields={[ 
                                                            { header: 'Survey', value: 'display_name', filterable: true, sortable: true },
                                                            { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                                            { header: 'Add', value: 'add' } ]}
                                                            data={ this.filterSurveys(this.props.surveys, this.state.assignedSurveys, this.state.pendingSurveys) }
                                                            tableName="AvailableUsers" />
                                                </fieldset>
                                                <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                                    <legend style={{ textAlign: 'left' }}>Pending Surveys</legend>
                                                    <KokoroTable style={{ width: '100%' }} 
                                                        fields={[ 
                                                            { header: 'Survey', value: 'display_name', filterable: true, sortable: true },
                                                            { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                                            { header: 'Remove', value: 'remove' }
                                                        ]} 
                                                        data={this.getPendingArray(this.state.pendingSurveys)}
                                                        tableName="PendingUsers" />
                                                </fieldset>
                                            </div>
                                        ) || (
                                            <div style={{ width: '100%' }}>
                                                <fieldset style={{ textAlign: 'center', padding: '.5vw', margin: 'auto' }}>
                                                    <legend style={{ textAlign: 'left' }}>Active Surveys</legend>
                                                    <KokoroTable style={{ width: '100%' }} 
                                                        fields={[ 
                                                            { header: 'Survey', value: 'survey', filterable: true, sortable: true },
                                                            { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                                            { header: 'Status', value: 'status' },
                                                            { header: 'Modify Status', value: 'modify_status' }
                                                         ]}
                                                            data={ this.getAssignedSurveysByStatus(this.state.assignedSurveys, true) }
                                                            tableName="AvailableUsers" />
                                                </fieldset>
                                                <fieldset style={{ textAlign: 'center', padding: '.5vw', margin: 'auto'  }}>
                                                    <legend style={{ textAlign: 'left' }}>Inactive Surveys</legend>
                                                    <KokoroTable style={{ width: '100%' }} 
                                                        fields={[ 
                                                            { header: 'Survey', value: 'survey', filterable: true, sortable: true },
                                                            { header: 'Survey Category', value: 'category', filterable: true, sortable: true },
                                                            { header: 'Status', value: 'status' },
                                                            { header: 'Modify Status', value: 'modify_status' }
                                                        ]} 
                                                        data={ this.getAssignedSurveysByStatus(this.state.assignedSurveys, false) }
                                                        tableName="PendingUsers" />
                                                </fieldset>
                                        </div>
                                    )}
                                </div>
                                { 
                                    canEdit === true && (
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                            { (this.state.edit === true && canEdit) && (
                                                <button onClick={
                                                    async (sender) => {
                                                        const updates = {};
                                                        if (this.state.name !== '' && this.state.name !== this.props.value.name) {
                                                            updates.name = this.state.name;
                                                        }
                                                        const surveyMap = this.getIdArray(this.state.pendingSurveys);
                                                        if (surveyMap.length > 0) {
                                                            updates["surveyMap"] = surveyMap;
                                                        }
                                                        
                                                        const hasUpdates = Object.entries(updates).length > 0;
                                                        if (hasUpdates) {
                                                            request(this.updateApi, { id: this.props.value.id, ...updates }).then(res => {
                                                                this.setState((prevState) => ( { edit: false, name: '', pendingSurveys: {} } ) );
                                                                this.initialRequest();
                                                                if (updates.name) {
                                                                    this.props.closeModal(true);
                                                                }
                                                            });
                                                        }
                                                    } }
                                                >
                                                    Save
                                                </button>
                                            ) }
                                            <button onClick={
                                                (sender) => {
                                                    this.setState((prevState) => ( { edit: !prevState.edit, name: '' } ) );
                                                } }
                                            >
                                                {editText}
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                            <KokoroDialog title="Status Change" message="Are you sure you want to change the Status for this Survey Status?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleCloseStatusDialog} />
                        </KokoroModal>
                    )
                }
            </div>
        );
    }

    getIdArray = (pendingSurveys) => {
        const pendingArray = [];
        const entries = Object.entries(pendingSurveys);
        for (let i = 0; i < entries.length; i++) {
            const pendingSurvey = entries[i][1];
            pendingArray.push(pendingSurvey.id);
        }
        return pendingArray;
    }

    getPendingArray = (pendingSurveys) => {
        const pendingArray = [];
        const entries = Object.entries(pendingSurveys);
        for (let i = 0; i < entries.length; i++) {
            const pendingSurvey = entries[i][1];
            pendingArray.push(pendingSurvey);
        }
        return pendingArray;
    }

    getAssignedSurveysByStatus = (assignedSurveys, status) => {
        const assignedSurveyArray = [];
        if (assignedSurveys) {
            const entries = Object.entries(assignedSurveys);
            for (let i = 0; i < entries.length; i++) {
                const assignedSurvey = entries[i][1];
                if (assignedSurvey.is_active === status) {
                    assignedSurveyArray.push(assignedSurvey);
                }
            }
        }
        return assignedSurveyArray;
    }

    filterSurveys = (surveys, assignedSurveys, pendingSurveys) => {
        const filteredSurveys = [];
        if (surveys) {
            for (let i = 0; i < surveys.length; i++) {
                const survey = surveys[i];
                survey.add = (<button onClick={(sender) => { this.setState((prevState) => { const newState = { ...prevState.pendingSurveys }; newState[survey.id] = survey; return { pendingSurveys: newState }; } ) } }>Add</button>);
                survey.remove = (<button onClick={(sender) => { this.setState((prevState) => { const newState = { ...prevState.pendingSurveys }; delete newState[survey.id]; return { pendingSurveys: newState }; } ) } }>Remove</button>);
                if ((Object.entries(assignedSurveys).length === 0 || !assignedSurveys[survey.id]) && (Object.entries(pendingSurveys).length === 0 || !pendingSurveys[survey.id])) {
                    filteredSurveys.push(survey);
                }
            }
        }
        return filteredSurveys
    }
}

export default StageTypeDetailsModal;
