import React, { useState } from 'react';
import { request } from '../../../utils/requests';
import KokoroModal from '../../Utils/KokoroModal';
import KokoroTable from '../../Utils/KokoroTable';

class SurveyCategoryDetailsModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        edit: false,
        name: '',
        surveys: [],
        assignedSurveys: {},
        pendingSurveys: {},
        showDialog: false,
        statusChangeValue: null
    }

    systemName = 'Survey Category';
    updateApi = 'surveyCategory/update';

    once = -1;
    componentDidMount = () => {
        if (this.props.value && this.props.showModal === true && this.once !== this.props.value.id) {
            this.once = this.props.value.id;
            this.initialRequest();
        }
    }

    componentDidUpdate = () => {
        if (this.props.value && this.props.showModal === true && this.once !== this.props.value.id) {
            this.once = this.props.value.id;
            this.initialRequest();
        }
    }

    initialRequest = () => {
        this.getSurveys();
    }

    getSurveys = () => {
        request('surveys/getSurveys').then(res => {
            const surveys = [];
            const assignedSurveys = {};
            const pendingSurveys = {};
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const survey of res.data.rows) {
                    
                    if (survey.category_id === this.props.value.id) {
                        assignedSurveys[survey.id] = survey;
                    }
                    survey.add = (<button onClick={(sender) => { this.setState( (prevState) => { const updates = { ...prevState.pendingSurveys }; updates[survey.id] = survey; return { pendingSurveys: updates } } ) } }>Add</button>);
                    survey.remove = (<button onClick={(sender) => { this.setState( (prevState) => { const updates = { ...prevState.pendingSurveys }; delete updates[survey.id]; return { pendingSurveys: updates } } ) } }>Remove</button>);
                    surveys.push(survey);
                }
            }
            this.setState((prevState) => ({ surveys, assignedSurveys, pendingSurveys, edit: false, name: '' }));
        }, reason => {
    
        });
    }

    filterSurveys = (surveys, assignedSurveys, pendingSurveys) => {
        const filteredSurveys = [];
        for (let i = 0; i < surveys.length; i++) {
            const survey = surveys[i];
            if (!assignedSurveys[survey.id] && !pendingSurveys[survey.id]) {
                filteredSurveys.push(survey);
            }
        }
        return filteredSurveys;
    }

    getSurveyArray = (surveyObjects) => {
        const surveys = [];
        if (surveyObjects) {
            const entries = Object.entries(surveyObjects);
            for (let i = 0; i < entries.length; i++) {
                surveys.push(entries[i][1]);
            }
        }
        return surveys;
    }

    render = () => {
        const nameInputValue = this.state.name.trim() === '' && this.props.value && this.props.value.name ? this.props.value.name : this.state.name;
        const editText = this.state.edit === true ? 'Cancel' : 'Edit';
        const canEdit = true;
        return (
            <div>
                {
                    (this.props.showModal && this.props.value) && (
                        <KokoroModal closeModal={this.props.closeModal}>
                            <div>
                                <div>
                                    <details>
                                        <div>
                                            <label>Id:</label>
                                            <span>{this.props.value.id}</span>
                                        </div>
                                        <div>
                                            <label>UUID:</label>
                                            <span>{this.props.value.uuid}</span>
                                        </div>
                                        <summary>
                                            <h3>{this.props.value.name} {this.systemName} Details</h3>
                                            (Click to expand for more info)
                                        </summary>
                                    </details>
                                </div>
                                <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Name</legend>
                                        { (this.state.edit === true && canEdit) && (
                                            <div>
                                                <label>Name:</label>
                                                <input type="text" value={nameInputValue} onChange={
                                                    (sender) => {
                                                        sender.preventDefault();
                                                        this.setState((prevState) => ( { name: sender.target.value } ) );
                                                    } }
                                                />
                                            </div>
                                        ) || (
                                            <div>
                                                <label>Name: </label>
                                                <span>{this.props.value.name}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                    { (this.state.edit === true && canEdit === true) && (
                                        <div style={{ width: '100%' }}>
                                            <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                                <legend style={{ textAlign: 'left' }}>Available Surveys</legend>
                                                <div>
                                                    <KokoroTable fields={ [ { header: 'Display Name', value: 'display_name', filterable: true, sortable: true }, { header: 'Survey System Name', value: 'name', filterable: true, sortable: true }, { header: 'Current Category', value: 'category', filterable: true, sortable: true }, { header: 'Add', value: 'add' } ] } data={this.filterSurveys(this.state.surveys, this.state.assignedSurveys, this.state.pendingSurveys)} tableName="AvailableSurveys" />
                                                </div>
                                            </fieldset>
                                            <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                                <legend style={{ textAlign: 'left' }}>Pending Surveys</legend>
                                                <div>
                                                    <KokoroTable fields={ [ { header: 'Display Name', value: 'display_name', filterable: true, sortable: true }, { header: 'Survey System Name', value: 'name', filterable: true, sortable: true }, { header: 'Current Category', value: 'category', filterable: true, sortable: true }, { header: 'Remove', value: 'remove' } ] } data={this.getSurveyArray(this.state.pendingSurveys)} tableName="PendingSurveys" />
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) || (
                                        <fieldset style={{ width: '60%', textAlign: 'center', padding: '.5vw' }}>
                                            <legend style={{ textAlign: 'left' }}>Assigned Surveys</legend>
                                            <div>
                                                <KokoroTable fields={ [ { header: 'Display Name', value: 'display_name', filterable: true, sortable: true }, { header: 'Survey System Name', value: 'name', filterable: true, sortable: true } ] } data={this.getSurveyArray(this.state.assignedSurveys)} tableName="Surveys" />
                                            </div>
                                        </fieldset>
                                    ) }
                                </div>
                                { 
                                    canEdit === true && (
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                            { (this.state.edit === true && canEdit) && (
                                                <button onClick={
                                                    async (sender) => {
                                                        const pendingSurveys = Object.entries(this.state.pendingSurveys);
                                                        let addedSurveys = false;
                                                        if (pendingSurveys.length > 0) {
                                                            addedSurveys = true;
                                                            for (let i = 0; i < pendingSurveys.length; i++) {
                                                                const newSurvey = pendingSurveys[i][1];
                                                                try {
                                                                    await request('surveys/update', { id: newSurvey.id, category_id: this.props.value.id });
                                                                } catch (error) {
                                                                    console.error(error);
                                                                }
                                                                
                                                            }
                                                        }

                                                        const updates = {};
                                                        if (this.state.name !== '' && this.state.name !== this.props.value.name) {
                                                            updates.name = this.state.name;
                                                        }
                                                        
                                                        const hasUpdates = Object.entries(updates).length > 0;
                                                        if (hasUpdates) {
                                                            request(this.updateApi, { id: this.props.value.id, ...updates }).then(res => {
                                                                this.setState((prevState) => ( { edit: false, name: '', pendingSurveys: {} } ) );
                                                                this.props.closeModal(true);
                                                            });
                                                        }

                                                        if (addedSurveys) {
                                                            this.initialRequest();
                                                        }
                                                    } }
                                                >
                                                    Save
                                                </button>
                                            ) }
                                            <button onClick={
                                                (sender) => {
                                                    this.setState((prevState) => ( { edit: !prevState.edit, name: '', pendingSurveys: {} } ) );
                                                } }
                                            >
                                                {editText}
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </KokoroModal>
                    )
                }
            </div>
        );
    }
}

export default SurveyCategoryDetailsModal;