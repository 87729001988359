import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';


const PartnerAddModal = (props) => {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [contact_name, setContactName] = useState('');
    const [contact_email, setContactEmail] = useState('');
    const [contact_number, setContactNumber] = useState('');
    const [error, setError] = useState();
    return (
        <div>
            { props.showAddModal && (
                <KokoroModal closeModal={props.handleAddModal}>
                    <div>
                        <h3>Add Company</h3>
                        {
                            error && (
                                <h4 style={{ color: 'red' }}>Error: {error}</h4>
                            )
                        }
                        <div>
                            <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Partner Name</legend>
                                    <input type="text" value={name} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setName(sender.target.value);
                                        } }
                                    />
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Notes</legend>
                                    <textarea value={notes} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setNotes(sender.target.value);
                                        } } 
                                    />
                                </fieldset>
                                <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                    <legend style={{ textAlign: 'left' }}>Contact Info</legend>
                                    <div>
                                        <label>Contact Name:</label>
                                        <input type="text" value={contact_name} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setContactName(sender.target.value);
                                            } } 
                                        />
                                    </div>
                                    <div>
                                        <label>Contact Email:</label>
                                        <input type="text" value={contact_email} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setContactEmail(sender.target.value);
                                            } } 
                                        />
                                    </div>
                                    <div>
                                        <label>Contact Number:</label>
                                        <input type="text" value={contact_number} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setContactNumber(sender.target.value);
                                            } } 
                                        />
                                    </div>
                                </fieldset>
                            </div>
                            <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', padding: '1vw', margin: 'auto', marginTop: '2vh' }}>
                                <button onClick={
                                    (sender) => {
                                        sender.target.disabled = true;
                                        // Validate fields
                                        if (!name || name.trim() === '') {
                                            setError("User Must Set a Partner Name");
                                            sender.target.disabled = false;
                                            return;
                                        }
                                        if (!contact_email || contact_email.trim() === '' || !contact_email.match('^[\\w-.]+@([\\w-]+\.)+[\\w-]{2,4}$')) {
                                            setError("User Must Set a Valid Contact Email");
                                            sender.target.disabled = false;
                                            return;
                                        }
                                        if (!contact_name || contact_name.trim() === '') {
                                            setError("User Must Set a Contact Name");
                                            sender.target.disabled = false;
                                            return;
                                        }

                                        if (!contact_number || contact_number.trim() === '') {
                                            setError("User Must Set a Contact Number");
                                            sender.target.disabled = false;
                                            return;
                                        }
                                        const partner = { name, contact_email, contact_name, contact_number, notes: notes && notes.trim() !== '' ? notes : undefined }
                                        // Request Add
                                        request('addPartner', partner).then(res => {
                                            if (res.data.successful === true) {
                                                setName('');
                                                setContactEmail('');
                                                setContactName('');
                                                setNotes('');
                                                setContactNumber('');
                                                setError();
                                                sender.target.disabled = false;
                                                props.handleAddModal(true);
                                            } else {
                                                setError("Failed to Add Company, Possible Duplicate");
                                                sender.target.disabled = false;
                                            }
                                        });
                                    } }
                                >
                                    Save
                                </button>
                                <button onClick={(sender) => { props.handleAddModal(); } }>Cancel</button>
                            </div>
                        </div>
                    </div>
                </KokoroModal>
            )}
        </div>
    );
}

export default PartnerAddModal;