import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';


const UserAddModal = (props) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [alt_email, setAltEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [alt_phone, setAltPhone] = useState('');
    const [text_permission, setTextPermission] = useState(false);
    const [prefers_text, setPrefersText] = useState(false);
    const [company_id, setCompany] = useState(1);
    const [error, setError] = useState();
    return (
        <div>
            { props.showModal && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>Add User</h3>
                        {
                            error && (
                                <h4 style={{ color: 'red' }}>Error: {error}</h4>
                            )
                        }
                        <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Name</legend>
                                <div>
                                    <label>First Name:</label>
                                    <input type="text" value={first_name} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setFirstName(sender.target.value);
                                        } }
                                    />
                                </div>
                                <div>
                                    <label>Last Name:</label>
                                    <input type="text" value={last_name} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setLastName(sender.target.value);
                                        } }
                                    />
                                </div>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Phone</legend>
                                <div>
                                    <label>Phone:</label>
                                    <input type="text" value={phone} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setPhone(sender.target.value);
                                        } }
                                    />
                                </div>
                                <div>
                                    <label>Alt Phone:</label>
                                    <input type="text" value={alt_phone} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setAltPhone(sender.target.value);
                                        } }
                                    />
                                </div>
                                <div>
                                    <label>Text Permission:</label>
                                    <input type="checkbox" checked={text_permission} onChange={
                                        (sender) => {
                                            setTextPermission(sender.target.checked);
                                        } }
                                    />
                                </div>
                                <div>
                                    <label>Prefers Text:</label>
                                    <input type="checkbox" checked={prefers_text} onChange={
                                        (sender) => {
                                            setPrefersText(sender.target.checked);
                                        } }
                                    />
                                </div>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Email</legend>
                                    <div>
                                        <label>Email:</label>
                                        <input type="text" value={email} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setEmail(sender.target.value);
                                            } }
                                        />
                                    </div>
                                
                                    <div>
                                        <label>Alt Email:</label>
                                        <input type="text" value={alt_email} onChange={
                                            (sender) => {
                                                sender.preventDefault();
                                                setAltEmail(sender.target.value);
                                            } }
                                        />
                                    </div>
                            </fieldset>
                            <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                <legend style={{ textAlign: 'left' }}>Company</legend>
                                <div>
                                    <label>Company:</label>
                                    <select value={company_id} onChange={
                                        (sender) => {
                                            sender.preventDefault();
                                            setCompany(parseInt(sender.target.value));
                                        } }
                                    >
                                        {getCompanyOptions(props.companies)}
                                    </select>
                                </div>
                            </fieldset>
                        </div>
                        <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', padding: '1vw', margin: 'auto', marginTop: '2vh' }}>
                            <button onClick={
                                (sender) => {
                                    sender.target.disabled = true;
                                    // Validate fields
                                    if (!first_name || first_name.trim() === '') {
                                        setError("User Must Set a First Name");
                                        sender.target.disabled = false;
                                        return;
                                    }
                                    if (!last_name || last_name.trim() === '') {
                                        setError("User Must Set a Last Name");
                                        sender.target.disabled = false;
                                        return;
                                    }
                                    if (!email || email.trim() === '' || !email.match('^[\\w-.]+@([\\w-]+\.)+[\\w-]{2,4}$')) {
                                        setError("User Must Set a Valid Contact Email");
                                        sender.target.disabled = false;
                                        return;
                                    }
                                    // Optional
                                    if (alt_email && !alt_email.match('^[\\w-.]+@([\\w-]+\.)+[\\w-]{2,4}$')) {
                                        setError("Alt Email must be valid if it is set");
                                        sender.target.disabled = false;
                                        return;
                                    }

                                    if (!phone || phone.trim() === '') {
                                        setError("User Must Set a Phone Number");
                                        sender.target.disabled = false;
                                        return;
                                    }

                                    // Required
                                    // Add first_name
                                    // Add last_name
                                    // Add email
                                    // Add Phone
                                    // Add Company

                                    // Optional
                                    // Add alt_email
                                    // Add alt_phone
                                    // Add text_permission
                                    // Add prefers_text

                                    const user = { first_name, last_name, email, phone, company_id, text_permission, prefers_text };
                                    if (alt_email) {
                                        user.alt_email = alt_email;
                                    }

                                    if (alt_phone && alt_phone.trim() !== '') {
                                        user.alt_phone = alt_phone;
                                    }

                                    // Request Add
                                    request('addUser', user).then(res => {
                                        if (res.data.successful === true) {
                                            setFirstName('');
                                            setLastName('');
                                            setEmail('');
                                            setAltEmail('');
                                            setPhone('');
                                            setAltPhone('');
                                            setTextPermission(false);
                                            setPrefersText(false);
                                            setCompany(1);
                                            setError();
                                            sender.target.disabled = false;
                                            props.closeModal(true);
                                        } else {
                                            setError("Failed to Add User, Possible Duplicate");
                                            sender.target.disabled = false;
                                        }
                                    });
                                } }
                            >
                                Save
                            </button>
                            <button onClick={(sender) => { props.closeModal(); } }>Cancel</button>
                        </div>
                    </div>
                </KokoroModal>
            ) }
        </div>
    );
}

const getCompanyOptions = (companies) => {
    const toRender = [];
    if (companies) {
        for (const company of companies) {
            toRender.push(<option value={`${company.id}`}>{company.name}</option>)
        }
    }
    return toRender;
}

export default UserAddModal;