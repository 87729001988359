import React, { useState } from 'react';
import KokoroModal from '../../Utils/KokoroModal';
import { request } from '../../../utils/requests';
import KokoroTable from '../../Utils/KokoroTable';

let once = -1;
const CohortUserReportDetails = (props) => {
    const [answers, setAnswers] = useState();
    const [score, setScore] = useState(0);
    if (props.value && once != props.value.uuid) {
        once = props.value.uuid;
        getSurveyAnswers(once, setAnswers, setScore);
    }
    return (
        <div>
            { ( props.showModal && props.value && answers)  && (
                <KokoroModal closeModal={props.closeModal}>
                    <div>
                        <h3>User Survey Id: {props.value.uuid}</h3>
                        <h3>User Name: {props.value.name}</h3>
                        <h3>Survey Name: {props.value.survey}</h3>
                        <h3>Score: {score}</h3>
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Answered</legend>
                            <KokoroTable style={{ width: '100%' }}
                                fields={[
                                    { header: 'Answer Key', value: 'answer_key' },
                                    { header: 'Question Id', value: 'survey_question_id' },
                                    { header: 'Question Text', value: 'question_text' },
                                    { header: 'Answer Id', value: 'survey_answer_id' },
                                    { header: 'Answer Label', value: 'answer_label' },
                                    { header: 'Answer Text', value: 'answer_text' },
                                    { header: 'Score', value: 'score' },
                                    { header: 'Reverse Coded', value: 'reversed' }

                                ]}
                                data={filterAnswers(answers, false)}
                                maxItems={5}
                                tableName="Answered"
                            />
                        </fieldset>
                        <fieldset>
                            <legend style={{ textAlign: 'left' }}>Unanswered</legend>
                            <KokoroTable style={{ width: '100%' }}
                                fields={[
                                    { header: 'Answer Key', value: 'answer_key' },
                                    { header: 'Question Id', value: 'survey_question_id' },
                                    { header: 'Question Text', value: 'question_text' },
                                    { header: 'Answer Id', value: 'survey_answer_id' },
                                    { header: 'Answer Label', value: 'answer_label' },
                                    { header: 'Answer Text', value: 'answer_text' },
                                    { header: 'Score', value: 'score' },
                                    { header: 'Reverse Coded', value: 'reversed' }

                                ]}
                                data={filterAnswers(answers, true)}
                                maxItems={2}
                                tableName="UnAnswered"
                            />
                        </fieldset>
                    </div>
                </KokoroModal>
            ) }
        </div>
    )
}

const filterAnswers = (answers, unanswered) => {
    const filteredData = [];
    if (answers) {
        for (const answer of answers) {
            if ((!answer.answer_key) !== unanswered) {
                continue;
            } else {
                filteredData.push(answer);
            }
        }
    }
    return filteredData;
}

const getSurveyAnswers = (id, setAnswers, setScore) => {
    request(`userSurveyAnswers/getAnswers/${id}`).then(res => {
        let surveyAnswers = res && res.data && res.data.rows && res.data.rows.length > 0 ? res.data.rows : [];
        let score = 0;
        for (const row of surveyAnswers) {
            if (row.answer_key) {
                let myScore = row.matrix_value > 0 ? row.matrix_value : row.value;
                if (row.reverse_coded) {
                    myScore = 6 - myScore;
                }
                score += myScore;
                row.score = myScore;
            }
            row.reversed = row.reverse_coded === true ? 'true' : 'false';
        }
        setAnswers(surveyAnswers);
        setScore(score);
    }, reason => {

    });
}


export default CohortUserReportDetails;