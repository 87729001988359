import React, { useState } from 'react';
import { request } from '../../../utils/requests';
import KokoroDialog from '../../Utils/KokoroDialog';
import KokoroModal from '../../Utils/KokoroModal';
import KokoroTable from '../../Utils/KokoroTable';

class OrganizationDetailsModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        edit: false,
        name: '',
        partner_id: undefined,
        users: [],
        members: {},
        pendingMembers: {},
        showDialog: false,
        statusChangeValue: null
    }

    once = -1;
    componentDidMount = () => {
        if (this.props.value && this.props.showModal === true && this.once !== this.props.value.id) {
            this.once = this.props.value.id;
            this.getUsers();
        }
    }

    componentDidUpdate = () => {
        if (this.props.value && this.props.showModal === true && this.once !== this.props.value.id) {
            this.once = this.props.value.id;
            this.getUsers();
        }
    }

    filterMembers = (users, members, pendingUsers) => {
        const returnUsers = [];
        if (users) {
            const entries = Object.entries(users);
            for (let i = 0; i < entries.length; i++) {
                const user = entries[i][1];
                if (members && !members[user.id] && pendingUsers && !pendingUsers[user.id]) {
                    returnUsers.push(user);
                }
            }
        }
        return returnUsers;
    }

    getMemberArray = (members) => {
        const returnMembers = [];
        if (members) {
            const entries = Object.entries(members);
            for (let i = 0; i < entries.length; i++) {
                const member = entries[i][1];
                returnMembers.push(member);
            }
        }
        return returnMembers;
    }

    getPendingMemberArray = (pendingMembers) => {
        const returnPendingMembers = [];
        if (pendingMembers) {
            const entries = Object.entries(pendingMembers);
            for (let i = 0; i < entries.length; i++) {
                const pendingMember = entries[i][1];
                returnPendingMembers.push(pendingMember);
            }
        }
        return returnPendingMembers;
    }

    getUsers = () => {
        request('getUsers').then(res => {
            const users = [];
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const user of res.data.rows) {
                    user.add = (<button onClick={(sender) => { this.setState( (prevState) => { const update = { ...prevState.pendingMembers  }; update[user.id] = user; return { pendingMembers: update }; } ) } }>Add</button>);
                    user.remove = (<button onClick={(sender) => { this.setState( (prevState) => { const update = { ...prevState.pendingMembers  }; delete update[user.id]; return { pendingMembers: update }; } ) } }>Remove</button>);
                    users.push(user);
                }
            }
            this.getMembers(users);
        }, reason => {
    
        });
    }

    getMembers = (users) => {
        request(`orgmap/getUsers/${this.props.value.id}`).then(res => {
            const members = {};
            if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                for (const member of res.data.rows) {
                    if (member.is_active === true) {
                        member.remove = (<button onClick={(sender) => { this.setState( ( { showDialog: true, statusChangeValue: member } ) ) } }>Remove</button>);
                        members[member.user_id] = member;
                    }
                }
            }
            this.setState((prevState) => ( { users, members, pendingMembers: {}, name: '', edit: false, showDialog: false, statusChangeValue: null } ) );
        }, reason => {
    
        });
    }

    handleUserRemoveDialog = (value, affirmative) => {
        if (value && value.id && affirmative === true) {
            request('orgmap/updateAssociation', { id: value.id, is_active: !value.is_active }).then(res => {
                this.getUsers();
            });
        } else {
            this.setState((prevState) => ( { showDialog: false, statusChangeValue: null } ) );
        }
    }

    generatePartnerSelect = (partners) => {
        const toRender = [];
            if (partners) {
                for (let i = 0; i < partners.length; i++) {
                    const partner = partners[i];
                    toRender.push(<option key={`AddOrg-Partner-${partner.id}`} value={partner.id}>{partner.name}</option>)
                }
            }
        return toRender;
    }
    
    render = () => {
        const nameInputValue = this.state.name.trim() === '' && this.props.value && this.props.value.name ? this.props.value.name : this.state.name;
        const partnerInputValue = !this.state.partner_id && this.props.value && this.props.value.partner_id ? this.props.value.partner_id : this.state.partner_id;
        const editText = this.state.edit === true ? 'Cancel' : 'Edit';
        const canEdit = true;
        return (
            <div>
                {
                    (this.props.showModal && this.props.value) && (
                        <KokoroModal closeModal={this.props.closeModal}>
                            {
                                this.state.showDialog && (
                                    <KokoroDialog title="Status Change" message="Are you sure you want to remove this user from the Organization?" value={this.state.statusChangeValue} showDialog={this.state.showDialog} closeModal={this.handleUserRemoveDialog} />
                                )
                            }
                            <div>
                                <div>
                                    <details>
                                        <div>
                                            <label>Id:</label>
                                            <span>{this.props.value.id}</span>
                                        </div>
                                        <div>
                                            <label>UUID:</label>
                                            <span>{this.props.value.uuid}</span>
                                        </div>
                                        <summary>
                                            <h3>{this.props.name} Organization Details</h3>
                                            (Click to expand for more info)
                                        </summary>
                                    </details>
                                </div>
                                <div style={{ maxWidth: '1000px', width: '80%', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignContent: 'space-between', margin: 'auto' }}>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                        <legend style={{ textAlign: 'left' }}>Name</legend>
                                        { (this.state.edit === true && canEdit) && (
                                        <div>
                                            <label>Name:</label>
                                            <input type="text" value={nameInputValue} onChange={
                                                (sender) => {
                                                    sender.preventDefault();
                                                    this.setState((prevState) => ( { name: sender.target.value } ) );
                                                } }
                                            />
                                        </div>
                                        ) || (
                                            <div>
                                                <label>Name: </label>
                                                <span>{this.props.value.name}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                    <fieldset style={{ width: '40%', textAlign: 'center', padding: '.5vw' }}>
                                        <legend style={{ textAlign: 'left' }}>Partner</legend>
                                        { (this.state.edit === true && canEdit) && (
                                        <div>
                                            <label>Partner:</label>
                                            <select type="text" value={partnerInputValue} onChange={
                                                (sender) => {
                                                    sender.preventDefault();
                                                    this.setState((prevState) => ( { partner_id: parseInt(sender.target.value) } ) );
                                                } }
                                            >
                                                { this.generatePartnerSelect(this.props.partners) }
                                            </select>
                                        </div>
                                        ) || (
                                            <div>
                                                <label>Partner: </label>
                                                <span>{this.props.value.partner}</span>
                                            </div>
                                        )}
                                    </fieldset>
                                    { (this.state.edit === true && canEdit === true) && (
                                        <div style={{ width: '100%' }}>
                                            <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                                <legend style={{ textAlign: 'left' }}>Available Users</legend>
                                                <div>
                                                    <KokoroTable fields={ [ { header: 'First Name', value: 'first_name', filterable: true, sortable: true }, { header: 'Last Name', value: 'last_name', filterable: true, sortable: true }, { header: 'Email', value: 'email', filterable: true, sortable: true }, { header: 'Add', value: 'add' } ] } data={this.filterMembers(this.state.users, this.state.members, this.state.pendingMembers)} tableName="AvailableUsers" />
                                                </div>
                                            </fieldset>
                                            <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                                <legend style={{ textAlign: 'left' }}>Pending Users</legend>
                                                <div>
                                                    <KokoroTable fields={ [ { header: 'First Name', value: 'first_name', filterable: true, sortable: true }, { header: 'Last Name', value: 'last_name', filterable: true, sortable: true }, { header: 'Email', value: 'email', filterable: true, sortable: true }, { header: 'Remove', value: 'remove' } ] } data={this.getPendingMemberArray(this.state.pendingMembers)} tableName="PendingMembers" />
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) || (
                                        <fieldset style={{ width: '100%', textAlign: 'center', padding: '.5vw' }}>
                                            <legend style={{ textAlign: 'left' }}>Assigned Users</legend>
                                            <div>
                                                <KokoroTable fields={ [ { header: 'First Name', value: 'first_name', filterable: true, sortable: true }, { header: 'Last Name', value: 'last_name', filterable: true, sortable: true }, { header: 'Email', value: 'email', filterable: true, sortable: true }, { header: 'Remove', value: 'remove' } ] } data={this.getMemberArray(this.state.members)} tableName="Members" />
                                            </div>
                                        </fieldset>
                                    ) }
                                </div>
                                { 
                                    canEdit === true && (
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-evenly', margin: 'auto' }}>
                                            { (this.state.edit === true && canEdit) && (
                                                <button onClick={
                                                    async (sender) => {
                                                        const updates = {};
                                                        if (this.state.name !== '' && this.state.name !== this.props.value.name) {
                                                            updates.name = this.state.name;
                                                        }
                                                        if (this.state.partner_id && this.state.partner_id > 0 && this.state.partner_id !== this.props.value.partner_id) {
                                                            updates.partner_id = this.state.partner_id;
                                                        }
                                                        const pendingMembers = Object.entries(this.state.pendingMembers);
                                                        const org_id = this.props.value.id;
                                                        let addedUsers = false;
                                                        if (pendingMembers.length > 0) {
                                                            addedUsers = true;
                                                            for (let i = 0; i < pendingMembers.length; i++) {
                                                                
                                                                const newMember = pendingMembers[i][1];
                                                                try {
                                                                    await request('orgmap/addAssociation', {org_id, user_id: newMember.id });
                                                                } catch (error) {
                                                                    console.error(error);
                                                                }
                                                                
                                                            }
                                                        }
                                                        
                                                        const hasUpdates = Object.entries(updates).length > 0;
                                                        if (hasUpdates) {
                                                            request('updateOrganization', { id: this.props.value.id, ...updates }).then(res => {
                                                                this.setState((prevState) => ( { edit: false, name: '', partner_id: null, pendingMembers: {} } ) );
                                                                this.props.closeModal(true, false);
                                                            });
                                                        }

                                                        if (addedUsers) {
                                                            this.getUsers();
                                                        }
                                                    } }
                                                >
                                                    Save
                                                </button>
                                            ) }
                                            <button onClick={
                                                (sender) => {
                                                    this.setState((prevState) => ( { edit: !prevState.edit, name: '', partner_id: null, pendingMembers: {} } ) );
                                                } }
                                            >
                                                {editText}
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </KokoroModal>
                    )
                }
            </div>
        );
    }
}

export default OrganizationDetailsModal;